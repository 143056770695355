import React, { Component } from "react";

import styles from "./Flyout.module.css";

interface FlyoutProps {
  customClass?: string;
  content: React.ReactNode;
  open?: boolean;

  onMouseEnter?: () => void;
  onMouseExit?: () => void;
}

export default class Flyout extends Component<FlyoutProps> {
  flyoutDiv?: HTMLSpanElement;

  constructor(props: FlyoutProps) {
    super(props);

    this.flyoutCloser = this.flyoutCloser.bind(this);
  }

  render() {
    return (
      <span
        className={styles.Flyout + (this.props.customClass ? this.props.customClass : "")}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseExit}
        onFocus={this.props.onMouseEnter}
        onBlur={this.props.onMouseExit}
        ref={span => (this.flyoutDiv = span ? span : undefined)}
      >
        {this.props.children}
        {this.props.open && (
          <div className={styles.flyoutContainer}>
            <div className={styles.flyoutContent}>{this.props.content}</div>
            <div className={styles.triangleContainer}>
              <div className={styles.flyoutTriangle}></div>
            </div>
          </div>
        )}
      </span>
    );
  }

  componentDidUpdate(prevProps: FlyoutProps) {
    if (prevProps.open !== this.props.open && this.props.open) {
      document.addEventListener("click", this.flyoutCloser);
    }
  }

  flyoutCloser(event: MouseEvent) {
    if (!this.flyoutDiv || !event.target) {
      return;
    }

    if (!this.flyoutDiv.contains(event.target as Node) && this.props.open && this.props.onMouseExit) {
      this.props.onMouseExit();
      document.removeEventListener("click", this.flyoutCloser);
    }
  }
}
