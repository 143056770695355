import { TextInputValidator } from "components/inputs/textInput/TextInput";

class TextEqualsValidator implements TextInputValidator {
  private errorMessage: string;
  private target: string;

  public constructor(target: string, errorMessage: string = "Invalid format.") {
    this.target = target;
    this.errorMessage = errorMessage;
  }

  public async validate(value: string | undefined): Promise<true | string> {
    if (value !== undefined && value.trim() === this.target) return true;

    return this.errorMessage;
  }

  public validateSync(value: string | undefined): true | string {
    if (value === this.target) return true;

    return this.errorMessage;
  }
}

export default TextEqualsValidator;
