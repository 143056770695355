import React, { Component } from "react";
import PunchlistItem from "components/punchlistItem/PunchlistItem";

import { PunchlistItem as PunchlistItemData } from "modules/api/apiModels/punchlistItem";
import SkeletonPunchlistItem from "components/skeletonPunchlistItem/SkeletonPunchlistItem";
import PunchlistMethods from "modules/api/punchlistMethods";

interface PunchlistItemCompositorProps {
  itemId: string;
  punchlistId: string;
  projectMembers?: string[];
}

interface PunchlistItemCompositorState {
  itemData?: PunchlistItemData;
}

export default class PunchlistItemCompositor extends Component<
  PunchlistItemCompositorProps,
  PunchlistItemCompositorState
> {
  constructor(props: PunchlistItemCompositorProps) {
    super(props);

    this.state = {};

    this.handleItemUpdate = this.handleItemUpdate.bind(this);
    this.getItemData = this.getItemData.bind(this);
  }

  render() {
    if (!this.state.itemData) {
      return <SkeletonPunchlistItem />;
    }

    return <PunchlistItem {...this.props} itemData={this.state.itemData} hideActions={false} />;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: PunchlistItemCompositorProps) {
    if (prevProps.itemId !== this.props.itemId || prevProps.punchlistId !== this.props.punchlistId) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    PunchlistMethods.mutePunchlistItemChildChanged(this.props.punchlistId, this.props.itemId, this.handleItemUpdate);
  }

  async fetchData() {
    const result = await PunchlistMethods.getPunchlistItemData(this.props.punchlistId, this.props.itemId);

    PunchlistMethods.listenPunchlistItemChildChanged(this.props.punchlistId, this.props.itemId, this.handleItemUpdate);

    this.setState({
      itemData: result
    });
  }

  handleItemUpdate(itemId: string, itemData: PunchlistItemData) {
    this.setState({
      itemData: itemData
    });
  }

  public getItemData(): PunchlistItemData | undefined {
    return this.state.itemData;
  }
}
