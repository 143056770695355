class TimeUtil {
  public static getReadableDelta(unixEpochTime: number): string {
    let readableDelta = "";

    const yearInMs = 31536000000;
    const monthInMs = yearInMs / 12;
    const dayInMs = yearInMs / 365;
    const hourInMs = dayInMs / 24;
    const minInMs = hourInMs / 60;
    const secondInMs = 1000;

    let remainder = unixEpochTime;

    const yearsPassed = (remainder - (remainder % yearInMs)) / yearInMs;
    remainder -= yearsPassed * yearInMs;

    const monthsPassed = (remainder - (remainder % monthInMs)) / monthInMs;
    remainder -= monthsPassed * monthInMs;

    const daysPassed = (remainder - (remainder % dayInMs)) / dayInMs;
    remainder -= daysPassed * dayInMs;

    const hoursPassed = (remainder - (remainder % hourInMs)) / hourInMs;
    remainder -= hoursPassed * hourInMs;

    const minsPassed = (remainder - (remainder % minInMs)) / minInMs;
    remainder -= minsPassed * minInMs;

    const secondsPassed = (remainder - (remainder % secondInMs)) / secondInMs;
    remainder -= secondsPassed * secondInMs;

    if (yearsPassed > 0) readableDelta = `${yearsPassed} year${yearsPassed > 1 ? "s" : ""} ago`;
    else if (monthsPassed > 0) readableDelta = `${monthsPassed} month${monthsPassed > 1 ? "s" : ""} ago`;
    else if (daysPassed > 0) readableDelta = `${daysPassed} day${daysPassed > 1 ? "s" : ""} ago`;
    else if (hoursPassed > 0) readableDelta = `${hoursPassed} hour${hoursPassed > 1 ? "s" : ""} ago`;
    else if (minsPassed > 0) readableDelta = `${minsPassed} minute${minsPassed > 1 ? "s" : ""} ago`;
    else if (secondsPassed > 0) readableDelta = `${secondsPassed} second${secondsPassed > 1 ? "s" : ""} ago`;
    else readableDelta = "just now";

    return readableDelta;
  }
}

export default TimeUtil;
