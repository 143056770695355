import React, { Component } from "react";

import styles from "./UserPickerFlyout.module.css";
import TextInput from "components/inputs/textInput/TextInput";

import * as Icons from "react-feather";

import UserItem from "./userItem/UserItem";

interface UserPickerFlyoutProps {
  open?: boolean;
  onClose?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  users: string[]; // uids

  onSelect?: (uid: string) => void;
}

interface UserPickerFlyoutState {
  filter: string;
}

export default class UserPickerFlyout extends Component<UserPickerFlyoutProps, UserPickerFlyoutState> {
  private menu: HTMLDivElement | null = null;

  constructor(props: UserPickerFlyoutProps) {
    super(props);

    this.state = {
      filter: ""
    };

    this.flyoutCloser = this.flyoutCloser.bind(this);
    this.flyoutCloserKb = this.flyoutCloserKb.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  render() {
    return (
      <span className={styles.UserPickerFlyout}>
        {this.props.children}
        {this.props.open && (
          <div className={styles.flyoutContainer}>
            <div className={styles.userPicker} ref={d => (this.menu = d)}>
              <div className={styles.topBar}>
                <div className={styles.searchBox}>
                  <TextInput
                    value={this.state.filter}
                    onChange={this.handleFilterChange}
                    type="text"
                    placeholder="Search users..."
                    height={32}
                    icon={Icons.Search}
                    iconSide="left"
                  />
                </div>
                <button className={styles.closeBtn} onClick={this.props.onClose}>
                  <Icons.X strokeWidth={1}></Icons.X>
                </button>
              </div>
              <div className={styles.list}>
                {this.props.users.map((userId, index) => {
                  return (
                    <UserItem
                      userId={userId}
                      filter={this.state.filter}
                      key={index}
                      onClick={this.props.onSelect}
                    ></UserItem>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </span>
    );
  }

  componentWillUnmount() {
    this.removeMenuEvents();
  }

  componentDidUpdate(prevProps: UserPickerFlyoutProps) {
    if (prevProps.open !== this.props.open && this.props.open) {
      document.addEventListener("click", this.flyoutCloser);
      document.addEventListener("keydown", this.flyoutCloserKb);
    }
  }

  removeMenuEvents() {
    document.removeEventListener("click", this.flyoutCloser);
    document.removeEventListener("keydown", this.flyoutCloserKb);
  }

  flyoutCloser(event: MouseEvent) {
    if (!this.menu || !event.target) {
      return;
    }

    if (!this.menu.contains(event.target as Node) && this.props.open && this.props.onClose) {
      this.props.onClose();
      this.removeMenuEvents();
    }
  }

  flyoutCloserKb(event: KeyboardEvent) {
    if (event.key !== "Escape") {
      return;
    }

    if (!this.menu) {
      return;
    }

    if (this.props.open && this.props.onClose) {
      this.props.onClose();
      this.removeMenuEvents();
    }
  }

  handleFilterChange(f: string) {
    this.setState({
      filter: f
    });
  }
}
