import React, { Component } from "react";
import Flyout from "components/flyout/Flyout";

interface TooltipState {
  tooltipOpen: boolean;
  flyoutHover: boolean;
}

interface TooltipProps {
  tooltip: string;
}

export default class Tooltip extends Component<TooltipProps, TooltipState> {
  constructor(props: TooltipProps) {
    super(props);

    this.state = {
      tooltipOpen: false,
      flyoutHover: false
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseExit = this.handleMouseExit.bind(this);
    this.handleFlyoutMouseEnter = this.handleFlyoutMouseEnter.bind(this);
    this.handleFlyoutMouseExit = this.handleFlyoutMouseExit.bind(this);
  }

  render() {
    return (
      <Flyout
        content={<>{this.props.tooltip}</>}
        open={this.state.tooltipOpen || this.state.flyoutHover}
        onMouseEnter={this.handleFlyoutMouseEnter}
        onMouseExit={this.handleFlyoutMouseExit}
      >
        <span
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseExit}
          onFocus={this.handleMouseEnter}
          onBlur={this.handleMouseExit}
        >
          {this.props.children}
        </span>
      </Flyout>
    );
  }

  handleFlyoutMouseEnter() {
    this.setState({
      flyoutHover: true
    });
  }

  handleFlyoutMouseExit() {
    this.setState({
      flyoutHover: false
    });
  }

  handleMouseEnter() {
    this.setState({
      tooltipOpen: true
    });
  }

  handleMouseExit() {
    this.setState({
      tooltipOpen: false
    });
  }
}
