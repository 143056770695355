import React, { Component } from "react";

import * as Icons from "react-feather";

import styles from "./FlatIconButton.module.css";

interface FlatIconButtonProps {
  onClick?: () => void;
  icon: React.ComponentType<Icons.Props>;
}

export default class FlatIconButton extends Component<FlatIconButtonProps> {
  render() {
    return (
      <button className={styles.iconButton} onClick={this.props.onClick}>
        <this.props.icon strokeWidth={1} size={18}></this.props.icon>
      </button>
    );
  }
}
