import React, { Component } from "react";

import styles from "./Punchlist.module.css";
import PunchlistItem from "components/punchlistItem/PunchlistItem";
import { PunchlistData } from "modules/api/apiModels/punchlistData";
import PunchlistMethods from "modules/api/punchlistMethods";

import { PunchlistItem as PunchlistItemData, PunchlistItems } from "modules/api/apiModels/punchlistItem";
import AddingItem from "./addingItem/AddingItem";
import PunchlistSorter from "./punchlistSorter/PunchlistSorter";

import * as Icons from "react-feather";
import { COMMON_ERRORS } from "types/commonErrors";
import ProjectMethods from "modules/api/projectMethods";
import SkeletonPunchlistItem from "components/skeletonPunchlistItem/SkeletonPunchlistItem";

interface PunchlistProps {
  punchlistId: string;
  showTopAddItem?: boolean;
  handleCloseTopAddItemClick?: () => void;
  onPermissionDenied?: () => void;
  onTitleChanged?: (title: string) => void;
  filter: string;
  onLoad?: (data: PunchlistData, items: PunchlistItems) => void;
  onUpdate?: (data: PunchlistData, items: PunchlistItems) => void;
  sessionDate: number;
}

interface PunchlistState {
  loading?: boolean;
  data?: PunchlistData;
  items?: { [items: string]: PunchlistItemData };
  error?: string;
  members: string[];
}

export default class Punchlist extends Component<PunchlistProps, PunchlistState> {
  forcedUpdateInterval?: number;

  constructor(props: PunchlistProps) {
    super(props);

    this.state = { loading: true, members: [] };

    this.handleOnTick = this.handleOnTick.bind(this);
    this.handleItemUpdate = this.handleItemUpdate.bind(this);
    this.handleItemPush = this.handleItemPush.bind(this);
    this.handleForcedUpdate = this.handleForcedUpdate.bind(this);
    this.handleItemRemove = this.handleItemRemove.bind(this);
  }

  componentDidMount() {
    this.updatePunchlistData();

    // Listen Changes
    PunchlistMethods.listenPunchlistItemChanged(this.props.punchlistId, this.handleItemUpdate);
    PunchlistMethods.listenPunchlistItemAdded(this.props.punchlistId, this.handleItemUpdate);
    PunchlistMethods.listenPunchlistItemRemoved(this.props.punchlistId, this.handleItemRemove);

    this.forcedUpdateInterval = setInterval(this.handleForcedUpdate as TimerHandler, 10000);
  }

  componentWillUnmount() {
    // Remove Changes Listeners
    PunchlistMethods.mutePunchlistItemChanged(this.props.punchlistId, this.handleItemUpdate);
    PunchlistMethods.mutePunchlistItemAdded(this.props.punchlistId, this.handleItemUpdate);
    PunchlistMethods.mutePunchlistItemRemoved(this.props.punchlistId, this.handleItemRemove);

    if (this.forcedUpdateInterval !== undefined) clearInterval(this.forcedUpdateInterval);
  }

  componentDidUpdate(prevProps: PunchlistProps) {
    if (prevProps.punchlistId !== this.props.punchlistId) {
      this.updatePunchlistData();
    }
  }

  render() {
    return (
      <div className={styles.Punchlist}>
        {this.props.showTopAddItem && (
          <div className={styles.topAddContainer}>
            <AddingItem
              onBlur={this.handleItemPush}
              placeholderClassName={styles.customPlaceholder}
              className={styles.customAddItem}
            />
            <button className={styles.closeBtn} onClick={this.props.handleCloseTopAddItemClick}>
              <Icons.X></Icons.X>
            </button>
          </div>
        )}
        {this.state.loading && (
          <>
            <SkeletonPunchlistItem />
            <SkeletonPunchlistItem />
            <SkeletonPunchlistItem />
            <SkeletonPunchlistItem />
          </>
        )}
        {this.state.items && <>{this.getItems()}</>}
        {!this.state.loading && !this.state.error && <AddingItem onBlur={this.handleItemPush} />}
        {this.state.error && <div className={styles.error}>{this.state.error}</div>}
      </div>
    );
  }

  getItems(): JSX.Element {
    if (!this.state.items) return <></>;

    const items: JSX.Element[] = [];

    let k = 0;

    const filter = this.props.filter.replace(" ", "").toLowerCase();

    for (const key in this.state.items) {
      const element = this.state.items[key];

      if (!element) continue;

      if (
        element.title
          .toLowerCase()
          .replace(" ", "")
          .includes(filter) ||
        element.owner.toLowerCase().includes(filter)
      )
        items.push(
          <PunchlistItem
            projectMembers={this.state.members}
            punchlistId={this.props.punchlistId}
            itemData={element}
            key={k}
            itemId={key}
          />
        );

      k++;
    }

    return <PunchlistSorter sessionTime={this.props.sessionDate}>{items}</PunchlistSorter>;
  }

  async updatePunchlistData() {
    this.setState({
      loading: true
    });

    let dataResult;
    let itemsResult;
    let projectResult;

    try {
      dataResult = await PunchlistMethods.getPunchlistData(this.props.punchlistId);
      itemsResult = await PunchlistMethods.getPunchlistItems(this.props.punchlistId);

      projectResult = await ProjectMethods.getProjectData(dataResult.project);
    } catch (ex) {
      this.setState({
        error: ex.message
      });

      if (ex.code === COMMON_ERRORS.NOT_PERMITTED.code && this.props.onPermissionDenied) {
        this.props.onPermissionDenied();
      }

      return;
    }

    const members = [];

    for (const key in projectResult.members) {
      members.push(key);
    }

    this.setState({
      loading: false,
      data: dataResult,
      items: itemsResult,
      members: members
    });

    if (this.props.onLoad) {
      this.props.onLoad(dataResult, itemsResult || {});
    }

    if (this.props.onTitleChanged) {
      this.props.onTitleChanged(dataResult.name);
    }
  }

  async handleOnTick(item: PunchlistItemData, id: string) {
    const f = item.punched ? PunchlistMethods.untickItem : PunchlistMethods.tickItem;

    await f(this.props.punchlistId, id);
  }

  async handleItemUpdate(itemId: string, itemData: PunchlistItemData) {
    if (!this.state.data) {
      return;
    }

    if (this.props.onUpdate) {
      this.props.onUpdate(this.state.data, {
        ...this.state.items,
        [itemId]: itemData
      });
    }

    this.setState({
      items: {
        ...this.state.items,
        [itemId]: itemData
      }
    });
  }

  async handleItemRemove(itemId: string, itemData: PunchlistItemData) {
    if (!this.state.items || !this.state.data) {
      return;
    }

    if (this.state.items[itemId]) {
      const b = { ...this.state.items };
      delete b[itemId];

      if (this.props.onUpdate) {
        this.props.onUpdate(this.state.data, b);
      }

      this.setState({
        items: b
      });
    }
  }

  async handleItemPush(title: string, punched: boolean) {
    if (title) {
      await PunchlistMethods.pushItem(this.props.punchlistId, title, punched);
      if (this.props.handleCloseTopAddItemClick) {
        this.props.handleCloseTopAddItemClick();
      }
    }
  }

  handleForcedUpdate() {
    this.forceUpdate();
  }
}
