import React, { Component } from "react";

import AuthForm, { AuthFormField } from "components/authForm/AuthForm";
import { DEFAULT_REQUIRED_VALIDATOR } from "modules/customInputDataValidators/textValidators/minLengthValidator";
import { EMAIL_VALIDATOR } from "modules/customInputDataValidators/textValidators/regexValidator";
import { AuthContext } from "contexts/AuthContext";
import FormWall from "components/formWall/FormWall";

import * as Icons from "react-feather";

const RESETPASS_FIELDS: AuthFormField[] = [
  {
    name: "email",
    placeholder: "example@example.com",
    label: "E-mail",
    type: "email",
    validators: [DEFAULT_REQUIRED_VALIDATOR, EMAIL_VALIDATOR],
    icon: Icons.Mail
  }
];

interface ResetPasswordState {
  hasError: boolean;
  error?: string;
  success?: string | JSX.Element;
}

class ResetPassword extends Component<{}, ResetPasswordState> {
  public static contextType = AuthContext;
  public context!: React.ContextType<typeof AuthContext>;

  public constructor(props: {}) {
    super(props);

    this.state = {
      hasError: false
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  public render(): JSX.Element {
    const success = this.state.success !== undefined;

    return (
      <div className="ResetPassword">
        <FormWall
          actionLink={{
            caption: "Know your password?",
            label: "Login",
            route: "/login"
          }}
        >
          <div className="r-login-form">
            <AuthForm
              fields={RESETPASS_FIELDS}
              title="Reset Password"
              onContinue={this.handleContinue}
              processing={this.context.authState !== "guest"}
              masterError={this.state.error}
              masterSuccess={this.state.success}
              hideActionArea={success}
              hideAdditionalContent={success}
              hideForm={success}
            />
          </div>
        </FormWall>
      </div>
    );
  }

  private async handleContinue(values: { [key: string]: string }): Promise<void> {
    const authContext = this.context;

    const email = values["email"];

    const response = await authContext.resetPassword(email);

    this.setState({
      hasError: !response.status,
      error: !response.status ? response.errors[0].message : undefined,
      success: undefined
    });

    if (response.status) {
      this.setState({
        success: "A reset link was sent to your e-mail."
      });
    }
  }
}

export default ResetPassword;
