import React, { Component } from "react";

import styles from "./SocialLogins.module.css";

import googleLogo from "assets/social/google-signin-logo.svg";
import githubLogo from "assets/social/github.png";

interface SocialLoginsProps {
  divisorTitle: string;
  invertOr?: boolean;

  onGithubClick?: () => void;
  onGoogleClick?: () => void;
}

class SocialLogins extends Component<SocialLoginsProps> {
  render() {
    return (
      <div className={styles.SocialLogins}>
        {!this.props.invertOr && (
          <div className={styles.orContainer}>
            <div className={styles.bar} />
            <div className={styles.divisorTitle}> {this.props.divisorTitle}</div>
            <div className={styles.bar} />
          </div>
        )}
        <button className={styles.loginOption} onClick={this.props.onGoogleClick}>
          <div className={styles.loginOptionLogoContainer}>
            <img src={googleLogo} alt="Google" className={styles.loginOptionLogo} />
          </div>
          <div className={styles.socialLabelContainer}>Continue with Google</div>
        </button>

        <button className={styles.loginOption} onClick={this.props.onGithubClick}>
          <div className={styles.loginOptionLogoContainer}>
            <img src={githubLogo} alt="Github" className={styles.loginOptionLogo} />
          </div>
          <div className={styles.socialLabelContainer}>Continue with GitHub</div>
        </button>

        {this.props.invertOr && (
          <div className={styles.orContainer}>
            <div className={styles.bar} />
            <div className={styles.divisorTitle}> {this.props.divisorTitle}</div>
            <div className={styles.bar} />
          </div>
        )}
      </div>
    );
  }
}

export default SocialLogins;
