import React, { Component } from "react";
import Flyout from "components/flyout/Flyout";
import SuperButton from "components/superButton/SuperButton";

import * as Icons from "react-feather";

import styles from "./DangerArea.module.css";

interface DangerAreaProps {
  executing?: boolean;
  execute: () => void;

  actionIcon?: React.ComponentType<Icons.Props>;
  action: string;

  label: string;
  desc: string;

  confirmationLabel: string;
  confirmationAction: string;
}

interface DangerAreaState {
  flyoutOpen: boolean;
}

export default class DangerArea extends Component<DangerAreaProps, DangerAreaState> {
  constructor(props: DangerAreaProps) {
    super(props);

    this.state = {
      flyoutOpen: false
    };

    this.toggleFlyout = this.toggleFlyout.bind(this);
    this.execute = this.execute.bind(this);
  }

  render() {
    return (
      <div className={styles.deleteContainer}>
        <label className={styles.formEntry}>{this.props.label}</label>
        <div className={styles.deleteCaption}>
          <div className={styles.formEntryCaption}>{this.props.desc}</div>

          <div className={styles.deleteBtnContainer}>
            <Flyout
              content={
                <div className={styles.deleteConfirm}>
                  <div className={styles.deleteConfirmText}>{this.props.confirmationLabel}</div>
                  <div className={styles.deleteConfirmButtonArea}>
                    <SuperButton
                      label={this.props.confirmationAction}
                      compact
                      outline
                      customColor="#ff0000"
                      onClick={this.execute}
                    ></SuperButton>
                    <div className={styles.deleteConfirmButtonSpace} />
                    <SuperButton label="Cancel" compact onClick={this.toggleFlyout}></SuperButton>
                  </div>
                </div>
              }
              open={this.state.flyoutOpen}
            >
              <SuperButton
                label={this.props.action}
                outline
                customColor="#ff0000"
                icon={this.props.actionIcon}
                onClick={this.toggleFlyout}
                disabled={this.props.executing}
                spin={this.props.executing}
              />
            </Flyout>
          </div>
        </div>
      </div>
    );
  }

  toggleFlyout() {
    this.setState({
      flyoutOpen: !this.state.flyoutOpen
    });
  }

  execute() {
    this.toggleFlyout();
    this.props.execute();
  }
}
