import React, { Component } from "react";
import { Route } from "react-router-dom";
import TopMenu from "components/topMenu/TopMenu";

import styles from "./AppBootstrap.module.css";

import Dashboard from "./dashboard/Dashboard";
import Teams from "./teams/Teams";
import NewTeam from "./teams/newTeam/NewTeam";
import CommonFooter from "components/commonFooter/CommonFooter";
import Punchlist from "./punchlist/Punchlist";
import Projects from "./projects/Projects";
import NewProject from "./projects/newProject/NewProject";
import Join from "./join/Join";
import ManageProject from "./projects/manage/Manage";
import ManageTeam from "./teams/manage/Manage";

export default class AppBootstrap extends Component {
  render() {
    return (
      <div className={styles.AppBootstrap}>
        <TopMenu />
        <main>
          <Route exact path="/app" component={Dashboard} />
          <Route exact path="/app/punchlist/:id" render={({ match }) => <Punchlist punchlistId={match.params.id} />} />

          <Route exact path="/app/teams" component={Teams} />
          <Route exact path="/app/teams/create" component={NewTeam} />

          <Route exact path="/app/projects" component={Projects} />
          <Route exact path="/app/projects/create" component={NewProject} />
          <Route exact path="/app/projects/manage/:userId/:projectAlias" component={ManageProject} />
          <Route exact path="/app/teams/manage/:projectAlias" component={ManageTeam} />
          <Route exact path="/app/join" component={Join} />
        </main>
        <footer>
          <CommonFooter />
        </footer>
      </div>
    );
  }
}
