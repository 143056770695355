import React, { Component } from "react";

import styles from "./commonStyles.module.css";
import Spinner from "components/spinner/Spinner";
import { AuthContext } from "contexts/AuthContext";
import { Redirect } from "react-router";
import ProjectMethods from "modules/api/projectMethods";

interface EmailLinkExecutorProps {
  oobCode: string;
  continueUrl?: string;
}

interface EmailLinkExecutorState {
  canRedirectBack: boolean;
  requiresEmail: boolean;
}

export default class EmailLinkExecutor extends Component<EmailLinkExecutorProps, EmailLinkExecutorState> {
  public static contextType = AuthContext;
  public context!: React.ContextType<typeof AuthContext>;

  private verifiedInviteSuitability: boolean = false;

  constructor(props: EmailLinkExecutorProps) {
    super(props);

    this.state = {
      canRedirectBack: false,
      requiresEmail: false
    };
  }

  render() {
    if (this.state.canRedirectBack || (this.context.firstState && this.context.isAuthenticated))
      return <Redirect to={{ pathname: this.props.continueUrl || "/app" }} />;

    return (
      <div className={styles.loadingOverlay}>
        <Spinner size={48} colorful />
      </div>
    );
  }

  componentDidUpdate() {
    if (this.context.firstState && !this.verifiedInviteSuitability) {
      if (this.props.continueUrl && this.props.continueUrl.startsWith("/app/join")) {
        const url = new URL("https://" + window.location.host + this.props.continueUrl);
        const query = new URLSearchParams(url.search);
        const token = query.get("token");

        if (token) {
          this.loginWithInvite(token);
        } else {
          this.setState({
            requiresEmail: true
          });

          this.verifiedInviteSuitability = true;
        }
      } else {
        this.setState({
          requiresEmail: true
        });

        this.verifiedInviteSuitability = true;
      }
    }
  }

  async loginWithInvite(inviteToken: string) {
    try {
      const invite = await ProjectMethods.retrieveInvite(inviteToken);

      const email = invite.email;

      const response = await this.context.loginEmailLink(email, window.location.href);

      if (response.status) {
        this.setState({
          canRedirectBack: true
        });
      }
    } catch {}
  }
}
