import React, { Component } from "react";

import ProjectListData from "modules/api/apiModels/projectsList";

import styles from "./ProjectsList.module.css";
import ProjectsListItem from "./projectsListItem/ProjectsListItem";

import * as Icons from "react-feather";
import { Link } from "react-router-dom";

interface ProjectsListProps {
  items: ProjectListData;
  filter: string;
  onResetFilter?: () => void;
}

export default class ProjectsList extends Component<ProjectsListProps> {
  render() {
    return <div className={styles.ProjectList}>{this.getItems()}</div>;
  }

  getItems(): JSX.Element {
    const items: JSX.Element[] = [];

    let i = 0;

    for (const key in this.props.items) {
      const item = this.props.items[key];

      if (
        item.name
          .toLowerCase()
          .trim()
          .includes(this.props.filter.toLowerCase().trim())
      )
        items.push(<ProjectsListItem item={item} key={i} id={key}></ProjectsListItem>);

      i++;
    }

    if (items.length === 0 && !this.props.filter) {
      return (
        <div className={styles.noProjects}>
          <div className={styles.noProjectsIcon}>
            <Icons.Coffee size="64" strokeWidth="1" />
          </div>
          <div className={styles.caption}>You don't have any project.</div>
          <Link className="linkLikeBtn" to="/app/projects/create">
            Create One
          </Link>
        </div>
      );
    }

    if (items.length === 0 && this.props.filter) {
      return (
        <div className={styles.noProjects}>
          <div className={styles.noProjectsIcon}>
            <Icons.Codesandbox size="64" strokeWidth="1" />
          </div>
          <div className={styles.caption}>No projects found for &ldquo;{this.props.filter}&rdquo;</div>
          <button className="linkLikeBtn" onClick={this.props.onResetFilter}>
            See All Projects
          </button>
        </div>
      );
    }
    return <>{items}</>;
  }
}
