import { TextInputValidator } from "components/inputs/textInput/TextInput";

class NotValidator implements TextInputValidator {
  private errorMessage: string;
  private validator: TextInputValidator;

  constructor(validator: TextInputValidator, errorMessage: string) {
    this.errorMessage = errorMessage;
    this.validator = validator;
  }

  public async validate(value?: string): Promise<true | string> {
    if (value !== undefined && (await this.validator.validate(value)) !== true) return true;

    return this.errorMessage;
  }
}

export default NotValidator;
