import React, { Component } from "react";

import styles from "./CommentsBar.module.css";

interface CommentsBarProps {
  showing: number;
  total: number;
  ordering: "NEWEST_FIRST" | "OLDEST_FIRST";
  onOrderingChanged?: (newOrdering: "NEWEST_FIRST" | "OLDEST_FIRST") => void;
  onCollapse?: () => void;
}

export default class CommentsBar extends Component<CommentsBarProps> {
  constructor(props: CommentsBarProps) {
    super(props);

    this.handleOrderingChange = this.handleOrderingChange.bind(this);
  }

  render() {
    return (
      <div className={styles.CommentsBar}>
        <div className={styles.titleLeft}>
          Comments (Showing {this.props.showing} of {this.props.total})
        </div>
        <div className={styles.titleRight}>
          <select value={this.props.ordering} onChange={this.handleOrderingChange}>
            <option value="NEWEST_FIRST" label="Newest First"></option>
            <option value="OLDEST_FIRST" label="Oldest First"></option>
          </select>
        </div>
      </div>
    );
  }

  handleOrderingChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (this.props.onOrderingChanged)
      this.props.onOrderingChanged(event.target.value as "NEWEST_FIRST" | "OLDEST_FIRST");
  }
}
