import React from "react";
import { AuthContext } from "contexts/AuthContext";
import { Route, RouteProps, Redirect } from "react-router";

import styles from "./privateRoute.module.css";
import Spinner from "components/spinner/Spinner";

interface PrivateRouteProps extends RouteProps {
  needsCompletion?: boolean;
}

export default class PrivateRoute extends Route<PrivateRouteProps> {
  public static contextType = AuthContext;
  public context!: React.ContextType<typeof AuthContext>;

  public render() {
    const query = new URLSearchParams(window.location.search);
    const redirectBack = query.get("next");

    const loginRedirectComponent = () => (
      <Redirect to={{ pathname: "/login/?next=" + (redirectBack || window.location.pathname) }} />
    );
    const completeRedirectComponent = () => (
      <Redirect to={{ pathname: "/complete/?next=" + (redirectBack || window.location.pathname) }} />
    );

    return (
      <AuthContext.Consumer>
        {auth => (
          <>
            {auth.isAuthenticated ? (
              auth.firstState ? (
                !auth.isProfileComplete && this.props.needsCompletion ? (
                  <Route {...this.props} component={completeRedirectComponent} render={undefined} />
                ) : (
                  <Route {...this.props} />
                )
              ) : (
                <div className={styles.loadingOverlay}>
                  <Spinner size={48} colorful />
                </div>
              )
            ) : (
              <Route {...this.props} component={loginRedirectComponent} render={undefined} />
            )}
          </>
        )}
      </AuthContext.Consumer>
    );
  }
}
