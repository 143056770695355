import React, { Component } from "react";
import { AuthContext, AuthContextState, AuthState } from "contexts/AuthContext";

import { AuthCommonResponse, CommonAuthResolver } from "types/authTypes";

import * as firebase from "firebase/app";
import UserProfileMethods from "modules/api/userProfileMethods";
import CommonEvent from "types/commonEvent";

/**
 * Authentication state provider and controller
 */
class AuthStateProvider extends Component<{}, AuthContextState> {
  private auth = firebase.auth();

  public constructor(props: {}) {
    super(props);

    this.state = {
      isAuthenticated: true,
      isProfileComplete: false,
      username: undefined,
      uid: undefined,

      authState: "waiting",
      firstState: false,
      login: this.login.bind(this),
      loginWithToken: this.loginWithJWTToken.bind(this),
      register: this.register.bind(this),
      resetPassword: this.resetPassword.bind(this),
      confirmResetPassword: this.confirmResetPassword.bind(this),
      loginGithub: this.loginGithub.bind(this),
      loginGoogle: this.loginGoogle.bind(this),
      loginEmailLink: this.loginEmailLink.bind(this),
      updateProfileState: this.assignCompletion.bind(this),
      logout: this.logout.bind(this),
      onCompletionAssigned: new CommonEvent()
    };

    this.auth.onAuthStateChanged(user => {
      if (user) {
        this.setState({
          authState: "logged",
          isAuthenticated: true
        });
      } else {
        this.setState({
          authState: "guest",
          isAuthenticated: false
        });
      }

      this.assignCompletion();
    });
  }

  public render(): JSX.Element {
    return <AuthContext.Provider value={this.state}>{this.props.children}</AuthContext.Provider>;
  }

  /**
   * Gets current auth state
   * @returns {boolean} Auth state
   */
  private getAuthState(): boolean {
    if (this.auth.currentUser) {
      return true;
    }

    return false;
  }

  /**
   * Logins the user
   * @param {string} email The user email
   * @param {string} password The user password
   * @returns {Promise<AuthCommonResponse>} Login sucessful or not
   */
  private async login(email: string, password: string): Promise<AuthCommonResponse> {
    const previousAuthState = this.state.authState;

    this.setAuthState("login");

    try {
      await this.auth.signInWithEmailAndPassword(email, password);

      this.setAuthState("logged");

      await this.assignCompletion();

      return { status: true, errors: [] };
    } catch (r) {
      this.setAuthState(previousAuthState);

      return { status: false, errors: [{ code: r.code, message: r.message }] };
    }
  }

  /**
   * Logins the user with JWT token
   * @param {string} token The user token
   * @returns {Promise<AuthCommonResponse>} Login sucessful or not
   */
  private async loginWithJWTToken(token: string): Promise<AuthCommonResponse> {
    const previousAuthState = this.state.authState;

    this.setAuthState("login");

    try {
      await this.auth.signInWithCustomToken(token);

      this.setAuthState("logged");

      await this.assignCompletion();

      return { status: true, errors: [] };
    } catch (r) {
      this.setAuthState(previousAuthState);

      return { status: false, errors: [{ code: r.code, message: r.message }] };
    }
  }

  /**
   * Logins the user using github
   * @returns {Promise<AuthCommonResponse>} Login sucessful or not
   */
  private async loginGoogle(): Promise<AuthCommonResponse> {
    const previousAuthState = this.state.authState;

    const provider = new firebase.auth.GoogleAuthProvider();

    this.setAuthState("login");

    try {
      await this.auth.signInWithRedirect(provider);

      this.setAuthState("logged");

      await this.assignCompletion();

      return { status: true, errors: [] };
    } catch (r) {
      this.setAuthState(previousAuthState);

      return { status: false, errors: [{ code: r.code, message: r.message }] };
    }
  }

  /**
   * Logins the user using github
   * @returns {Promise<AuthCommonResponse>} Login sucessful or not
   */
  private async loginGithub(): Promise<AuthCommonResponse> {
    const previousAuthState = this.state.authState;

    const provider = new firebase.auth.GithubAuthProvider();

    this.setAuthState("login");

    try {
      await this.auth.signInWithRedirect(provider);

      this.setAuthState("logged");

      await this.assignCompletion();

      return { status: true, errors: [] };
    } catch (r) {
      this.setAuthState(previousAuthState);

      return { status: false, errors: [{ code: r.code, message: r.message }] };
    }
  }

  /**
   * Logins the user
   * @param {string} email The user email
   * @param {string} link The login link
   * @returns {Promise<AuthCommonResponse>} Login sucessful or not
   */
  private async loginEmailLink(email: string, link: string): Promise<AuthCommonResponse> {
    const previousAuthState = this.state.authState;

    this.setAuthState("login");

    try {
      await this.auth.signInWithEmailLink(email, link);

      this.setAuthState("logged");

      await this.assignCompletion();

      return { status: true, errors: [] };
    } catch (r) {
      this.setAuthState(previousAuthState);

      return { status: false, errors: [{ code: r.code, message: r.message }] };
    }
  }

  private async resetPassword(email: string): Promise<AuthCommonResponse> {
    const previousAuthState = this.state.authState;

    this.setAuthState("reset");

    try {
      await this.auth.sendPasswordResetEmail(email);
      this.setAuthState(previousAuthState);

      return { status: true, errors: [] };
    } catch (r) {
      this.setAuthState(previousAuthState);

      return { status: false, errors: [{ code: r.code, message: r.message }] };
    }
  }

  private async confirmResetPassword(oobCode: string, password: string): Promise<AuthCommonResponse> {
    const previousAuthState = this.state.authState;

    this.setAuthState("resetConfirm");

    try {
      await this.auth.confirmPasswordReset(oobCode, password);

      this.setAuthState(previousAuthState);

      return { status: true, errors: [] };
    } catch (r) {
      this.setAuthState(previousAuthState);

      return { status: false, errors: [{ code: r.code, message: r.message }] };
    }
  }

  /**
   * Register a new user
   * @param name The user name
   * @param company The user company
   * @param email The user email adress
   * @param password The user password
   * @param passwordConfirmation The password confirmation
   * @returns {Promise<AuthCommonResponse>} Login sucessful or not
   */
  private async register(name: string, company: string, email: string, password: string): Promise<AuthCommonResponse> {
    const previousAuthState = this.state.authState;

    this.setAuthState("register");

    try {
      await this.auth.createUserWithEmailAndPassword(email, password);

      // Updates Company
      await UserProfileMethods.updateProfileSettings(company, name, "");

      this.setAuthState("logged");

      return { status: true, errors: [] };
    } catch (r) {
      this.setAuthState(previousAuthState);

      return { status: false, errors: [{ code: r.code, message: r.message }] };
    }
  }

  private async logout(): Promise<AuthCommonResponse> {
    const resolver = async (resolve: CommonAuthResolver): Promise<void> => {
      try {
        await this.auth.signOut();

        resolve({ status: true, errors: [] });
      } catch {
        resolve({ status: false, errors: [] });
      }
    };

    return new Promise(resolver);
  }

  private setAuthState(newState: AuthState) {
    this.setState({
      authState: newState,
      firstState: true
    });
  }

  private async assignCompletion(cb?: () => void) {
    const isProfileComplete = await UserProfileMethods.isUserProfileComplete();
    this.setState(
      {
        isProfileComplete: isProfileComplete.status,
        firstState: true
      },
      cb
    );

    if (isProfileComplete) {
      const username = await UserProfileMethods.getCurrentUserUsername();
      const uid = await UserProfileMethods.getCurrentUserUid();

      this.setState(
        {
          username: username,
          uid: uid
        },
        () => this.state.onCompletionAssigned.propagate()
      );
    }
  }
}

export default AuthStateProvider;
