import React from "react";
import ReactDOM from "react-dom";

import "./index.css"; // Common CSS
import "./variables.css"; // Variables

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import * as firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/database";

// https://stackoverflow.com/a/51093815/10777177
document.body.addEventListener("mousedown", function() {
  document.body.classList.add("using-mouse");
});

document.body.addEventListener("keydown", function() {
  document.body.classList.remove("using-mouse");
});

firebase.initializeApp(window.firebaseConfig);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
