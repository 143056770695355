import React, { Component } from "react";

import styles from "./TextInput.module.css";

import * as Icons from "react-feather";

import Spinner from "components/spinner/Spinner";

export interface TextInputValidator {
  validate: (value?: string, formValues?: { [key: string]: string }) => Promise<true | string>;
}

interface TextInputProps {
  name?: string;
  value: string;
  placeholder?: string;
  spellCheck?: boolean;
  type?: "text" | "password" | "email";
  tabIndex?: number;
  autoFocus?: boolean;
  validators?: TextInputValidator[];
  disabled?: boolean;
  forceTouched?: boolean;
  spinner?: boolean;

  formValues?: { [key: string]: string };

  noRadiusOnBottom?: boolean;
  suppressErrors?: boolean;

  className?: string;

  height?: number;

  /**
   * This functions is called before the input value is validate.
   * Use it to change, trim, etc. the value before validation
   */
  validationPreluder?: (v: string) => string;

  onValidationStateChanged?: (valid: boolean, name?: string) => void;
  onValidationStarted?: (name?: string) => void;
  onValidationCompleted?: (name?: string) => void;
  onChange?: (value: string, name?: string) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;

  icon?: React.ComponentType<Icons.Props>;
  iconSide?: "left" | "right";
}

interface TextInputState {
  // Validation Types
  isValid: boolean;
  error?: string;
  validationsInProgress: number;
  touched: boolean;
}

class TextInput extends Component<TextInputProps, TextInputState> {
  private input: HTMLInputElement | null = null;
  private validationsInProgress = 0;

  public constructor(props: TextInputProps) {
    super(props);

    this.state = {
      touched: false,
      isValid: true,
      validationsInProgress: 0
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleIconClick = this.handleIconClick.bind(this);
  }

  public componentDidMount(): void {
    this.propagateState();
  }

  public componentDidUpdate(prevProps: TextInputProps): void {
    if (prevProps.value !== this.props.value) {
      this.propagateState();
    }
  }

  public render(): JSX.Element {
    const p = this.props;
    const s = this.state;

    const showErrors =
      s.validationsInProgress === 0 && !p.suppressErrors && !s.isValid && (p.forceTouched || s.touched);

    let heightStyle = {};
    let iconStyle = {};

    if (this.props.height) {
      heightStyle = { height: this.props.height + "px" };
      iconStyle = { height: this.props.height - 2 + "px", width: this.props.height - 2 + "px" };
    }

    return (
      <div
        className={
          styles.TextInput +
          (showErrors ? " " + styles.invalid : "") +
          (this.props.className ? " " + this.props.className : "")
        }
        style={heightStyle}
      >
        <div className={styles.inputContainer} style={heightStyle}>
          <div
            className={styles.inputBorder + (this.props.noRadiusOnBottom ? " " + styles.noRadiusOnBottom : "")}
            style={heightStyle}
          >
            {this.props.icon && this.props.iconSide === "left" && (
              <div className={styles.icon} onClick={this.handleIconClick} style={iconStyle}>
                <this.props.icon size={16} strokeWidth={1} />
              </div>
            )}
            <input
              placeholder={p.placeholder}
              spellCheck={p.spellCheck}
              type={p.type}
              value={p.value}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              tabIndex={p.tabIndex}
              autoFocus={p.autoFocus}
              onKeyPress={p.onKeyPress}
              disabled={p.disabled}
              ref={i => (this.input = i)}
              style={heightStyle}
            />
            {(this.props.spinner || s.validationsInProgress > 0) && (
              <div className={styles.spinnerContainer} onClick={this.handleIconClick} style={iconStyle}>
                <Spinner size={16} colorful />
              </div>
            )}
            {this.props.icon && (!this.props.iconSide || this.props.iconSide === "right") && (
              <div className={styles.icon} onClick={this.handleIconClick} style={iconStyle}>
                <this.props.icon size={16} strokeWidth={1} />
              </div>
            )}
          </div>
        </div>
        {showErrors && <div className={styles.errorContainer}>{s.error || "Error."}</div>}
      </div>
    );
  }

  private handleBlur(): void {}

  private handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (!this.state.touched) {
      this.setState({
        touched: true
      });
    }

    const newValue = event.target.value;

    if (this.props.onChange) {
      this.props.onChange(newValue, this.props.name);
    }
  }

  private async propagateState(): Promise<void> {
    if (this.props.onValidationStateChanged) {
      this.props.onValidationStateChanged(await this.isValid(), this.props.name);
    }
  }

  private async isValid(): Promise<boolean> {
    if (this.state.isValid !== true) {
      this.setState({
        isValid: true,
        error: undefined
      });
    }

    if (this.props.validators) {
      if (this.props.onValidationStarted) this.props.onValidationStarted(this.props.name);

      const v = this.props.validationPreluder ? this.props.validationPreluder(this.props.value) : this.props.value;

      for (let i = 0; i < this.props.validators.length; i++) {
        const validator = this.props.validators[i];

        this.validationsInProgress++;

        this.setState({
          validationsInProgress: this.validationsInProgress
        });

        const result = await validator.validate(v, this.props.formValues);

        this.validationsInProgress--;

        this.setState({
          validationsInProgress: this.validationsInProgress
        });

        if (result !== true) {
          this.setState({
            error: result,
            isValid: false
          });

          if (this.props.onValidationCompleted) this.props.onValidationCompleted(this.props.name);
          return false;
        }
      }

      if (this.props.onValidationCompleted) this.props.onValidationCompleted(this.props.name);
    }

    return true;
  }

  private handleIconClick() {
    if (this.input) {
      this.input.focus();
    }
  }
}

export default TextInput;
