import React, { Component } from "react";

import styles from "./UserItem.module.css";
import UserAvatar from "components/userAvatar/UserAvatar";
import UserProfileMethods from "modules/api/userProfileMethods";

interface UserItemProps {
  userId: string;
  filter: string;
  onClick?: (uid: string) => void;
}

interface UserItemState {
  name?: string;
  pic?: string;
  username?: string;
}

export default class UserItem extends Component<UserItemProps, UserItemState> {
  constructor(props: UserItemProps) {
    super(props);

    this.state = {};

    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    const filter = this.props.filter
      .toLowerCase()
      .replace(" ", "")
      .replace("@", "");
    const shouldShow =
      (this.state.name &&
        this.state.name
          .replace(" ", "")
          .toLowerCase()
          .includes(filter)) ||
      (this.state.username &&
        this.state.username
          .replace(" ", "")
          .toLowerCase()
          .includes(filter));

    if (shouldShow)
      return (
        <div className={styles.user} tabIndex={0} onClick={this.handleClick}>
          <UserAvatar size={32} pic={this.state.pic} />
          <div className={styles.userDetails}>
            <div className={styles.name}>{this.state.name}</div>
            <div className={styles.username}>@{this.state.username}</div>
          </div>
        </div>
      );

    return <></>;
  }

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps: UserItemProps) {
    if (prevProps.userId !== this.props.userId) this.updateData();
  }

  async updateData() {
    const user = await UserProfileMethods.getUserDetails(this.props.userId);
    const username = await UserProfileMethods.getUserUsername(this.props.userId);

    if (user && username) {
      this.setState({
        name: user.name,
        username: username,
        pic: user.pic
      });
    }
  }

  handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (this.props.onClick) {
      this.props.onClick(this.props.userId);
    }

    e.stopPropagation();
  }
}
