import { ERROR_MESSAGES } from "./errorMessages";

export const COMMON_ERRORS = {
  UNKNOWN: {
    code: "unknown",
    message: ERROR_MESSAGES.UNKNOWN
  },

  ACTION_REQUIRES_LOGIN: {
    code: "actionRequiresLogin",
    message: ERROR_MESSAGES.ACTION_REQUIRES_LOGIN
  },

  INEXISTS: {
    code: "inexists",
    message: ERROR_MESSAGES.INEXISTS
  },

  PROJECT_INEXISTS: {
    code: "projectInexists",
    message: ERROR_MESSAGES.PROJECT_INEXISTS
  },

  NOT_PERMITTED: {
    code: "notPermitted",
    message: ERROR_MESSAGES.NOT_PERMITTED
  },

  PROJECT_INVITE: {
    TOKEN_REVOKED: {
      code: "projectInvite/tokenRevoked",
      message: ERROR_MESSAGES.PROJECT_INVITE_REVOKED
    },

    PROJECT_DELETED: {
      code: "projectInvite/projectDeleted",
      message: ERROR_MESSAGES.PROJECT_DELETED
    },

    TOKEN_EXPIRED: {
      code: "projectInvite/tokenExpired",
      message: ERROR_MESSAGES.PROJECT_TOKEN_EXPIRED
    },

    EMAILS_DOESNT_MATCH: {
      code: "projectInvite/emailsDoesntMatch",
      message: ERROR_MESSAGES.PROJECT_INVITE_EMAILS_DOESNT_MATCH
    },

    INVALID_TOKEN: {
      code: "projectInvite/invalidToken",
      message: ERROR_MESSAGES.INVALID_TOKEN
    }
  },

  USER_PROFILE: {
    PROFILE_INEXISTS: {
      code: "userProfile/profileInexists",
      message: ERROR_MESSAGES.PROFILE_INEXISTS
    },

    MISSING_COMPANY: {
      code: "userProfile/missingCompany",
      message: ERROR_MESSAGES.MISSING_COMPANY
    },

    MISSING_NAME: {
      code: "userProfile/missingName",
      message: ERROR_MESSAGES.MISSING_NAME
    },

    MISSING_USERNAME: {
      code: "userProfile/missingUsername",
      message: ERROR_MESSAGES.MISSING_USERNAME
    },

    USERNAME_TAKEN: {
      code: "userProfile/usernameTaken",
      message: ERROR_MESSAGES.USERNAME_TAKEN
    },

    USERNAME_INVALID: {
      code: "userProfile/usernameInvalid",
      message: ERROR_MESSAGES.USERNAME_INVALID
    }
  },

  PROJECT_CREATION: {
    ALIAS_INVALID: {
      code: "projectCreation/aliasInvalid",
      message: ERROR_MESSAGES.ALIAS_INVALID
    },

    ALIAS_TAKEN: {
      code: "projectCreation/aliasTaken",
      message: ERROR_MESSAGES.ALIAS_TAKEN
    }
  }
};
