import React, { Component } from "react";

import styles from "./ProjectsListItem.module.css";

import ProjectData from "modules/api/apiModels/projectData";

import * as Icons from "react-feather";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";
import FloatingMenu from "components/floatingMenu/FloatingMenu";
import ProgressBar from "components/progressBar/ProgressBar";
import PunchlistMethods from "modules/api/punchlistMethods";
import Spinner from "components/spinner/Spinner";
import FlatIconButton from "components/flatIconButton/FlatIconButton";

interface ProjectsListItemProps extends RouteComponentProps {
  item: ProjectData;
  id: string;
}

interface ProjectsListItemState {
  open: boolean;
  checked: number;
  unchecked: number;
  loadingChecks: boolean;
}

class ProjectsListItem extends Component<ProjectsListItemProps, ProjectsListItemState> {
  public static contextType = AuthContext;
  public context!: React.ContextType<typeof AuthContext>;

  constructor(props: ProjectsListItemProps) {
    super(props);

    this.state = {
      open: false,
      checked: 0,
      unchecked: 0,
      loadingChecks: true
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleMenuBtn = this.toggleMenuBtn.bind(this);
    this.handleManageClick = this.handleManageClick.bind(this);
  }

  render() {
    return (
      <div className={styles.ProjectItem}>
        <Link to={`/app/punchlist/${this.props.item.punchlist}`} className={styles.leftSide}>
          <div className={styles.iconContainer}>
            <Icons.List></Icons.List>
          </div>
          <div className={styles.titleContainer}>{this.props.item.name}</div>
          {this.props.item.owner === this.context.uid && <div className={styles.ownerBadge}>owner</div>}
        </Link>
        <div className={styles.rightSide}>
          <div className={styles.progress}>
            {this.state.checked}/{this.state.checked + this.state.unchecked}
          </div>
          <div className={styles.progressBarContainer}>
            {!this.state.loadingChecks ? (
              <ProgressBar progress={this.state.checked / (this.state.checked + this.state.unchecked)} />
            ) : (
              <Spinner colorful size={16} />
            )}
          </div>
          <FloatingMenu
            items={[
              {
                label: "Manage",
                icon: Icons.Settings,
                onClick: this.handleManageClick
              }
            ]}
            fixOn="right"
            show={this.state.open}
            onClickOutside={this.toggleMenu}
          >
            <FlatIconButton icon={Icons.MoreVertical} onClick={this.toggleMenuBtn} />
          </FloatingMenu>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadProgress();
  }

  toggleMenu() {
    this.setState({
      open: !this.state.open
    });
  }

  toggleMenuBtn() {
    if (!this.state.open)
      this.setState({
        open: true
      });
  }

  async loadProgress() {
    const progress = await PunchlistMethods.getPunchlistProgress(this.props.item.punchlist);

    if (!progress.response) {
      this.setState({
        checked: 0,
        unchecked: 0,
        loadingChecks: false
      });
    }

    this.setState({
      checked: progress.response!.checked,
      unchecked: progress.response!.unchecked,
      loadingChecks: false
    });
  }

  handleManageClick() {
    // this.props.history.push(this.props.location.pathname + "/manage/" + this.props.id, { project: this.props.item }); --> Use this to Push State
    this.props.history.push(this.props.location.pathname + "/manage/" + this.props.id);
  }
}

export default withRouter(ProjectsListItem);
