import React, { Component } from "react";

import styles from "./NewItem.module.css";

import * as Icons from "react-feather";
import { RouteComponentProps, withRouter } from "react-router-dom";

class NewItem extends Component<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    return (
      <div className={styles.RecentItem} onClick={this.handleClick}>
        <div className={styles.avatarArea}>
          <div className={styles.teamAvatar}>
            <Icons.Plus />
          </div>
        </div>
        <div className={styles.descArea}>
          <div className={styles.teamName}>Create Project</div>
        </div>
      </div>
    );
  }

  handleClick() {
    this.props.history.push("/app/projects/create");
  }
}

export default withRouter(NewItem);
