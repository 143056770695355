import React, { Component } from "react";

import styles from "./InsertingUserDetails.module.css";
import UserAvatar from "components/userAvatar/UserAvatar";

import Spinner from "components/spinner/Spinner";
import UserProfileMethods from "modules/api/userProfileMethods";
import { UserProfileSettings } from "modules/api/apiModels/UserProfileSettings";
import SuperButton from "components/superButton/SuperButton";

interface InsertingUserDetailsProps {
  appended?: boolean;
  noRadiusBottom?: boolean;
  noRadiusTop?: boolean;
  noBorderBottom?: boolean;
  noBorderTop?: boolean;
  username?: string;
  email?: string;
  alreadyInvited?: boolean;
  isMe?: boolean;
  removeBtn?: boolean;
  removeBtnText?: string;
  noBtn?: boolean;
  onInviteClick?: (usernameOrEmail: string) => void;
  onRemoveClick?: (usernameOrEmail: string) => void;
  isMailList?: boolean;
}

interface InsertingUserDetailsState {
  loading?: boolean;
  profile?: UserProfileSettings;
}

class InsertingUserDetails extends Component<InsertingUserDetailsProps, InsertingUserDetailsState> {
  constructor(props: InsertingUserDetailsProps) {
    super(props);

    this.state = {
      loading: true
    };

    this.handleInviteClick = this.handleInviteClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
  }

  componentDidMount() {
    if (this.props.username) {
      this.loadUsername(this.props.username);
    } else if (this.props.email) {
      this.loadEmail(this.props.email);
    }
  }

  componentDidUpdate(prevProps: InsertingUserDetailsProps) {
    if (prevProps.email !== this.props.email || prevProps.username !== this.props.username) {
      if (this.props.username) {
        this.loadUsername(this.props.username);
      } else if (this.props.email) {
        this.loadEmail(this.props.email);
      }
    }
  }

  render() {
    const classStr = this.buildClassString();

    const mailListMatches =
      this.props.isMailList &&
      this.props.email!.match(
        /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*/
      );

    let mailList = "";

    if (mailListMatches) {
      mailListMatches.forEach(x => (mailList = mailList + (mailList !== "" ? ",\n" : "") + x));
    }

    return (
      <div className={styles.InsertingUserDetails + classStr}>
        {!this.state.loading && (
          <>
            <div className={styles.avatarContainer}>
              <UserAvatar pic={this.state.profile ? this.state.profile.pic : undefined} size={36} />
            </div>

            <div className={styles.details}>
              {this.state.profile && this.state.profile.name && (
                <span className={styles.name}>{this.state.profile.name}</span>
              )}
              {this.props.username && <span className={styles.username}>@{this.props.username}</span>}
            </div>

            <div className={styles.buttonContainer}>
              {!this.props.removeBtn && !this.props.noBtn && (
                <SuperButton outline label="Invite" compact onClick={this.handleInviteClick} />
              )}
              {this.props.removeBtn && !this.props.noBtn && (
                <SuperButton
                  outline
                  label={this.props.removeBtnText || "Remove"}
                  compact
                  customColor="#ff0022"
                  onClick={this.handleRemoveClick}
                />
              )}
              {this.props.alreadyInvited && !this.props.isMe && (
                <SuperButton outline label="Invited" compact customColor="#000000" disabled />
              )}

              {this.props.isMe && <SuperButton outline label="You" compact customColor="#000000" disabled />}
            </div>
          </>
        )}

        {this.state.loading && (
          <div className={styles.loadingContainer}>
            <Spinner size={20} colorful />
          </div>
        )}
      </div>
    );
  }

  async loadUsername(username: string) {
    this.setState({
      loading: true
    });

    const userId = await UserProfileMethods.getUserId(username);

    if (!userId) {
      this.setState({
        loading: false
      });

      return;
    }

    const profile = await UserProfileMethods.getUserDetails(userId);

    this.setState({
      loading: false,
      profile: profile
    });
  }

  loadEmail(email: string) {
    const mailListMatches =
      this.props.isMailList &&
      this.props.email!.match(
        /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*/g
      );

    let mailList = "";

    if (mailListMatches) {
      mailListMatches.forEach(x => (mailList = mailList + (mailList !== "" ? ",\n" : "") + x));
    }

    this.setState({
      profile: {
        name: this.props.isMailList ? mailList : email
      },
      loading: false
    });
  }

  buildClassString(): string {
    const p = this.props;

    // build class string
    let className = "";

    if (p.appended) className = className + " " + styles.appended;
    if (p.noBorderBottom) className = className + " " + styles.noBorderBottom;
    if (p.noBorderTop) className = className + " " + styles.noBorderTop;
    if (p.noRadiusBottom) className = className + " " + styles.noRadiusBottom;
    if (p.noRadiusTop) className = className + " " + styles.noRadiusTop;

    return className;
  }

  handleInviteClick() {
    if (this.props.onInviteClick) this.props.onInviteClick(this.props.username || this.props.email || "");
  }

  handleRemoveClick() {
    if (this.props.onRemoveClick) this.props.onRemoveClick(this.props.username || this.props.email || "");
  }
}

export default InsertingUserDetails;
