import React, { Component } from "react";
import OrValidator from "modules/customInputDataValidators/textValidators/orValidator";
import TextEqualsValidator from "modules/customInputDataValidators/textValidators/textEqualsValidator";
import BlendValidator from "modules/customInputDataValidators/textValidators/blendValidator";
import NotValidator from "modules/customInputDataValidators/textValidators/notValidator";
import { DEFAULT_USERNAME_FORMAT_VALIDATOR } from "modules/customInputDataValidators/textValidators/usernameFormatValidator";
import { DEFAULT_USERNAME_AVAILABLE_VALIDATOR } from "modules/customInputDataValidators/textValidators/usernameAvailableValidator";
import {
  SIMPLE_EMAIL_LIST_VALIDATOR,
  NAMED_EMAIL_LIST_VALIDATOR,
  EMAIL_VALIDATOR
} from "modules/customInputDataValidators/textValidators/regexValidator";
import TextInput from "components/inputs/textInput/TextInput";
import InsertingUserDetails from "components/insertingUserDetails/InsertingUserDetails";

import * as Icons from "react-feather";

import styles from "./ProjectUserList.module.css";
import { AuthContext } from "contexts/AuthContext";

const USERNAME_VALIDATORS = [
  new OrValidator(
    "Input is neither a valid and existing user nor a valid e-mail address.",
    new TextEqualsValidator("", "Invalid"),
    new BlendValidator(
      "Error.",
      new NotValidator(new TextEqualsValidator("", "Invalid"), ""),
      DEFAULT_USERNAME_FORMAT_VALIDATOR,
      new NotValidator(DEFAULT_USERNAME_AVAILABLE_VALIDATOR, "There's no user registred with this username.")
    ),
    SIMPLE_EMAIL_LIST_VALIDATOR,
    NAMED_EMAIL_LIST_VALIDATOR
  )
];

interface ProjectUserListState {
  searchingUsername: string;
  searchingUsernameValid: boolean;
}

interface ProjectUserListProps {
  onAddMember?: (member: string) => void;
  onRemoveMember?: (member: string) => void;
  onRemoveAux?: (member: string) => void;
  members: string[];
  auxMembers?: string[];
  auxRemoveText?: string;
  hideActionButtons?: boolean;
  disabled?: boolean;
}

export default class ProjectUserList extends Component<ProjectUserListProps, ProjectUserListState> {
  public static contextType = AuthContext;
  public context!: React.ContextType<typeof AuthContext>;

  constructor(props: ProjectUserListProps) {
    super(props);

    this.state = {
      searchingUsername: "",
      searchingUsernameValid: true
    };

    this.addSearchingUser = this.addSearchingUser.bind(this);
    this.usernamePreluder = this.usernamePreluder.bind(this);
    this.handleInputKeypress = this.handleInputKeypress.bind(this);
    this.handleMemberRemove = this.handleMemberRemove.bind(this);
    this.handleAuxRemove = this.handleAuxRemove.bind(this);
  }

  render() {
    const trimmedSearchingUsername = this.usernamePreluder(this.state.searchingUsername.trim());

    const searchingIsUsername =
      EMAIL_VALIDATOR.validateSync(trimmedSearchingUsername) !== true &&
      DEFAULT_USERNAME_FORMAT_VALIDATOR.validateSync(trimmedSearchingUsername) === true;

    const searchingIsMail = EMAIL_VALIDATOR.validateSync(trimmedSearchingUsername) === true;

    const searchingIsSimpleMailList = SIMPLE_EMAIL_LIST_VALIDATOR.validateSync(trimmedSearchingUsername) === true;
    const searchingIsNamedMailList = NAMED_EMAIL_LIST_VALIDATOR.validateSync(trimmedSearchingUsername) === true;

    return (
      <div>
        <label className={styles.formEntry}>
          Members
          <div className={styles.formEntryCaption}>
            Type an @username of a registered user or a comma-separated e-mail address list. An invite to join your
            project will be sent to this person.
          </div>
          <div className={styles.formInput}>
            <TextInput
              value={this.state.searchingUsername}
              type="text"
              placeholder="@username or email@address.com"
              icon={Icons.AtSign}
              iconSide="left"
              noRadiusOnBottom={this.state.searchingUsernameValid && trimmedSearchingUsername !== ""}
              suppressErrors={trimmedSearchingUsername === ""}
              onChange={this.setSearchingUsername}
              onValidationStateChanged={this.setSearchingUsernameValid}
              validators={USERNAME_VALIDATORS}
              validationPreluder={this.usernamePreluder}
              onKeyPress={this.handleInputKeypress}
              disabled={this.props.disabled}
            />
          </div>
        </label>
        {this.state.searchingUsernameValid &&
          (searchingIsUsername || searchingIsMail || searchingIsNamedMailList || searchingIsSimpleMailList) && (
            <InsertingUserDetails
              appended
              username={searchingIsUsername ? trimmedSearchingUsername : undefined}
              email={
                searchingIsMail || searchingIsSimpleMailList || searchingIsNamedMailList
                  ? trimmedSearchingUsername
                  : undefined
              }
              isMailList={!searchingIsMail && (searchingIsSimpleMailList || searchingIsNamedMailList)}
              onInviteClick={this.addSearchingUser}
              alreadyInvited={this.props.members.includes(trimmedSearchingUsername)}
              noBtn={this.props.members.includes(trimmedSearchingUsername)}
              isMe={this.context.username === trimmedSearchingUsername}
            />
          )}

        {this.props.members.length > 0 && (
          <div className={styles.insertedUsers}>
            {this.props.members.map((v, i, a) => {
              const trimmedUsername = this.usernamePreluder(v.trim());

              const isMe = this.context.username === trimmedUsername;
              const isFirst = i === 0;
              const isLast = i === a.length - 1;

              const isUsername =
                EMAIL_VALIDATOR.validateSync(trimmedUsername) !== true &&
                DEFAULT_USERNAME_FORMAT_VALIDATOR.validateSync(trimmedUsername) === true;

              const isEmail = EMAIL_VALIDATOR.validateSync(trimmedUsername) === true;

              return (
                <InsertingUserDetails
                  username={isUsername ? trimmedUsername : undefined}
                  email={isEmail ? trimmedUsername : undefined}
                  noBorderBottom={!isLast}
                  noRadiusBottom={!isLast}
                  noRadiusTop={!isFirst}
                  removeBtn={!isMe}
                  noBtn={isMe || this.props.hideActionButtons}
                  isMe={isMe}
                  onRemoveClick={this.handleMemberRemove}
                  key={i}
                />
              );
            })}
          </div>
        )}

        {this.props.auxMembers && this.props.auxMembers.length > 0 && (
          <div className={styles.insertedUsers}>
            {this.props.auxMembers.map((v, i, a) => {
              const trimmedUsername = this.usernamePreluder(v.trim());

              const isMe = this.context.username === trimmedUsername;
              const isFirst = i === 0;
              const isLast = i === a.length - 1;

              const isUsername =
                EMAIL_VALIDATOR.validateSync(trimmedUsername) !== true &&
                DEFAULT_USERNAME_FORMAT_VALIDATOR.validateSync(trimmedUsername) === true;

              const isEmail = EMAIL_VALIDATOR.validateSync(trimmedUsername) === true;

              return (
                <InsertingUserDetails
                  username={isUsername ? trimmedUsername : undefined}
                  email={isEmail ? trimmedUsername : undefined}
                  noBorderBottom={!isLast}
                  noRadiusBottom={!isLast}
                  noRadiusTop={!isFirst}
                  removeBtn={!isMe}
                  removeBtnText={this.props.auxRemoveText}
                  noBtn={isMe || this.props.hideActionButtons}
                  isMe={isMe}
                  onRemoveClick={this.handleAuxRemove}
                  key={i}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }

  usernamePreluder(username: string) {
    if (username[0] === "@") return username.substr(1);

    return username;
  }

  handleAuxRemove(user: string) {
    if (this.props.onRemoveAux) this.props.onRemoveAux(user);

    // const x = [...this.members];
    // x.splice(x.indexOf(user), 1);

    // this.members = x;

    // this.setState({
    //   members: this.members
    // });
  }

  handleMemberRemove(user: string) {
    if (this.props.onRemoveMember) this.props.onRemoveMember(user);

    // const x = [...this.members];
    // x.splice(x.indexOf(user), 1);

    // this.members = x;

    // this.setState({
    //   members: this.members
    // });
  }

  handleInputKeypress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      this.addSearchingUser();
    }
  }

  addSearchingUser() {
    if (!this.state.searchingUsernameValid) return;

    const trimmedUsername = this.usernamePreluder(this.state.searchingUsername.trim());

    const usernameValidator = DEFAULT_USERNAME_FORMAT_VALIDATOR.validateSync(trimmedUsername);
    const emailListValidator = SIMPLE_EMAIL_LIST_VALIDATOR.validateSync(trimmedUsername);
    const emailNamedListValidator = NAMED_EMAIL_LIST_VALIDATOR.validateSync(trimmedUsername);

    const isEmailList =
      !(usernameValidator === true) && (emailListValidator === true || emailNamedListValidator === true);

    const usernames = [];

    if (!isEmailList) {
      usernames.push(trimmedUsername);
    } else {
      const mails = trimmedUsername.match(
        /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*/g
      );

      if (mails)
        mails.forEach(mail => {
          usernames.push(mail);
        });
    }

    usernames.forEach(u => {
      if (this.props.members.includes(u)) return;

      if (u === "") return;

      if (this.props.onAddMember) this.props.onAddMember(u);

      // this.members = [...this.members, u];
    });

    this.setState({
      // members: this.members,
      searchingUsername: ""
    });
  }

  setSearchingUsername = (val: string) => {
    this.setState({
      searchingUsername: val
    });
  };

  setSearchingUsernameValid = (val: boolean) => {
    this.setState({
      searchingUsernameValid: val
    });
  };
}
