import React, { Component } from "react";
import NewPasswordForm from "./forms/NewPasswordForm";
import EmailLinkExecutor from "./forms/EmailLinkExecutor";

type ModeString = "resetPassword" | "recoverEmail" | "verifyEmail" | "signIn";

class UserMgmt extends Component<{}> {
  private mode: ModeString | null;
  private oobCode: string | null;
  private continueUrl?: string;

  constructor(props: {}) {
    super(props);

    const paramStr = window.location.search;
    const searchStr = new URLSearchParams(paramStr);

    this.mode = (searchStr.get("mode") as unknown) as ModeString;
    this.oobCode = searchStr.get("oobCode");
    this.continueUrl = searchStr.get("continueUrl") || undefined;

    if (this.continueUrl && this.continueUrl.startsWith("https://alpha.punchlist.kaykyb.com"))
      this.continueUrl = this.continueUrl.replace("https://alpha.punchlist.kaykyb.com", "");
  }

  render() {
    if (this.oobCode) {
      switch (this.mode) {
        case "resetPassword":
          return <NewPasswordForm oobCode={this.oobCode} />;

        case "signIn":
          return <EmailLinkExecutor oobCode={this.oobCode} continueUrl={this.continueUrl} />;

        default:
          break;
      }
    }

    return <>Action not found</>;
  }
}

export default UserMgmt;
