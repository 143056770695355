import React, { Component } from "react";

interface ItemsSorterProps {
  sorting: "NEWEST_FIRST" | "OLDEST_FIRST";
}

export default class ItemsSorter extends Component<ItemsSorterProps> {
  constructor(props: ItemsSorterProps) {
    super(props);

    this.compare = this.compare.bind(this);
  }

  render() {
    return React.Children.toArray(this.props.children).sort(this.compare);
  }

  compare(): number {
    if (this.props.sorting === "NEWEST_FIRST") {
      return -1;
    }

    return 1;
  }
}
