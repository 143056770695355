import React, { Component } from "react";

import styles from "./Punchlist.module.css";
import PageHeader from "components/pageHeader/PageHeader";

import PunchlistComponent from "components/punchlist/Punchlist";
import SuperButton from "components/superButton/SuperButton";

import * as Icons from "react-feather";
import TextInput from "components/inputs/textInput/TextInput";
import { PunchlistData } from "modules/api/apiModels/punchlistData";
import { withRouter, RouteComponentProps } from "react-router";
import PunchlistMethods from "modules/api/punchlistMethods";

interface PunchlistProps extends RouteComponentProps {
  punchlistId: string;
}

interface PunchlistState {
  showAddItem: boolean;
  permissionDenied: boolean;
  punchlistTitle?: string;
  filter: string;
  loadedPunchlist?: PunchlistData;
  punchlistSessionTime: number;
}

class Punchlist extends Component<PunchlistProps, PunchlistState> {
  private sessionTimer?: any;

  constructor(props: PunchlistProps) {
    super(props);

    this.state = {
      showAddItem: false,
      permissionDenied: false,
      filter: "",
      punchlistSessionTime: Date.now()
    };

    this.handleAddItemClick = this.handleAddItemClick.bind(this);
    this.handlePermissionDenied = this.handlePermissionDenied.bind(this);
    this.handlePunchlistTitle = this.handlePunchlistTitle.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.handleManageClick = this.handleManageClick.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handlePunchlistUpdate = this.handlePunchlistUpdate.bind(this);
    this.keepSession = this.keepSession.bind(this);
  }

  render() {
    return (
      <>
        <div className={styles.topBar}></div>
        <div className={styles.Punchlist}>
          <PageHeader
            title={this.state.permissionDenied ? "Permission Denied" : this.state.punchlistTitle || "Loading..."}
            titleColor="#ffffff"
            spinner={this.state.loadedPunchlist || this.state.permissionDenied ? undefined : "white"}
          >
            {!this.state.permissionDenied && (
              <>
                <TextInput
                  placeholder="Search this Punchlist..."
                  value={this.state.filter}
                  type="text"
                  icon={Icons.Search}
                  className={styles.search}
                  height={36}
                  iconSide="left"
                  onChange={this.handleFilterChange}
                />
                <SuperButton
                  label="Export"
                  customColor="#ffffff"
                  outline
                  icon={Icons.DownloadCloud}
                  onClick={this.handleExport}
                  className={styles.exportBtn}
                ></SuperButton>
                <SuperButton
                  label="Add Item"
                  customColor="#ffffff"
                  outline
                  icon={Icons.Plus}
                  onClick={this.handleAddItemClick}
                ></SuperButton>
                <SuperButton
                  label="Manage Linked Project"
                  customColor="#ffffff"
                  outline
                  icon={Icons.Settings}
                  onClick={this.handleManageClick}
                  className={styles.manageBtn}
                  iconOnly
                ></SuperButton>
              </>
            )}
          </PageHeader>

          <div className={styles.container}>
            {this.state.permissionDenied ? (
              <span style={{ color: "#FFFFFF" }}>You don't have permission to access this content.</span>
            ) : (
              <PunchlistComponent
                filter={this.state.filter}
                showTopAddItem={this.state.showAddItem}
                punchlistId={this.props.punchlistId}
                handleCloseTopAddItemClick={this.handleAddItemClick}
                onPermissionDenied={this.handlePermissionDenied}
                onTitleChanged={this.handlePunchlistTitle}
                onLoad={this.handleLoad}
                onUpdate={this.handlePunchlistUpdate}
                sessionDate={this.state.punchlistSessionTime}
              />
            )}
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    this.retrieveSession();
    this.sessionTimer = setInterval(this.keepSession, 240000);

    PunchlistMethods.setVisit(this.props.punchlistId);
  }

  componentWillUnmount() {
    clearInterval(this.sessionTimer);
  }

  handleAddItemClick() {
    this.setState({
      showAddItem: !this.state.showAddItem
    });
  }

  handlePermissionDenied() {
    this.setState({
      permissionDenied: true
    });
  }

  handlePunchlistTitle(title: string) {
    this.setState({
      punchlistTitle: title
    });
  }

  handleFilterChange(value: string) {
    this.setState({
      filter: value
    });
  }

  handleLoad(data: PunchlistData) {
    this.setState({
      loadedPunchlist: data
    });
  }

  handlePunchlistUpdate(data: PunchlistData) {
    this.setState({
      loadedPunchlist: data
    });
  }

  handleManageClick() {
    if (!this.state.loadedPunchlist) {
      return;
    }

    this.props.history.push("/app/projects/manage/" + this.state.loadedPunchlist.project);
  }

  async handleExport() {
    if (!this.state.loadedPunchlist) {
      return;
    }

    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.state.loadedPunchlist));
    let downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", this.state.loadedPunchlist.name + ".json");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  async retrieveSession() {
    const punchlistSession = await PunchlistMethods.getPunchlistSession(this.props.punchlistId);

    if (punchlistSession.sessionDate > 0 && Date.now() - punchlistSession.sessionPingDate < 300000) {
      this.setState({
        punchlistSessionTime: punchlistSession.sessionDate
      });

      await this.keepSession();
    } else {
      this.setState({
        punchlistSessionTime: Date.now()
      });

      await PunchlistMethods.startPunchlistSession(this.props.punchlistId);
    }
  }

  async keepSession() {
    await PunchlistMethods.pingPunchlistSession(this.props.punchlistId);
  }
}

export default withRouter(Punchlist);
