import { TextInputValidator } from "components/inputs/textInput/TextInput";

class OrValidator implements TextInputValidator {
  private errorMessage: string;
  private validators: TextInputValidator[];

  constructor(errorMessage: string, ...validators: TextInputValidator[]) {
    this.errorMessage = errorMessage;
    this.validators = validators;
  }

  public async validate(value?: string): Promise<true | string> {
    if (value === undefined) {
      return this.errorMessage;
    }

    for (let i = 0; i < this.validators.length; i++) {
      const validator = this.validators[i];

      const isValid = await validator.validate(value);

      if (isValid === true) {
        return true;
      }
    }

    return this.errorMessage;
  }
}

export default OrValidator;
