import React, { Component } from "react";

import FormWall from "components/formWall/FormWall";
import AuthForm, { AuthFormField } from "components/authForm/AuthForm";
import { EMAIL_VALIDATOR } from "modules/customInputDataValidators/textValidators/regexValidator";
import { AuthContext } from "contexts/AuthContext";
import {
  DEFAULT_REQUIRED_VALIDATOR,
  DEFAULT_PASSWORD_LENGTH_VALIDATOR
} from "modules/customInputDataValidators/textValidators/minLengthValidator";
// import SiblingInputValidator from "components/inputs/validators/siblingInputValidator";
import SocialLogins from "components/socialLogins/SocialLogins";
import { Redirect, RouteComponentProps, withRouter } from "react-router";

import * as Icons from "react-feather";

const REGISTER_FIELDS: AuthFormField[] = [
  {
    name: "name",
    placeholder: "John Doe",
    label: "Name",
    type: "text",
    validators: [DEFAULT_REQUIRED_VALIDATOR],
    icon: Icons.User
  },
  {
    name: "company",
    placeholder: "Company Name",
    label: "Company",
    type: "text",
    validators: [DEFAULT_REQUIRED_VALIDATOR],
    icon: Icons.Shield
  },
  {
    name: "email",
    placeholder: "example@example.com",
    label: "E-mail",
    type: "email",
    validators: [DEFAULT_REQUIRED_VALIDATOR, EMAIL_VALIDATOR],
    icon: Icons.Mail
  },
  {
    name: "password",
    placeholder: "••••••",
    label: "Password",
    type: "password",
    validators: [DEFAULT_REQUIRED_VALIDATOR, DEFAULT_PASSWORD_LENGTH_VALIDATOR],
    icon: Icons.Lock
  }
  // {
  //   name: "password_confirmation",
  //   placeholder: "••••••",
  //   label: "Confirm Password",
  //   type: "password",
  //   validators: [
  //     DEFAULT_REQUIRED_VALIDATOR,
  //     DEFAULT_PASSWORD_LENGTH_VALIDATOR,
  //     new SiblingInputValidator("password", "Passwords doesn't match.")
  //   ],
  //   icon: Icons.Lock
  // }
];

interface RegisterState {
  hasError: boolean;
  error?: string;

  redirectBack: boolean;
}

class Register extends Component<RouteComponentProps<any>, RegisterState> {
  public static contextType = AuthContext;
  public context!: React.ContextType<typeof AuthContext>;

  public constructor(props: RouteComponentProps<any>) {
    super(props);

    this.state = {
      hasError: false,
      redirectBack: false
    };

    this.handleContinue = this.handleContinue.bind(this);
    this.handleGithubContinue = this.handleGithubContinue.bind(this);
    this.handleGoogleContinue = this.handleGoogleContinue.bind(this);
  }

  public render(): JSX.Element {
    let redirectTo = "/app";

    const query = new URLSearchParams(window.location.search);
    const redirectBack = query.get("next");
    if (redirectBack) redirectTo = redirectBack;

    if (this.state.redirectBack) return <Redirect to={{ pathname: redirectTo }} />;

    return (
      <div className="Register">
        <FormWall
          actionLink={{
            caption: "Already have an account?",
            label: "Login",
            route: "/login/?next=" + redirectTo
          }}
        >
          <div className="r-register-form">
            <AuthForm
              fields={REGISTER_FIELDS}
              title="Sign Up"
              onContinue={this.handleContinue}
              processing={this.context.authState !== "guest"}
              masterError={this.state.error}
              invertChildren
            >
              <SocialLogins
                divisorTitle="OR"
                invertOr
                onGithubClick={this.handleGithubContinue}
                onGoogleClick={this.handleGoogleContinue}
              />
            </AuthForm>
          </div>
        </FormWall>
      </div>
    );
  }

  private async handleContinue(values: { [key: string]: string }): Promise<void> {
    const authContext = this.context;

    const name = values["name"];
    const company = values["company"];
    const password = values["password"];
    const email = values["email"];

    const response = await authContext.register(name, company, email, password, password);

    this.setState({
      hasError: !response.status,
      error: !response.status ? response.errors[0].message : undefined
    });

    if (response.status) {
      this.setState({
        redirectBack: true
      });
    }
  }

  private async handleGithubContinue(): Promise<void> {
    const authContext = this.context;

    const response = await authContext.loginGithub();

    this.setState({
      hasError: !response.status,
      error: !response.status ? response.errors[0].message : undefined
    });

    if (response.status) {
      this.setState({
        redirectBack: true
      });
    }
  }

  private async handleGoogleContinue(): Promise<void> {
    const authContext = this.context;

    const response = await authContext.loginGoogle();

    this.setState({
      hasError: !response.status,
      error: !response.status ? response.errors[0].message : undefined
    });

    if (response.status) {
      this.setState({
        redirectBack: true
      });
    }
  }
}

export default withRouter(Register);
