import React, { Component } from "react";

import styles from "./PunchlistItem.module.css";
import Tick from "components/tick/Tick";

import * as Icons from "react-feather";

import { PunchlistItem as PunchlistItemData } from "modules/api/apiModels/punchlistItem";
import { UserProfileSettings } from "modules/api/apiModels/UserProfileSettings";
import UserProfileMethods from "modules/api/userProfileMethods";
import Spinner from "components/spinner/Spinner";
import TimeUtil from "modules/util/time";
import PunchlistMethods from "modules/api/punchlistMethods";
import ItemActions from "./itemActions/ItemActions";
import ItemComment from "./itemComment/ItemComment";
import AddCommentBox from "./addCommentBox/AddCommentBox";
import CommentsSorter from "./commentsSorter/CommentsSorter";
import Tooltip from "components/tooltip/Tooltip";
import CommentsBar from "./commentsBar/CommentsBar";
import { ContainerQuery } from "react-container-query";
import UserPickerFlyout from "components/userPickerFlyout/UserPickerFlyout";
import UserAvatar from "components/userAvatar/UserAvatar";

interface PunchlistItemProps {
  itemId: string;
  itemData: PunchlistItemData;
  projectMembers?: string[];
  punchlistId: string;
  hideActions?: boolean;
}

interface PunchlistItemState {
  loadingUserDetails?: boolean;
  username?: string;
  details?: UserProfileSettings;

  tickingInProgress: boolean;

  editing: boolean;

  showComments: boolean;

  commentsLimit: number;
  commentsLimitMultiplier: number;

  commentSorting: "NEWEST_FIRST" | "OLDEST_FIRST";

  assigneeOpen: boolean;

  assigneeName?: string;
}

export default class PunchlistItem extends Component<PunchlistItemProps, PunchlistItemState> {
  input?: HTMLDivElement;

  constructor(props: PunchlistItemProps) {
    super(props);

    this.state = {
      loadingUserDetails: true,
      tickingInProgress: false,

      editing: false,
      showComments: false,
      commentsLimitMultiplier: 1,
      commentsLimit: 10,

      commentSorting: "NEWEST_FIRST",

      assigneeOpen: false
    };

    this.onTickChanged = this.onTickChanged.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
    this.handlePushComment = this.handlePushComment.bind(this);
    this.handleToggleComments = this.handleToggleComments.bind(this);
    this.handleTickClick = this.handleTickClick.bind(this);
    this.handleShowMore = this.handleShowMore.bind(this);
    this.handleCommentSortingChange = this.handleCommentSortingChange.bind(this);
    this.openAssignee = this.openAssignee.bind(this);
    this.closeAssignee = this.closeAssignee.bind(this);
    this.handleAssignee = this.handleAssignee.bind(this);
    this.removeAssignee = this.removeAssignee.bind(this);
  }

  componentDidMount() {
    this.updateUserInfo();
  }

  componentDidUpdate(prevProps: PunchlistItemProps) {
    if (
      prevProps.itemData.owner !== this.props.itemData.owner ||
      prevProps.itemData.assignee !== this.props.itemData.assignee
    ) {
      this.updateUserInfo();
    }
  }

  render() {
    const rightQuery = {
      right: {
        minWidth: 760
      }
    };

    let creationDate = new Date(this.props.itemData.creationDate);

    let creationDelta = Date.now() - this.props.itemData.creationDate;
    let creationBefore = TimeUtil.getReadableDelta(creationDelta);

    let commentQtd = 0;

    if (this.props.itemData.comments) {
      for (const key in this.props.itemData.comments) {
        if (key) commentQtd++;
      }
    }

    if (creationDelta)
      return (
        <div className={styles.PunchlistItem}>
          <ContainerQuery query={rightQuery}>
            {params => (
              <>
                <div className={styles.generalContainer}>
                  <div className={styles.tickContainer}>
                    <Tooltip tooltip={this.props.itemData.punched ? "Completed" : "Mark as completed"}>
                      <Tick
                        ticked={this.props.itemData.punched}
                        onClick={this.handleTickClick}
                        loading={this.state.tickingInProgress}
                        taskName={this.props.itemData.title}
                      />
                    </Tooltip>
                  </div>
                  <div className={styles.detailsContainer}>
                    <div className={styles.detailsGrid + (!params.right ? " " + styles.compactGrid : "")}>
                      <div className={styles.detailsGroup}>
                        <div className={styles.titleContainer}>
                          {!this.state.editing && (
                            <div
                              className={styles.title + (this.props.itemData.punched ? " " + styles.punched : "")}
                              onClick={this.handleTickClick}
                            >
                              {this.props.itemData.title}
                            </div>
                          )}

                          {this.state.editing && (
                            <div
                              className={
                                styles.title +
                                " " +
                                styles.editable +
                                " " +
                                "focusLike" +
                                (this.props.itemData.punched ? " " + styles.punched : "")
                              }
                              ref={div => (this.input = div || undefined)}
                              onBlur={this.handleInputBlur}
                              onKeyDown={this.handleInputKeyDown}
                              contentEditable
                              onPaste={this.pasteInterceptor}
                            ></div>
                          )}
                        </div>

                        <div className={styles.author}>
                          <div className={styles.authorRight}>
                            {this.state.loadingUserDetails && <Spinner size={12} colorful />}
                            {this.state.details && (
                              <>
                                <Icons.User size={"0.75rem"} />
                                <div className={styles.spacer} /> {this.state.details.name} (@{this.state.username})
                              </>
                            )}
                            <div className={styles.bigspacer} />
                            <Icons.Clock size={"0.75rem"} />
                            <div className={styles.spacer} />
                            <Tooltip tooltip={creationDate.toLocaleString()}>{creationBefore}</Tooltip>

                            {!this.props.itemData.assignee && this.props.projectMembers && (
                              <>
                                <div className={styles.bigspacer} />
                                <UserPickerFlyout
                                  users={this.props.projectMembers}
                                  open={this.state.assigneeOpen}
                                  onClose={this.closeAssignee}
                                  onSelect={this.handleAssignee}
                                >
                                  <button className={styles.assigneeButton} onClick={this.openAssignee}>
                                    <Icons.UserPlus size="0.75rem"></Icons.UserPlus>
                                    <div className={styles.spacer} />
                                    Add assignee
                                  </button>
                                </UserPickerFlyout>
                              </>
                            )}

                            {this.props.itemData.assignee && this.props.projectMembers && (
                              <>
                                <div className={styles.bigspacer} />
                                <UserPickerFlyout
                                  users={this.props.projectMembers}
                                  open={this.state.assigneeOpen}
                                  onClose={this.closeAssignee}
                                  onSelect={this.handleAssignee}
                                >
                                  <button className={styles.assigneeButtonNoPaddingLeft} onClick={this.openAssignee}>
                                    <UserAvatar size={16} uid={this.props.itemData.assignee} />
                                    <div className={styles.spacer} />
                                    {this.state.assigneeName}
                                  </button>
                                </UserPickerFlyout>
                                <div className={styles.spacer} />
                                <button className={styles.assigneeButtonNoPadding} onClick={this.removeAssignee}>
                                  <Icons.UserX size="0.75rem"></Icons.UserX>
                                </button>
                              </>
                            )}
                            {!this.props.hideActions && (
                              <>
                                <div className={styles.bigspacer} />

                                <button className={styles.assigneeButton} onClick={this.handleToggleComments}>
                                  <Icons.MessageSquare size="0.75rem"></Icons.MessageSquare>
                                  <div className={styles.spacer} />
                                  Add comment
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.actionsContainer}>
                      <ItemActions
                        onDeleteClick={this.handleDelete}
                        onEditClick={this.handleEdit}
                        commentQuantity={commentQtd}
                        onCommentsClick={this.handleToggleComments}
                        showUncheck={this.props.itemData.punched}
                        onUncheckClick={this.onTickChanged}
                      />
                    </div>
                  </div>
                </div>
                {(commentQtd > 0 || this.state.showComments) && (
                  <div className={styles.commentContainer + (this.state.showComments ? " " + styles.expanded : "")}>
                    <div className={styles.commentIconContainer}>
                      <div className={styles.commentIcon}>
                        <Icons.MessageSquare strokeWidth={2} size={16} />
                      </div>
                    </div>
                    <div className={styles.commentsRoot}>
                      {this.state.showComments && <div className={styles.comments}>{this.getComments()}</div>}
                      {!this.state.showComments && this.props.itemData.comments && (
                        <div className={styles.comments}>{this.getLatestComment(params.right)}</div>
                      )}
                    </div>
                    <div className={styles.moreCommentsSection}>
                      {!this.state.showComments && (
                        <button className={styles.moreCommentsButton} onClick={this.handleToggleComments}>
                          {commentQtd} comment{commentQtd > 1 && <>s</>}
                          <div className={styles.commentIcon}>
                            <Icons.ChevronDown strokeWidth={2} size={16} />
                          </div>
                        </button>
                      )}

                      {this.state.showComments && (
                        <button className={styles.moreCommentsButtonExpanded}>
                          <div className={styles.commentIcon}>
                            <Icons.ChevronUp strokeWidth={2} size={16} onClick={this.handleToggleComments} />
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </ContainerQuery>
        </div>
      );
  }

  getLatestComment(onRight?: boolean): JSX.Element {
    if (!this.props.itemData.comments) return <></>;

    let item: JSX.Element = <></>;

    let k = 0;

    for (const key in this.props.itemData.comments) {
      const element = this.props.itemData.comments[key];

      if (!element) continue;

      item = (
        <ItemComment
          itemData={element}
          key={k}
          punchlistId={this.props.punchlistId}
          itemId={this.props.itemId}
          commentId={key}
          compact={onRight}
        />
      );

      k++;
    }

    return item;
  }

  getComments(): JSX.Element {
    if (!this.props.itemData.comments)
      return (
        <>
          <CommentsBar
            showing={0}
            total={0}
            ordering={this.state.commentSorting}
            onOrderingChanged={this.handleCommentSortingChange}
            onCollapse={this.handleToggleComments}
          />
          <AddCommentBox onEnter={this.handlePushComment} />
        </>
      );

    const items: JSX.Element[] = [];

    let k = 0;
    let total = 0;

    for (const key in this.props.itemData.comments) {
      if (key) total++;
    }

    for (const key in this.props.itemData.comments) {
      const element = this.props.itemData.comments[key];

      if (!element) continue;

      if (
        this.state.commentSorting === "NEWEST_FIRST" &&
        k >= total - this.state.commentsLimit * this.state.commentsLimitMultiplier
      )
        items.push(
          <ItemComment
            itemData={element}
            key={k}
            punchlistId={this.props.punchlistId}
            itemId={this.props.itemId}
            commentId={key}
          />
        );

      if (
        this.state.commentSorting === "OLDEST_FIRST" &&
        k < this.state.commentsLimit * this.state.commentsLimitMultiplier
      )
        items.push(
          <ItemComment
            itemData={element}
            key={k}
            punchlistId={this.props.punchlistId}
            itemId={this.props.itemId}
            commentId={key}
          />
        );

      k++;
    }

    return (
      <>
        <CommentsBar
          showing={items.length}
          total={total}
          ordering={this.state.commentSorting}
          onOrderingChanged={this.handleCommentSortingChange}
          onCollapse={this.handleToggleComments}
        />
        <AddCommentBox onEnter={this.handlePushComment} />
        <div className={styles.commentGrid}>
          <CommentsSorter sorting={this.state.commentSorting}>{items}</CommentsSorter>
        </div>
        {total > this.state.commentsLimit * this.state.commentsLimitMultiplier && (
          <button className={styles.showMoreButton} onClick={this.handleShowMore}>
            Show More Comments ({total - items.length})
          </button>
        )}
      </>
    );
  }

  async updateUserInfo() {
    this.setState({ loadingUserDetails: true });

    const uid = this.props.itemData.owner;

    const username = await UserProfileMethods.getUserUsername(uid);
    const details = await UserProfileMethods.getUserDetails(uid);

    let assignee = undefined;

    if (this.props.itemData.assignee) {
      const res = await UserProfileMethods.getUserDetails(this.props.itemData.assignee);
      assignee = res.name;
    }

    this.setState({
      loadingUserDetails: false,
      username: username || undefined,
      details: details,
      assigneeName: assignee
    });
  }

  async handleTickClick() {
    if (!this.props.itemData.punched) await this.onTickChanged();
  }

  async onTickChanged() {
    this.setState({
      tickingInProgress: true
    });

    const f = this.props.itemData.punched ? PunchlistMethods.untickItem : PunchlistMethods.tickItem;

    await f(this.props.punchlistId, this.props.itemId);

    this.setState({
      tickingInProgress: false
    });
  }

  async handleDelete() {
    await PunchlistMethods.deleteItem(this.props.punchlistId, this.props.itemId);
  }

  async handlePushComment(content: string) {
    await PunchlistMethods.pushComment(this.props.punchlistId, this.props.itemId, content);
  }

  handleCommentSortingChange(newSorting: "NEWEST_FIRST" | "OLDEST_FIRST") {
    this.setState({
      commentSorting: newSorting
    });
  }

  handleShowMore() {
    this.setState({
      commentsLimitMultiplier: this.state.commentsLimitMultiplier + 1
    });
  }

  handleToggleComments() {
    this.setState({
      showComments: !this.state.showComments,
      commentsLimitMultiplier: 1
    });
  }

  openAssignee(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({
      assigneeOpen: true
    });

    if (e) {
      e.stopPropagation();
    }
  }

  closeAssignee(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({
      assigneeOpen: false
    });

    if (e) {
      e.stopPropagation();
    }
  }

  handleEdit() {
    this.setState(
      {
        editing: true
      },
      () => {
        if (!this.input) return;

        this.input.innerText = this.props.itemData.title;

        this.input.focus();

        const textRange = document.createRange();
        textRange.selectNodeContents(this.input);
        textRange.collapse(false);
        const sel = window.getSelection();

        if (sel) {
          sel.removeAllRanges();
          sel.addRange(textRange);
        }
      }
    );
  }

  async removeAssignee() {
    await this.handleAssignee();
  }

  async handleAssignee(uid?: string) {
    await PunchlistMethods.assignItem(this.props.punchlistId, this.props.itemId, uid);

    this.setState({
      assigneeOpen: false
    });
  }

  async handleInputBlur() {
    if (!this.input) return;

    const newV = this.input.innerText;

    this.setState({
      editing: false
    });

    if (!newV || newV === this.props.itemData.title) return;

    await PunchlistMethods.setTitle(this.props.punchlistId, this.props.itemId, newV);
  }

  async handleInputKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === "Enter" && this.input) {
      event.preventDefault();
      event.stopPropagation();

      this.input.blur();
    }
  }

  pasteInterceptor(e: React.ClipboardEvent<HTMLDivElement>) {
    e.preventDefault();
    var text = e.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  }
}
