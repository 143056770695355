import ProjectsList from "./apiModels/projectsList";
import ProjectData from "./apiModels/projectData";
import { ProjectInvite, RetrievedProjectInvite } from "./apiModels/projectInvite";
import HelperMethods from "./helper";
import ErrorHelper from "types/errorHelper";
import HttpsFunctions from "./httpsFunctions";

class ProjectMethods {
  private static cachedProjectsList?: ProjectsList;

  /**
   * Check if an alias is available for project creation
   * @param {string} alias The alias to check
   * @throws {COMMON_ERRORS.ACTION_REQUIRES_LOGIN} Action Requires Login
   */
  public static async isAliasAvailable(alias?: string): Promise<boolean> {
    if (!alias || alias === "") return false;

    const snap = await HelperMethods.getSnap(`projects/${HelperMethods.getCurrentUserUid()}/${alias}`);

    if (snap.exists()) {
      return false;
    }

    return true;
  }

  /**
   * Deletes a project
   * @param {string} id Project ID
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error/Not Permitted
   */
  public static async deleteProject(id: string) {
    try {
      await HttpsFunctions.deleteProject(id);
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Get data about a specific project
   * @param id The project full id (`$user/$alias`)
   * @throws {COMMON_ERRORS.NOT_PERMITTED} Not Permitted
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error
   * @throws {COMMON_ERRORS.INEXISTS} Child doesn't exists
   * @throws {COMMON_ERRORS.ACTION_REQUIRES_LOGIN} Action Requires Login
   */
  public static async getProjectData(id: string): Promise<ProjectData> {
    const data = await HelperMethods.getSnapData(`projects/${id}`, true);

    return data;
  }

  /**
   * Get owned project list for current user
   * @throws {COMMON_ERRORS.NOT_PERMITTED} Not Permitted
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error
   * @throws {COMMON_ERRORS.INEXISTS} Child doesn't exists
   * @throws {COMMON_ERRORS.ACTION_REQUIRES_LOGIN} Action Requires Login
   */
  public static async getUserProjects(): Promise<ProjectsList> {
    const uid = HelperMethods.getCurrentUserUid();

    const data = await HelperMethods.getSnapData(`projects/${uid}`, false, false);

    if (data) {
      return data as ProjectsList;
    } else {
      return {};
    }
  }

  /**
   * Get all projects list for current user
   * @throws {COMMON_ERRORS.NOT_PERMITTED} Not Permitted
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error
   * @throws {COMMON_ERRORS.INEXISTS} Child doesn't exists
   * @throws {COMMON_ERRORS.ACTION_REQUIRES_LOGIN} Action Requires Login
   */
  public static async getUserSubscribedProjects(): Promise<ProjectsList> {
    const uid = HelperMethods.getCurrentUserUid();

    const data = await HelperMethods.getSnap(`userData/${uid}/projects`);

    const projectsList: ProjectsList = {};

    if (data.exists()) {
      const val = data.val();

      for (const owner in val) {
        for (const project in val[owner]) {
          projectsList[owner + "/" + project] = await HelperMethods.getSnapData(`projects/${owner}/${project}`);
        }
      }
    }

    this.cachedProjectsList = projectsList;

    return projectsList;
  }

  /**
   * Get the latest cached projects list
   */
  public static getCachedProjectsList(): ProjectsList | undefined {
    return this.cachedProjectsList;
  }

  /**
   * Creates a project
   * @param {string} alias The project alias
   * @param {string} name The Project name
   * @param {string[]} member The list of members to invite to the project
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error/Not Permitted
   */
  public static async createProject(alias: string, name: string, members: string[]) {
    try {
      await HttpsFunctions.createProject(alias, name, members);
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Retrieves an invite
   * @param {string} token The invite token
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error/Not Permitted
   * @throws {COMMON_ERRORS.PROJECT_INVITE.INVALID_TOKEN} Invalid token
   * @throws {COMMON_ERRORS.PROJECT_INVITE.TOKEN_EXPIRED} Token expired
   * @throws {COMMON_ERRORS.PROJECT_INVITE.PROJECT_DELETED} Project deleted
   */
  public static async retrieveInvite(token: string): Promise<RetrievedProjectInvite> {
    try {
      const res = await HttpsFunctions.retrieveInvite(token, "project");

      return res.data;
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Executes an invite
   * @param {string} token The invite token
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error/Not Permitted
   * @throws {COMMON_ERRORS.PROJECT_INVITE.INVALID_TOKEN} Invalid token
   * @throws {COMMON_ERRORS.PROJECT_INVITE.TOKEN_EXPIRED} Token expired
   * @throws {COMMON_ERRORS.PROJECT_INVITE.PROJECT_DELETED} Project deleted
   * @throws {COMMON_ERRORS.PROJECT_INVITE.TOKEN_REVOKED} Token revoked
   * @throws {COMMON_ERRORS.PROJECT_INVITE.EMAILS_DOESNT_MATCH} Emails doesn't match
   */
  public static async executeInvite(token: string) {
    try {
      await HttpsFunctions.executeProjectInvite(token);
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Get login token for an invite
   * @param {string} token The invite token
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error/Not Permitted
   * @throws {COMMON_ERRORS.PROJECT_INVITE.INVALID_TOKEN} Invalid token
   * @throws {COMMON_ERRORS.PROJECT_INVITE.TOKEN_EXPIRED} Token expired
   */
  public static async getInviteLoginToken(token: string): Promise<string> {
    try {
      const res = await HttpsFunctions.getInviteLoginToken(token, "project");

      return res.data.loginToken;
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Sets the project name
   * @param {string} id The target project id
   * @param {string} name The new name
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error/Not Permitted
   * @throws {COMMON_ERRORS.PROJECT_INVITE.INVALID_TOKEN} Invalid token
   * @throws {COMMON_ERRORS.PROJECT_INVITE.TOKEN_EXPIRED} Token expired
   */
  public static async setProjectName(id: string, name: string) {
    await HelperMethods.setChild(`projects/${id}/name`, name, true);
  }

  /**
   * Invites a list of users to the project
   * @param {string} projectId The target project id
   * @param {string[]} users The users to invite
   * @throws {COMMON_ERRORS.UNKNOWN} Unknown Error/Not Permitted
   */
  public static async inviteToProject(projectId: string, users: string[]) {
    try {
      await HttpsFunctions.inviteToProject(projectId, users);
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Remove a list of users from the project
   * @param {string} projectId The target project id
   * @param {string[]} users The users to remove
   * @throws {COMMON_ERRORS.UNKNOWN} Unknown Error/Not Permitted
   */
  public static async removeFromProject(projectId: string, users: string[]) {
    try {
      await HttpsFunctions.removeFromProject(projectId, users);
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Revokes a list of invites
   * @param {string[]} inviteKeys The invites to revoke
   * @throws {COMMON_ERRORS.UNKNOWN} Unknown Error/Not Permitted
   */
  public static async revokeInvites(inviteKeys: string[]) {
    try {
      await HttpsFunctions.revokeInvites(inviteKeys, "project");
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Leaves a project
   * @param {string} projectId The id of the project to leave
   * @throws {COMMON_ERRORS.UNKNOWN} Unknown Error/Not Permitted
   */
  public static async leaveProject(projectId: string) {
    try {
      await HttpsFunctions.leaveProject(projectId);
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Get active invites for a project
   * @param {string} projectId Project id
   * @throws {COMMON_ERRORS.UNKNOWN} Unknown Error/Not Permitted
   */
  public static async getActiveInvites(projectId: string): Promise<ProjectInvite[]> {
    try {
      const res = await HttpsFunctions.getActiveInvites(projectId, "project");

      return res.data;
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }
}

export default ProjectMethods;
