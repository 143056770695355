import React, { Component } from "react";
import ItemComment from "../itemComment/ItemComment";

interface CommentsSorterProps {
  sorting: "NEWEST_FIRST" | "OLDEST_FIRST";
}

export default class CommentsSorter extends Component<CommentsSorterProps> {
  constructor(props: CommentsSorterProps) {
    super(props);

    this.compare = this.compare.bind(this);
  }

  render() {
    return React.Children.toArray(this.props.children).sort(this.compare);
  }

  compare(a: React.ReactNode, b: React.ReactNode): number {
    const elA = a as ItemComment;
    const elB = b as ItemComment;

    if (elA && elB && this.props.sorting === "NEWEST_FIRST") {
      if (elA.props.itemData.creationDate > elB.props.itemData.creationDate) return -1;
      if (elA.props.itemData.creationDate < elB.props.itemData.creationDate) return 1;
    }

    if (elA && elB && this.props.sorting === "OLDEST_FIRST") {
      if (elA.props.itemData.creationDate > elB.props.itemData.creationDate) return 1;
      if (elA.props.itemData.creationDate < elB.props.itemData.creationDate) return -1;
    }

    return 0;
  }
}
