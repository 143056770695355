import { TextInputValidator } from "components/inputs/textInput/TextInput";
import ProjectMethods from "modules/api/projectMethods";

class ProjectAliasAvailableValidator implements TextInputValidator {
  private errorMessage: string;
  private timeout: number;

  private lastValidationResolver: ((v: true | string) => void) | null = null;

  public constructor(errorMessage: string = "Alias is not available.", timeout: number = 2000) {
    this.errorMessage = errorMessage;
    this.timeout = timeout;
  }

  public validate(value: string | undefined): Promise<true | string> {
    if (this.lastValidationResolver) this.lastValidationResolver(true);

    const resolver = async (resolve: (v: true | string) => void) => {
      this.lastValidationResolver = resolve;

      setTimeout(async () => {
        if (this.lastValidationResolver !== resolve) return;

        if (!value) resolve("Alias not provided.");

        const isAvailable = await ProjectMethods.isAliasAvailable(value);

        if (isAvailable) resolve(true);
        else resolve(this.errorMessage);

        if (this.lastValidationResolver === resolve) {
          this.lastValidationResolver = null;
        }
      }, this.timeout);
    };

    return new Promise(resolver);
  }
}

export const DEFAULT_PROJECT_ALIAS_AVAILABLE_VALIDATOR: ProjectAliasAvailableValidator = new ProjectAliasAvailableValidator();

export default ProjectAliasAvailableValidator;
