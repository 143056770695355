import React, { Component } from "react";

import styles from "./Dashboard.module.css";
import PageHeader from "components/pageHeader/PageHeader";
import * as Icons from "react-feather";
// import TextInput from "components/inputs/textInput/TextInput";
import { AssignList } from "modules/api/apiModels/assignList";
import UserProfileMethods from "modules/api/userProfileMethods";
import PunchlistArea from "./punchlistArea/PunchlistArea";
import Tooltip from "components/tooltip/Tooltip";
import RecentPunchlists from "./recentPunchlists/RecentPunchlists";

interface DashboardState {
  list?: AssignList;
  name?: string;
  ordering: "NEWEST_FIRST" | "OLDEST_FIRST";
}

export default class Dashboard extends Component<{}, DashboardState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      ordering: "NEWEST_FIRST"
    };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleOrderingChange = this.handleOrderingChange.bind(this);
  }

  render() {
    const list: Array<{ punchlist: string; items: string[] }> = [];

    if (this.state.list) {
      for (const key in this.state.list) {
        const element = this.state.list[key];

        const items: string[] = [];

        if (!element) {
          continue;
        }

        for (const key in element.items) {
          items.push(key);
        }

        list.push({
          punchlist: key,
          items: items
        });
      }
    }

    return (
      <div className={styles.Dashboard}>
        <PageHeader
          title={this.state.name ? "Welcome, " + this.state.name + "." : "Dashboard"}
          spinner={this.state.list ? undefined : "colorful"}
        ></PageHeader>
        <div className={styles.title}>
          <div className={styles.titleLeft}>Recent Punchlists</div>
        </div>

        <div className={styles.recentContainer}>
          <RecentPunchlists />
        </div>

        <div className={styles.title}>
          <div className={styles.titleLeft}>
            Assigned Items
            <Tooltip tooltip="These are the pendent items that were assigned to you in the projects which you're a member.">
              <div className={styles.helpBage}>
                <Icons.HelpCircle />
              </div>
            </Tooltip>
          </div>
          <select value={this.state.ordering} onChange={this.handleOrderingChange}>
            <option value="NEWEST_FIRST" label="Newest First"></option>
            <option value="OLDEST_FIRST" label="Oldest First"></option>
          </select>
          {/* <div className={styles.inputContainer}>
            <TextInput placeholder="Filter" value="" type="text" icon={Icons.Search} />
          </div> */}
        </div>
        <div className={styles.grid}>
          {list.map((v, i) => {
            return <PunchlistArea punchlistId={v.punchlist} items={v.items} key={i} ordering={this.state.ordering} />;
          })}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.fetchList();
    this.fetchName();
  }

  componentWillUnmount() {
    UserProfileMethods.muteAssignedItems("child_added", this.handleUpdate);
    UserProfileMethods.muteAssignedItems("child_changed", this.handleUpdate);
    UserProfileMethods.muteAssignedItems("child_removed", this.handleUpdate);
  }

  async fetchName() {
    const uid = await UserProfileMethods.getCurrentUserUid();

    if (!uid) {
      return;
    }

    const profile = await UserProfileMethods.getUserDetails(uid);

    if (profile.name) {
      this.setState({
        name: profile.name
      });
    }
  }

  async fetchList() {
    const list = await UserProfileMethods.getAssignedItems();

    this.handleUpdate(list);

    UserProfileMethods.listenAssignedItems("child_added", this.handleUpdate);
    UserProfileMethods.listenAssignedItems("child_changed", this.handleUpdate);
    UserProfileMethods.listenAssignedItems("child_removed", this.handleUpdate);
  }

  handleUpdate(list: AssignList) {
    this.setState({
      list: list
    });
  }

  handleOrderingChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      ordering: event.target.value as "NEWEST_FIRST" | "OLDEST_FIRST"
    });
  }
}
