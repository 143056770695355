import React, { Component } from "react";
import styles from "./SkeletonPunchlistItem.module.css";
import ContentLoader from "react-content-loader";

export default class SkeletonPunchlistItem extends Component {
  render() {
    return (
      <div className={styles.loadingItem}>
        <div className={styles.skeletonContainer}>
          <ContentLoader height={44} width={400} speed={1} primaryColor="#f3f3f3" secondaryColor="#e8e6e6">
            <circle cx="10" cy="10" r="10" />
            <rect x="60" y="10" rx="0" ry="0" width="0" height="0" />
            <rect x="32" y="0" rx="0" ry="0" width="361" height="20" />
            <rect x="32" y="27" rx="0" ry="0" width="141" height="14" />
            <rect x="181" y="27" rx="0" ry="0" width="101" height="14" />
          </ContentLoader>
        </div>
      </div>
    );
  }
}
