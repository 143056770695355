import React, { Component } from "react";

import styles from "./Projects.module.css";
import PageHeader from "components/pageHeader/PageHeader";
import { Link } from "react-router-dom";
import ProjectsList from "modules/api/apiModels/projectsList";
import ProjectMethods from "modules/api/projectMethods";
import ProjectsListComponent from "./projectsList/ProjectsList";
import TextInput from "components/inputs/textInput/TextInput";

import * as Icons from "react-feather";

interface ProjectsState {
  list?: ProjectsList;
  updatingList: boolean;
  message?: string;
  messageType?: "success" | "error";

  filter: string;
}

export default class Projects extends Component<{}, ProjectsState> {
  constructor(props: {}) {
    super(props);

    const query = new URLSearchParams(window.location.search);

    const message = query.get("message");

    this.state = {
      updatingList: true,
      messageType: message === "project_success" ? "success" : undefined,
      message: message === "project_success" ? "Project created sucessfully." : undefined,
      filter: ""
    };

    this.setFilter = this.setFilter.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
  }

  render() {
    return (
      <div className={styles.Projects}>
        {this.state.message && (
          <div className={styles.messageContainer}>
            {this.state.messageType === "success" && <div className={styles.messageSuccess}>{this.state.message}</div>}
          </div>
        )}
        <PageHeader title="Projects" spinner={this.state.updatingList ? "colorful" : undefined}>
          <TextInput
            placeholder="Filter"
            value={this.state.filter}
            type="text"
            icon={Icons.Search}
            onChange={this.setFilter}
          />
          <Link to="/app/projects/create" className={styles.addBtn}>
            Create Project
          </Link>
        </PageHeader>
        <div className={styles.projectsList}>
          {this.state.list && (
            <ProjectsListComponent
              filter={this.state.filter}
              items={this.state.list}
              onResetFilter={this.resetFilter}
            ></ProjectsListComponent>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.updateProjectList();
  }

  async updateProjectList() {
    this.setState({
      list: ProjectMethods.getCachedProjectsList()
    });

    const list = await ProjectMethods.getUserSubscribedProjects();

    this.setState({
      list: list,
      updatingList: false
    });
  }

  setFilter(v: string) {
    this.setState({
      filter: v
    });
  }

  resetFilter() {
    this.setState({
      filter: ""
    });
  }
}
