import React, { Component } from "react";
import PunchlistItem from "components/punchlistItem/PunchlistItem";

interface PunchlistSorterProps {
  sessionTime: number;
}

export default class PunchlistSorter extends Component<PunchlistSorterProps> {
  private orderingCache?: string[];

  constructor(props: PunchlistSorterProps) {
    super(props);

    this.compareCached = this.compareCached.bind(this);
  }

  render() {
    const cache = React.Children.toArray(this.props.children).sort(this.compareCached);

    if (!this.orderingCache) {
      this.orderingCache = [];

      for (let i = 0; i < cache.length; i++) {
        const element = cache[i] as PunchlistItem;

        this.orderingCache[i] = element.props.itemId;
      }
    }

    return cache;
  }

  compareCached(a: React.ReactNode, b: React.ReactNode): number {
    if (!this.orderingCache) {
      return this.compare(a, b);
    }

    const elA = a as PunchlistItem;
    const elB = b as PunchlistItem;

    let result = 0;

    const indexA = this.orderingCache.indexOf(elA.props.itemId);
    const indexB = this.orderingCache.indexOf(elB.props.itemId);

    if (indexA < indexB) result = -1;
    else if (indexA > indexB) result = 1;

    if (indexA === -1 && indexB > -1) result = 1;
    if (indexB === -1 && indexA > -1) result = -1;

    return result;
  }

  compare(a: React.ReactNode, b: React.ReactNode): number {
    const elA = a as PunchlistItem;
    const elB = b as PunchlistItem;

    if (
      elA.props.itemData.creationDate > this.props.sessionTime &&
      elB.props.itemData.creationDate <= this.props.sessionTime
    )
      return 1;

    if (
      elB.props.itemData.creationDate > this.props.sessionTime &&
      elA.props.itemData.creationDate <= this.props.sessionTime
    )
      return -1;

    if (
      elB.props.itemData.creationDate > this.props.sessionTime &&
      elA.props.itemData.creationDate > this.props.sessionTime
    )
      return this.poscompare(elA, elB);

    if (
      elB.props.itemData.creationDate <= this.props.sessionTime &&
      elA.props.itemData.creationDate <= this.props.sessionTime
    )
      return this.poscompare(elA, elB);

    return 0;
  }

  poscompare(a: React.ReactNode, b: React.ReactNode): number {
    const elA = a as PunchlistItem;
    const elB = b as PunchlistItem;

    let isAPunched = this.isItemReallyPunched(elA);
    let isBPunched = this.isItemReallyPunched(elB);

    if (isAPunched && !isBPunched) return 1;
    if (!isAPunched && isBPunched) return -1;

    if (elA.props.itemData.creationDate < elB.props.itemData.creationDate) return 1;
    if (elA.props.itemData.creationDate > elB.props.itemData.creationDate) return -1;

    return 0;
  }

  isItemReallyPunched(el: PunchlistItem) {
    let isPunched = el.props.itemData.punched;

    const punchistory = el.props.itemData.punchistory;

    if (punchistory) {
      for (const stateKey in punchistory) {
        const state = punchistory[stateKey];

        if (state.time > this.props.sessionTime) {
          isPunched = !state.state;
          break;
        }
      }
    }

    return isPunched;
  }
}
