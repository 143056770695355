import React, { Component } from "react";

import styles from "./AddingItem.module.css";
import Tick from "components/tick/Tick";

import { UserProfileSettings } from "modules/api/apiModels/UserProfileSettings";

interface AddingItemProps {
  onBlur?: (content: string, punched: boolean) => any;

  className?: string;
  placeholderClassName?: string;
}

interface AddingItemState {
  loadingUserDetails?: boolean;
  username?: string;
  details?: UserProfileSettings;

  tickingInProgress: boolean;

  ticked: boolean;
  title: string;

  focused: boolean;
}

export default class AddingItem extends Component<AddingItemProps, AddingItemState> {
  input?: HTMLDivElement;

  constructor(props: AddingItemProps) {
    super(props);

    this.state = {
      loadingUserDetails: true,
      tickingInProgress: false,

      ticked: false,
      title: "",

      focused: false
    };

    this.onTick = this.onTick.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleUnfocus = this.handleUnfocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
  }

  render() {
    return (
      <div className={styles.AddingItem + (this.props.className ? " " + this.props.className : "")}>
        <div className={styles.tickContainer}>
          <Tick ticked={this.state.ticked} onClick={this.onTick} taskName={this.state.title || "to be added"} />
        </div>
        <div className={styles.detailsContainer}>
          {!this.state.focused && (
            <div
              className={
                styles.placeholder + (this.props.placeholderClassName ? " " + this.props.placeholderClassName : "")
              }
              tabIndex={0}
              onFocus={this.handleFocus}
            >
              Add item
            </div>
          )}
          {this.state.focused && (
            <div
              className={styles.title + (this.state.ticked ? " " + styles.punched : "")}
              contentEditable
              onBlur={this.handleUnfocus}
              ref={div => (this.input = div || undefined)}
              onInput={this.handleChange}
              onKeyDown={this.handleKeydown}
              onPaste={this.pasteInterceptor}
            />
          )}
        </div>
      </div>
    );
  }

  onTick() {
    this.setState({
      ticked: !this.state.ticked
    });
  }

  handleFocus() {
    this.setState(
      {
        focused: true
      },
      () => {
        if (this.input) this.input.focus();
      }
    );
  }

  handleUnfocus() {
    if (!this.input || !this.props.onBlur) return;

    this.props.onBlur(this.input.innerText, this.state.ticked);

    this.setState({
      focused: false,
      ticked: false
    });
  }

  handleChange() {
    if (!this.input) return;

    // do something, like validate?
  }

  handleKeydown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();

      if (this.input) this.input.blur();
    }
  }

  pasteInterceptor(e: React.ClipboardEvent<HTMLDivElement>) {
    e.preventDefault();
    var text = e.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  }
}
