import * as firebase from "firebase/app";
import { AuthCommonResponse, ValidationError } from "types/authTypes";
import { COMMON_ERRORS } from "types/commonErrors";
import { UserProfileSettings } from "./apiModels/UserProfileSettings";
import { AssignList } from "./apiModels/assignList";
import HelperMethods from "./helper";
import HttpsFunctions from "./httpsFunctions";
import ErrorHelper from "types/errorHelper";

export type AssignedItemsListenerCallback = (itemData: AssignList) => any;

interface AssignedItemsListener {
  method: "child_added" | "child_removed" | "child_changed";
  callback: AssignedItemsListenerCallback;

  assignedCallback?: (snap: firebase.database.DataSnapshot) => any;
}

/**
 * Common methods for user profile changes and requests.
 */
class UserProfileMethods {
  private static assignedItemsListeners: AssignedItemsListener[] = [];

  /**
   * Sets the current user display name
   * @param name User name
   */
  public static async setDisplayName(name: string) {
    const auth = firebase.auth();

    await HelperMethods.updateChild(`users/${HelperMethods.getCurrentUserUid()}/name`, name, true);

    await auth.currentUser!.updateProfile({
      displayName: name
    });
  }

  /**
   * Updates current logged user profile
   * @param company The user company
   */
  public static async updateProfileSettings(company?: string, name?: string, picId?: string) {
    const auth = firebase.auth();

    const newObj: { [key: string]: string } = {};

    if (company) newObj["company"] = company;
    if (name) newObj["name"] = name;
    if (picId) newObj["picId"] = picId;

    await HelperMethods.setChild(`users/${HelperMethods.getCurrentUserUid()}`, newObj, true);

    await auth.currentUser!.updateProfile({
      displayName: name
    });
  }

  public static async setCurrentUserUsername(username: string) {
    try {
      await HttpsFunctions.setUsername(username);
    } catch (error) {
      throw ErrorHelper.getCommonErrorByCode(error.message);
    }
  }

  /**
   * Check if an username is available for register
   * @param username The usernmae to check
   */
  public static async isUsernameAvailable(username?: string): Promise<boolean> {
    if (!username || username === "") return false;

    const snapshot = await firebase
      .database()
      .ref("usernames")
      .child(username.toLowerCase())
      .once("value");

    if (snapshot.exists()) {
      return false;
    }

    return true;
  }

  /**
   * Gets the username of the logged user
   */
  public static async getCurrentUserUsername(): Promise<string | null> {
    const auth = firebase.auth();

    if (!auth.currentUser) {
      return null;
    }

    return this.getUserUsername(auth.currentUser.uid);
  }

  public static async getUserPicture(userId: string): Promise<string | null> {
    const profile = await UserProfileMethods.getUserDetails(userId);

    return UserProfileMethods.getUserPictureFromProfilePic(profile.pic);
  }

  public static async getUserPictureFromProfilePic(pic?: string): Promise<string | null> {
    if (!pic) {
      return null;
    } else if (pic === "uploaded") {
      return "UPLOADED_URL_HERE";
    }

    return pic;
  }

  /**
   * Gets the uid of the logged user
   */
  public static async getCurrentUserUid(): Promise<string | null> {
    const auth = firebase.auth();

    if (!auth.currentUser) {
      return null;
    }

    return auth.currentUser.uid;
  }

  private static UidKeyToUsernameCache: {
    [uid: string]: string;
  } = {};

  private static UsernameKeyToUidCache: {
    [username: string]: string;
  } = {};

  /**
   * Get the username of an registred user
   * @param uid The user id
   */
  public static async getUserUsername(uid: string): Promise<string | null> {
    if (this.UidKeyToUsernameCache[uid]) return this.UidKeyToUsernameCache[uid];

    const database = firebase.database();

    const matches = await database
      .ref("usernames")
      .orderByChild("owner")
      .equalTo(uid)
      .once("value");

    if (matches.exists()) {
      let username: string | null = null;

      matches.forEach(u => {
        username = u.key;
      });

      if (username) {
        this.UidKeyToUsernameCache[uid] = username;
        this.UsernameKeyToUidCache[username] = uid;
      }

      return username;
    }

    return null;
  }

  /**
   * Gets the user id of an registred username
   * @param username The target username
   */
  public static async getUserId(username: string): Promise<string | null> {
    if (this.UsernameKeyToUidCache[username]) return this.UsernameKeyToUidCache[username];

    const database = firebase.database();

    const match = await database.ref(`usernames/${username}`).once("value");

    if (match.exists()) {
      if (username) {
        this.UidKeyToUsernameCache[match.val().owner] = username;
        this.UsernameKeyToUidCache[username] = match.val().owner;
      }

      return match.val().owner;
    }

    return null;
  }

  /**
   * Checks if the current logged user profile is complete
   */
  public static async isUserProfileComplete(): Promise<AuthCommonResponse> {
    const auth = firebase.auth();
    const database = firebase.database();

    if (!auth.currentUser) {
      return {
        status: false,
        errors: [COMMON_ERRORS.ACTION_REQUIRES_LOGIN]
      };
    }

    try {
      const profileErrors: ValidationError[] = [];

      const profileSnap = await database.ref(`users/${auth.currentUser.uid}`).once("value");
      const profile: UserProfileSettings | undefined | null = profileSnap.val();

      const username = await this.getCurrentUserUsername();

      if (!auth.currentUser.displayName || !profile) profileErrors.push(COMMON_ERRORS.USER_PROFILE.MISSING_NAME);

      if (!username) profileErrors.push(COMMON_ERRORS.USER_PROFILE.MISSING_USERNAME);

      if (!profile) {
        profileErrors.push(COMMON_ERRORS.USER_PROFILE.PROFILE_INEXISTS);
        return {
          status: false,
          errors: profileErrors
        };
      }

      // add errors
      if (!profile.company) profileErrors.push(COMMON_ERRORS.USER_PROFILE.MISSING_COMPANY);
      if (!profile.name) profileErrors.push(COMMON_ERRORS.USER_PROFILE.MISSING_NAME);

      return { status: profileErrors.length === 0, errors: profileErrors };
    } catch (r) {
      return {
        status: false,
        errors: [r]
      };
    }
  }

  private static detailsCache: {
    [uid: string]: UserProfileSettings;
  } = {};

  public static async getUserDetails(uid: string): Promise<UserProfileSettings> {
    if (this.detailsCache[uid]) return this.detailsCache[uid];

    const database = firebase.database();

    const snap = await database.ref(`users/${uid}`).once("value");
    const profile: UserProfileSettings = snap.val();

    this.detailsCache[uid] = profile;

    return profile;
  }

  private static lastAssignListFetch: AssignList;

  public static async getAssignedItems(): Promise<AssignList> {
    const uid = await this.getCurrentUserUid();

    const database = firebase.database();

    const snap = await database.ref(`userData/${uid}/assignedItems`).once("value");
    const list: AssignList = snap.val();

    this.lastAssignListFetch = list;

    return list;
  }

  public static async listenAssignedItems(
    event: "child_added" | "child_changed" | "child_removed",
    cb: AssignedItemsListenerCallback
  ) {
    const uid = await this.getCurrentUserUid();

    const db = firebase.database();
    const auth = firebase.auth();

    if (!auth.currentUser) {
      return {
        status: false,
        errors: [COMMON_ERRORS.ACTION_REQUIRES_LOGIN]
      };
    }

    let ref = db.ref(`userData/${uid}/assignedItems`);

    const listener: AssignedItemsListener = {
      method: event,
      callback: cb
    };

    listener.assignedCallback = async snap => {
      const val = snap.val();
      const key = snap.key;

      if (key === null || !this.lastAssignListFetch) {
        listener.callback(await this.getAssignedItems());
        return;
      }

      this.lastAssignListFetch[key] = event !== "child_removed" ? val : undefined;

      listener.callback(this.lastAssignListFetch);
    };

    this.assignedItemsListeners.push(listener);

    ref.on(event, listener.assignedCallback);
  }

  public static async muteAssignedItems(
    event: "child_added" | "child_changed" | "child_removed",
    cb: AssignedItemsListenerCallback
  ) {
    const uid = await this.getCurrentUserUid();

    const db = firebase.database();
    const auth = firebase.auth();

    if (!auth.currentUser) {
      return {
        status: false,
        errors: [COMMON_ERRORS.ACTION_REQUIRES_LOGIN]
      };
    }

    let ref = db.ref(`userData/${uid}/assignedItems`);

    const listener = this.assignedItemsListeners.find(x => x.callback === cb);

    if (!listener) return;

    ref.off(event, listener.assignedCallback);

    const index = this.assignedItemsListeners.findIndex(x => x === listener);
    this.assignedItemsListeners.splice(index, 1);
  }
}

export default UserProfileMethods;
