import React, { Component } from "react";

import styles from "./FormWall.module.css";

import { Link } from "react-router-dom";

import logo from "assets/logo.svg";

interface AuthFormLink {
  caption?: string;
  route: string;
  label: string;
}

interface FormWallProps {
  actionLink?: AuthFormLink;
  hideLogo?: boolean;
}

class FormWall extends Component<FormWallProps> {
  public render(): JSX.Element {
    return (
      <div className={styles.FormWall}>
        <div className={styles.content}>
          {!this.props.hideLogo && (
            <div className={styles.logoContainer}>
              <Link to="/">
                <img className={styles.logo} src={logo} alt="Punchlist" />
              </Link>
            </div>
          )}
          <div className={styles.form}>{this.props.children}</div>

          {this.props.actionLink && (
            <div className={styles.formActionArea}>
              {this.props.actionLink.caption}
              {this.props.actionLink.caption && <>&nbsp;</>}
              <Link to={this.props.actionLink.route}>{this.props.actionLink.label}</Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FormWall;
