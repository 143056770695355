import React, { Component } from "react";
import "./App.css";
import AppStateProvider from "providers/AppStateProvider";

import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "views/login/Login";
import Register from "views/register/Register";
import ResetPassword from "views/password/reset/ResetPassword";
import Home from "views/home/Home";
import UserMgmt from "views/userMgmt/UserMgmt";
import CompleteCreation from "views/authorizedViews/completeCreation/CompleteCreation";
import PrivateRoute from "components/privateRoute/PrivateRoute";
import AppBootstrap from "views/authorizedViews/app/AppBootstrap";
import JoinAuth from "views/joinAuth/JoinAuth";

export default class App extends Component {
  render() {
    return (
      <AppStateProvider>
        <div className="App">
          <Router>
            <main>
              <Route exact path="/" component={Home} />
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/password/reset" component={ResetPassword} />
              <Route path="/usermgmt" component={UserMgmt} />
              <Route path="/join" component={JoinAuth} />

              <PrivateRoute path="/complete" component={CompleteCreation} />

              <PrivateRoute path="/app" component={AppBootstrap} needsCompletion />
            </main>
          </Router>
        </div>
      </AppStateProvider>
    );
  }
}
