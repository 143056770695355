import React, { Component } from "react";

import styles from "./Home.module.css";

import { AuthContext } from "contexts/AuthContext";

import { Link } from "react-router-dom";
import * as firebase from "firebase/app";

import logo from "assets/logo.svg";
import Spinner from "components/spinner/Spinner";

class Home extends Component {
  public render(): JSX.Element {
    return (
      <div className={styles.Home}>
        <div className={`${styles.flexCenter} ${styles.positionRef} ${styles.fullHeight}`}>
          <div className={`${styles.topRight} ${styles.links}`}>
            <AuthContext.Consumer>
              {(auth): JSX.Element => (
                <>
                  {auth.authState === "waiting" ? (
                    <div className={styles.spinnerContainer}>
                      <Spinner colorful size={16} />
                    </div>
                  ) : auth.isAuthenticated ? (
                    <>
                      <Link to="/app">App</Link>
                      <button
                        onClick={() => {
                          firebase.auth().signOut();
                        }}
                      >
                        Logout
                      </button>
                    </>
                  ) : (
                    <>
                      <Link to="/login">Login</Link>
                      <Link to="/register">Register</Link>
                    </>
                  )}
                </>
              )}
            </AuthContext.Consumer>
          </div>

          <div className={styles.content}>
            <div className={`${styles.title} ${styles.mbmd}`}>
              <img className={styles.logo} src={logo} alt="PunchList" />
            </div>
            <div className={styles.links} />
          </div>
          <div className={styles.environmentDesc}>
            Git: {process.env.REACT_APP_BRANCH_NAME} - Environment: {process.env.REACT_APP_ENVIRONMENT}
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
