import * as firebase from "firebase/app";

class HttpsFunctions {
  /**
   * Deletes a project
   * @param {string} id The id of the project to delete
   */
  public static async deleteProject(id: string): Promise<firebase.functions.HttpsCallableResult> {
    const func = firebase.functions().httpsCallable("deleteProject");

    return await func({
      id: id
    });
  }

  /**
   * Deletes a project
   * @param {string} id The id of the project to delete
   */
  public static async deleteTeam(id: string): Promise<firebase.functions.HttpsCallableResult> {
    const func = firebase.functions().httpsCallable("deleteTeam");

    return await func({
      id: id
    });
  }

  /**
   * Creates a Project
   * @param {string} alias The desired alias
   * @param {string} name The desired name
   * @param {string[]} members The member to invite
   */
  public static async createProject(
    alias: string,
    name: string,
    members: string[]
  ): Promise<firebase.functions.HttpsCallableResult> {
    const createProject = firebase.functions().httpsCallable("createProject");

    return await createProject({
      alias: alias,
      name: name,
      members: members
    });
  }

  /**
   * Creates a Team
   * @param {string} alias The desired alias
   * @param {string} name The desired name
   * @param {string[]} members The member to invite
   */
  public static async createTeam(
    alias: string,
    name: string,
    members: string[]
  ): Promise<firebase.functions.HttpsCallableResult> {
    const createProject = firebase.functions().httpsCallable("createTeam");

    return await createProject({
      alias: alias,
      name: name,
      members: members
    });
  }

  /**
   * Retrieve invite data
   * @param {string} token The invite token
   */
  public static async retrieveInvite(
    token: string,
    inviteType: "team" | "project"
  ): Promise<firebase.functions.HttpsCallableResult> {
    const retrieveInvite = firebase.functions().httpsCallable("retrieveInvite");

    return await retrieveInvite({
      token: token,
      inviteType: inviteType
    });
  }

  /**
   * Executes an inivite to a project
   */
  public static async executeProjectInvite(token: string): Promise<firebase.functions.HttpsCallableResult> {
    const executeInvite = firebase.functions().httpsCallable("executeProjectInvite");

    return await executeInvite({
      token: token
    });
  }

  /**
   * Executes an inivite to a project
   */
  public static async executeTeamInvite(token: string): Promise<firebase.functions.HttpsCallableResult> {
    const executeInvite = firebase.functions().httpsCallable("executeTeamInvite");

    return await executeInvite({
      token: token
    });
  }
  /**
   * Gets the token to login using an invite token
   */
  public static async getInviteLoginToken(
    token: string,
    inviteType: "team" | "project"
  ): Promise<firebase.functions.HttpsCallableResult> {
    const getInviteLoginToken = firebase.functions().httpsCallable("getInviteLoginToken");

    return await getInviteLoginToken({
      token: token,
      inviteType: inviteType
    });
  }

  public static async inviteToProject(
    projectId: string,
    users: string[]
  ): Promise<firebase.functions.HttpsCallableResult> {
    const inviteToProject = firebase.functions().httpsCallable("inviteToProject");

    return await inviteToProject({
      projectId: projectId,
      users: users
    });
  }

  public static async inviteToTeam(teamId: string, users: string[]): Promise<firebase.functions.HttpsCallableResult> {
    const inviteToProject = firebase.functions().httpsCallable("inviteToTeam");

    return await inviteToProject({
      teamId: teamId,
      users: users
    });
  }

  public static async removeFromProject(
    projectId: string,
    users: string[]
  ): Promise<firebase.functions.HttpsCallableResult> {
    const removeFromProject = firebase.functions().httpsCallable("removeFromProject");

    return await removeFromProject({
      projectId: projectId,
      users: users
    });
  }

  public static async removeFromTeam(
    projectId: string,
    users: string[]
  ): Promise<firebase.functions.HttpsCallableResult> {
    const removeFromProject = firebase.functions().httpsCallable("removeFromTeam");

    return await removeFromProject({
      teamId: projectId,
      users: users
    });
  }

  public static async revokeInvites(
    inviteKeys: string[],
    inviteType: "team" | "project"
  ): Promise<firebase.functions.HttpsCallableResult> {
    const revokeInvites = firebase.functions().httpsCallable("revokeInvite");

    return await revokeInvites({
      inviteKeys: inviteKeys,
      inviteType: inviteType
    });
  }

  public static async leaveProject(projectId: string): Promise<firebase.functions.HttpsCallableResult> {
    const leaveProject = firebase.functions().httpsCallable("leaveProject");

    return await leaveProject({
      projectId: projectId
    });
  }

  public static async isTeamAliasAvailable(alias: string): Promise<firebase.functions.HttpsCallableResult> {
    const isTeamAliasAvailable = firebase.functions().httpsCallable("isTeamAliasAvailable");

    return await isTeamAliasAvailable({
      alias: alias
    });
  }

  public static async leaveTeam(projectId: string): Promise<firebase.functions.HttpsCallableResult> {
    const leaveProject = firebase.functions().httpsCallable("leaveTeam");

    return await leaveProject({
      teamId: projectId
    });
  }

  public static async getActiveInvites(
    resourceId: string,
    resourceType: "project" | "team"
  ): Promise<firebase.functions.HttpsCallableResult> {
    const retrieveActiveInvites = firebase.functions().httpsCallable("retrieveActiveInvites");

    return await retrieveActiveInvites({
      resourceId: resourceId,
      resourceType: resourceType
    });
  }

  public static async setUsername(username: string) {
    const setUsername = firebase.functions().httpsCallable("setUsername");

    return await setUsername({
      username: username
    });
  }
}

export default HttpsFunctions;
