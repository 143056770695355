import React, { Component } from "react";

import styles from "./ItemActions.module.css";

import * as Icons from "react-feather";
import FloatingMenu, { FloatingMenuItem } from "components/floatingMenu/FloatingMenu";
import Tooltip from "components/tooltip/Tooltip";
import SuperButton from "components/superButton/SuperButton";

interface ItemActionsProps {
  onEditClick?: () => any;
  onCommentsClick?: () => any;

  onDeleteClick: () => any;
  onUncheckClick: () => any;

  commentQuantity: number;
  showUncheck?: boolean;
}

interface ItemActionsState {
  showMenu: boolean;
  showDeleteConfirm: boolean;
}

export default class ItemActions extends Component<ItemActionsProps, ItemActionsState> {
  constructor(props: ItemActionsProps) {
    super(props);

    this.state = { showMenu: false, showDeleteConfirm: false };

    this.showOptionsMenu = this.showOptionsMenu.bind(this);
    this.toggleOptionsMenu = this.toggleOptionsMenu.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleUncheckClick = this.handleUncheckClick.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
  }

  render() {
    const menuItems: FloatingMenuItem[] = [
      {
        label: "Delete",
        onClick: this.handleDeleteClick,
        icon: Icons.Trash,
        flyout: {
          open: this.state.showDeleteConfirm,
          content: (
            <div className={styles.deleteConfirm}>
              <div className={styles.deleteConfirmText}>Confirm deletion?</div>
              <div className={styles.deleteConfirmButtonArea}>
                <SuperButton
                  label="Delete"
                  compact
                  onClick={this.handleDeleteConfirm}
                  outline
                  customColor="#ff0000"
                ></SuperButton>
                <div className={styles.deleteConfirmButtonSpace} />
                <SuperButton label="Cancel" compact onClick={this.handleDeleteCancel}></SuperButton>
              </div>
            </div>
          )
        }
      },

      {
        label: "Edit",
        onClick: this.handleEditClick,
        icon: Icons.Edit
      }
    ];

    if (this.props.showUncheck) {
      menuItems.push({
        label: "Uncheck",
        onClick: this.handleUncheckClick,
        icon: Icons.XCircle
      });
    }

    return (
      <div className={styles.ItemActions}>
        <FloatingMenu
          fixOn="right"
          show={this.state.showMenu}
          items={menuItems}
          onClickOutside={this.toggleOptionsMenu}
        >
          <Tooltip tooltip="More Options">
            <button className={styles.action}>
              <Icons.MoreVertical size={16} strokeWidth={1} onClick={this.showOptionsMenu} />
            </button>
          </Tooltip>
        </FloatingMenu>
      </div>
    );
  }

  toggleOptionsMenu() {
    this.setState({
      showMenu: !this.state.showMenu
    });
  }

  showOptionsMenu() {
    if (!this.state.showMenu) {
      this.setState({
        showMenu: true
      });
    }
  }

  handleDeleteClick() {
    this.setState({
      showDeleteConfirm: true
    });
  }

  handleDeleteConfirm() {
    this.setState({
      showDeleteConfirm: false,
      showMenu: false
    });
    this.props.onDeleteClick();
  }

  handleDeleteCancel() {
    this.setState({
      showDeleteConfirm: false
    });
  }

  handleUncheckClick() {
    this.showOptionsMenu();
    this.props.onUncheckClick();
  }

  handleEditClick() {
    this.toggleOptionsMenu();

    if (!this.props.onEditClick) {
      return;
    }

    this.props.onEditClick();
  }
}
