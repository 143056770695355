import React, { Component } from "react";

import FormWall from "components/formWall/FormWall";
import { AuthContext } from "contexts/AuthContext";
import AuthForm, { AuthFormField } from "components/authForm/AuthForm";
import { EMAIL_VALIDATOR } from "modules/customInputDataValidators/textValidators/regexValidator";
import { DEFAULT_REQUIRED_VALIDATOR } from "modules/customInputDataValidators/textValidators/minLengthValidator";
import SocialLogins from "components/socialLogins/SocialLogins";
import { withRouter, RouteComponentProps, Redirect } from "react-router";

import * as Icons from "react-feather";

const LOGIN_FIELDS: AuthFormField[] = [
  {
    name: "email",
    placeholder: "example@example.com",
    label: "E-mail",
    type: "email",
    validators: [DEFAULT_REQUIRED_VALIDATOR, EMAIL_VALIDATOR],
    icon: Icons.Mail
  },
  {
    name: "password",
    placeholder: "••••••",
    label: "Password",
    type: "password",
    validators: [DEFAULT_REQUIRED_VALIDATOR],
    icon: Icons.Lock
  }
];

interface LoginState {
  hasError: boolean;
  error?: string;

  redirectBack: boolean;
}

class Login extends Component<RouteComponentProps<any>, LoginState> {
  public static contextType = AuthContext;
  public context!: React.ContextType<typeof AuthContext>;

  public constructor(props: RouteComponentProps<any>) {
    super(props);

    this.state = {
      hasError: false,
      redirectBack: false
    };

    this.handleContinue = this.handleContinue.bind(this);
    this.handleGithubContinue = this.handleGithubContinue.bind(this);
    this.handleGoogleContinue = this.handleGoogleContinue.bind(this);
  }

  public render(): JSX.Element {
    let redirectTo = "/app";

    const query = new URLSearchParams(window.location.search);
    const redirectBack = query.get("next");

    if (redirectBack) redirectTo = redirectBack;

    if (this.state.redirectBack || this.context.isAuthenticated) return <Redirect to={{ pathname: redirectTo }} />;

    return (
      <div className="Login">
        <FormWall
          actionLink={{
            caption: "Don't have an account?",
            label: "Create one",
            route: "/register/?next=" + redirectTo
          }}
        >
          <div className="r-login-form">
            <AuthForm
              fields={LOGIN_FIELDS}
              title="Login"
              secondaryLink={{
                label: "Forgot Password",
                route: "/password/reset"
              }}
              onContinue={this.handleContinue}
              processing={this.context.authState !== "guest"}
              masterError={this.state.error}
              invertChildren
            >
              <SocialLogins
                divisorTitle="OR"
                invertOr
                onGithubClick={this.handleGithubContinue}
                onGoogleClick={this.handleGoogleContinue}
              />
            </AuthForm>
          </div>
        </FormWall>
      </div>
    );
  }

  private async handleContinue(values: { [key: string]: string }): Promise<void> {
    const authContext = this.context;

    const email = values["email"];
    const password = values["password"];

    const response = await authContext.login(email, password);

    this.setState({
      hasError: !response.status,
      error: !response.status ? response.errors[0].message : undefined
    });

    if (response.status) {
      this.setState({
        redirectBack: true
      });
    }
  }

  private async handleGithubContinue(): Promise<void> {
    const authContext = this.context;

    const response = await authContext.loginGithub();

    this.setState({
      hasError: !response.status,
      error: !response.status ? response.errors[0].message : undefined
    });

    if (response.status) {
      this.setState({
        redirectBack: true
      });
    }
  }

  private async handleGoogleContinue(): Promise<void> {
    const authContext = this.context;

    const response = await authContext.loginGoogle();

    this.setState({
      hasError: !response.status,
      error: !response.status ? response.errors[0].message : undefined
    });

    if (response.status) {
      this.setState({
        redirectBack: true
      });
    }
  }
}

export default withRouter(Login);
