import React, { Component } from "react";

import styles from "./FloatingMenu.module.css";

import * as Icons from "react-feather";
import Flyout from "components/flyout/Flyout";

export interface FloatingMenuItem {
  label: string;
  onClick?: () => void;
  icon?: React.ComponentType<Icons.Props>;
  flyout?: {
    content: React.ReactNode;
    open?: boolean;
  };
}

interface FloatingMenuProps {
  className?: string;
  fixOn?: "left" | "right";
  show?: boolean;
  onClickOutside?: () => void;
  items: FloatingMenuItem[];
}

export default class FloatingMenu extends Component<FloatingMenuProps> {
  private menu: HTMLDivElement | null = null;

  constructor(props: FloatingMenuProps) {
    super(props);

    this.menuCloser = this.menuCloser.bind(this);
  }

  render() {
    return (
      <div
        className={
          styles.FloatingMenu +
          " " +
          (this.props.className || "") +
          (this.props.fixOn === "right" ? " " + styles.fixOnRight : " " + styles.fixOnLeft) +
          " " +
          (this.props.show ? styles.open : styles.closed)
        }
      >
        {this.props.children}
        <div className={styles.menu} ref={d => (this.menu = d)}>
          <ul>
            {this.props.items.map((v, k) => {
              const item = (
                <li key={!v.flyout ? k : undefined} onClick={v.onClick}>
                  <div className={styles.iconContainer}>{v.icon && <v.icon strokeWidth={1} size={16} />}</div>
                  <div>{v.label}</div>
                </li>
              );

              if (!v.flyout) return item;

              return (
                <Flyout key={k} open={v.flyout.open} content={v.flyout.content} customClass={styles.itemFlyout}>
                  {item}
                </Flyout>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.removeMenuEvents();
  }

  componentDidUpdate(prevProps: FloatingMenuProps) {
    if (prevProps.show !== this.props.show && this.props.show) {
      document.addEventListener("click", this.menuCloser);
    }
  }

  removeMenuEvents() {
    document.removeEventListener("click", this.menuCloser);
  }

  menuCloser(event: MouseEvent) {
    if (!this.menu || !event.target) {
      return;
    }

    if (!this.menu.contains(event.target as Node) && this.props.show && this.props.onClickOutside) {
      this.props.onClickOutside();
      document.removeEventListener("click", this.menuCloser);
    }

    event.stopPropagation();
  }
}
