import React, { Component } from "react";

import styles from "./TeamItem.module.css";

import UserAvatar from "components/userAvatar/UserAvatar";
import { RouteComponentProps, withRouter } from "react-router-dom";
import TeamData from "modules/api/apiModels/teamData";

import * as Icons from "react-feather";
import FlatIconButton from "components/flatIconButton/FlatIconButton";
import FloatingMenu from "components/floatingMenu/FloatingMenu";

interface TeamItemProps extends RouteComponentProps {
  item: TeamData;
  id: string;
}

interface ProjectsListItemState {
  open: boolean;
}

class TeamItem extends Component<TeamItemProps, ProjectsListItemState> {
  constructor(props: TeamItemProps) {
    super(props);

    this.state = {
      open: false
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleMenuBtn = this.toggleMenuBtn.bind(this);
    this.handleManageClick = this.handleManageClick.bind(this);
  }

  render() {
    const members = [];

    let count = 0;

    for (const member in this.props.item.members) {
      count++;

      if (count <= 5) members.push(member);
    }

    return (
      <div className={styles.TeamItem}>
        <div className={styles.avatarArea}>
          <div className={styles.teamAvatar}>
            <Icons.Users strokeWidth={1}></Icons.Users>
          </div>
        </div>
        <div className={styles.descArea}>
          <div className={styles.teamName}>{this.props.item.name}</div>
          <div className={styles.users}>
            {members.map((v, i) => {
              return <UserAvatar uid={v} size={16} />;
            })}
            {count > 5 && (
              <div className={styles.more}>
                <Icons.MoreHorizontal></Icons.MoreHorizontal>
              </div>
            )}
          </div>
        </div>
        <div className={styles.actionArea}>
          <FloatingMenu
            items={[
              {
                label: "Manage",
                icon: Icons.Settings,
                onClick: this.handleManageClick
              }
            ]}
            fixOn="left"
            show={this.state.open}
            onClickOutside={this.toggleMenu}
          >
            <FlatIconButton icon={Icons.MoreVertical} onClick={this.toggleMenuBtn}></FlatIconButton>
          </FloatingMenu>
        </div>
      </div>
    );
  }

  toggleMenu() {
    this.setState({
      open: !this.state.open
    });
  }

  toggleMenuBtn() {
    if (!this.state.open)
      this.setState({
        open: true
      });
  }

  handleManageClick() {
    this.props.history.push(this.props.location.pathname + "/manage/" + this.props.id);
  }
}

export default withRouter(TeamItem);
