import * as firebase from "firebase/app";
import { COMMON_ERRORS } from "types/commonErrors";

/**
 * Database and authentication helper methods
 */
class HelperMethods {
  /**
   * Gets the number of children in a database child
   * @param {string} ref Child path
   */
  public static async getChildrenNum(ref: string): Promise<number> {
    let url = (firebase.app().options as any).databaseURL + "/" + ref + ".json?shallow=true";

    const auth = firebase.auth();

    if (auth.currentUser) {
      url = url + "&auth=" + (await auth.currentUser.getIdToken());
    }

    const data = await (await fetch(url)).json();

    let n = 0;

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        n++;
      }
    }

    return n;
  }

  /**
   * This functions throw the standard `COMMON_ERRORS.ACTION_REQUIRES_LOGIN` if there's no user.
   * @throws {COMMON_ERRORS.ACTION_REQUIRES_LOGIN} Action Requires Login
   */
  public static throwNotLogged() {
    const auth = firebase.auth();

    if (!auth.currentUser) {
      throw COMMON_ERRORS.ACTION_REQUIRES_LOGIN;
    }
  }

  /**
   * Get snap from database url
   * @throws {COMMON_ERRORS.NOT_PERMITTED} Not Permitted
   * @throws {COMMON_ERRORS.ACTION_REQUIRES_LOGIN} Action Requires Login
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error
   */
  public static async getSnap(url: string, checkLogin = false): Promise<firebase.database.DataSnapshot> {
    const db = firebase.database();

    if (checkLogin) {
      this.throwNotLogged();
    }

    try {
      const snap = await db.ref(url).once("value");

      return snap;
    } catch (reason) {
      if (reason.code === "PERMISSION_DENIED") throw COMMON_ERRORS.NOT_PERMITTED;

      throw COMMON_ERRORS.UNKNOWN;
    }
  }

  /**
   * Get data from database url
   * @throws {COMMON_ERRORS.NOT_PERMITTED} Not Permitted
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error
   * @throws {COMMON_ERRORS.INEXISTS} Child doesn't exists
   * @throws {COMMON_ERRORS.ACTION_REQUIRES_LOGIN} Action Requires Login
   */
  public static async getSnapData(url: string, checkLogin = false, checkExists = false): Promise<any> {
    const snap = await this.getSnap(url, checkLogin);

    const auth = firebase.auth();

    if (!auth.currentUser) {
      throw COMMON_ERRORS.ACTION_REQUIRES_LOGIN;
    }

    if (checkExists && !snap.exists()) {
      throw COMMON_ERRORS.INEXISTS;
    }

    return snap.val();
  }

  /**
   * Set child from database url
   * @throws {COMMON_ERRORS.NOT_PERMITTED} Not Permitted
   * @throws {COMMON_ERRORS.ACTION_REQUIRES_LOGIN} Action Requires Login
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error
   */
  public static async setChild(url: string, data: any, checkLogin = false) {
    const db = firebase.database();

    if (checkLogin) {
      this.throwNotLogged();
    }

    try {
      await db.ref(url).set(data);
    } catch (reason) {
      if (reason.code === "PERMISSION_DENIED") throw COMMON_ERRORS.NOT_PERMITTED;

      throw COMMON_ERRORS.UNKNOWN;
    }
  }

  /**
   * Push child to database url
   * @throws {COMMON_ERRORS.NOT_PERMITTED} Not Permitted
   * @throws {COMMON_ERRORS.ACTION_REQUIRES_LOGIN} Action Requires Login
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error
   */
  public static async pushChild(url: string, data: any, checkLogin = false): Promise<firebase.database.Reference> {
    const db = firebase.database();

    if (checkLogin) {
      this.throwNotLogged();
    }

    try {
      return await db.ref(url).push(data);
    } catch (reason) {
      if (reason.code === "PERMISSION_DENIED") throw COMMON_ERRORS.NOT_PERMITTED;

      throw COMMON_ERRORS.UNKNOWN;
    }
  }

  /**
   * Update child from database url
   * @throws {COMMON_ERRORS.NOT_PERMITTED} Not Permitted
   * @throws {COMMON_ERRORS.ACTION_REQUIRES_LOGIN} Action Requires Login
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error
   */
  public static async updateChild(url: string, data: any, checkLogin = false) {
    const db = firebase.database();

    if (checkLogin) {
      this.throwNotLogged();
    }

    try {
      await db.ref(url).update(data);
    } catch (reason) {
      if (reason.code === "PERMISSION_DENIED") throw COMMON_ERRORS.NOT_PERMITTED;

      throw COMMON_ERRORS.UNKNOWN;
    }
  }

  /**
   * Remove child from database url
   * @throws {COMMON_ERRORS.NOT_PERMITTED} Not Permitted
   * @throws {COMMON_ERRORS.ACTION_REQUIRES_LOGIN} Action Requires Login
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error
   */
  public static async removeChild(url: string, checkLogin = false) {
    const db = firebase.database();

    if (checkLogin) {
      this.throwNotLogged();
    }

    try {
      await db.ref(url).remove();
    } catch (reason) {
      if (reason.code === "PERMISSION_DENIED") throw COMMON_ERRORS.NOT_PERMITTED;

      throw COMMON_ERRORS.UNKNOWN;
    }
  }

  /**
   * Gets the current user id
   * @throws {COMMON_ERRORS.ACTION_REQUIRES_LOGIN} Action Requires Login
   */
  public static getCurrentUserUid(): string {
    const auth = firebase.auth();

    if (!auth.currentUser) {
      throw COMMON_ERRORS.ACTION_REQUIRES_LOGIN;
    }

    return auth.currentUser.uid;
  }
}

export default HelperMethods;
