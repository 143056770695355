import React, { Component } from "react";

import styles from "./Tick.module.css";

import * as Icons from "react-feather";
import Spinner from "components/spinner/Spinner";

interface TickProps {
  ticked?: boolean;
  onClick?: () => void;
  loading?: boolean;
  taskName?: string;
}

export default class Tick extends Component<TickProps> {
  render() {
    return (
      <button
        className={styles.Tick + (!this.props.loading && this.props.ticked ? " " + styles.ticked : "")}
        onClick={this.props.onClick}
        aria-label={
          (this.props.ticked ? "Task completed. " : "Task pendent. ") +
          "Mark task " +
          (this.props.taskName ? this.props.taskName + " " : "") +
          "as " +
          (this.props.ticked ? "pendent" : "complete")
        }
        role="checkbox"
        aria-checked={this.props.ticked}
      >
        {this.props.ticked && !this.props.loading && <Icons.Check size={"0.875rem"} role="presentation" />}
        {this.props.loading && <Spinner colorful size={14} />}
      </button>
    );
  }
}
