import { TextInputValidator } from "components/inputs/textInput/TextInput";

class SiblingInputValidator implements TextInputValidator {
  private errorMessage: string;
  private siblingInput: string;

  public constructor(siblingInput: string, errorMessage: string) {
    this.errorMessage = errorMessage;
    this.siblingInput = siblingInput;
  }

  public async validate(value?: string, formValues?: { [key: string]: string }): Promise<true | string> {
    if (formValues && formValues[this.siblingInput] === value) return true;

    return this.errorMessage;
  }
}

export default SiblingInputValidator;
