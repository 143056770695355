import React from "react";
import { AuthCommonResponse } from "types/authTypes";
import CommonEvent from "types/commonEvent";

export type AuthState = "waiting" | "login" | "logged" | "guest" | "register" | "reset" | "resetConfirm";

export interface AuthContextState {
  isAuthenticated: boolean;
  isProfileComplete: boolean;
  username: string | undefined | null;
  uid: string | undefined | null;

  /**
   * The current operation in execution (waiting, login, loged, guest)
   */
  authState: AuthState;

  firstState: boolean;

  /**
   * Logins the user
   * @param {string} email User Email
   * @param {string} password User Password
   * @returns {Promise<boolean>} Login sucessful
   */
  login: (email: string, password: string) => Promise<AuthCommonResponse>;

  /**
   * Logins the user with a JWT Token
   * @param {string} email User Email
   * @param {string} password User Password
   * @returns {Promise<boolean>} Login sucessful
   */
  loginWithToken: (token: string) => Promise<AuthCommonResponse>;

  /**
   * Register a new user
   * @param name The user name
   * @param company The user company
   * @param email The user email adress
   * @param password The user password
   * @param passwordConfirmation The password confirmation
   * @returns {Promise<AuthCommonResponse>} Login sucessful or not
   */
  register: (
    name: string,
    company: string,
    email: string,
    password: string,
    passwordConfirmation: string
  ) => Promise<AuthCommonResponse>;

  resetPassword: (email: string) => Promise<AuthCommonResponse>;
  confirmResetPassword: (oobCode: string, newPassword: string) => Promise<AuthCommonResponse>;

  loginGoogle: () => Promise<AuthCommonResponse>;
  loginGithub: () => Promise<AuthCommonResponse>;
  loginEmailLink: (email: string, link: string) => Promise<AuthCommonResponse>;

  updateProfileState: (cb?: () => void) => void;

  logout: () => Promise<AuthCommonResponse>;

  onCompletionAssigned: CommonEvent;
}

const dummyMethod = (): Promise<AuthCommonResponse> => {
  return new Promise<AuthCommonResponse>((): void => {});
};

/**
 * Authentication context
 */
export const AuthContext = React.createContext<AuthContextState>({
  isAuthenticated: false,
  isProfileComplete: false,
  username: undefined,
  uid: undefined,

  authState: "waiting",
  firstState: false,

  login: dummyMethod,

  loginWithToken: dummyMethod,

  register: dummyMethod,

  resetPassword: dummyMethod,

  confirmResetPassword: dummyMethod,

  loginGithub: dummyMethod,

  loginGoogle: dummyMethod,

  loginEmailLink: dummyMethod,

  updateProfileState: () => {
    return;
  },

  logout: dummyMethod,

  onCompletionAssigned: new CommonEvent()
});
