import React, { Component } from "react";

import SuperButton from "../superButton/SuperButton";

import styles from "./AuthForm.module.css";

import { Link } from "react-router-dom";
import TextInput, { TextInputValidator } from "../inputs/textInput/TextInput";

import * as Icons from "react-feather";

interface AuthFormLink {
  caption?: string;
  route: string;
  label: string;
}

export interface AuthFormField {
  name: string;
  label: string;
  type: "email" | "password" | "text";
  placeholder: string;
  validators?: TextInputValidator[];
  icon?: React.ComponentType<Icons.Props>;
}

interface AuthFormProps {
  hideForm?: boolean;
  hideActionArea?: boolean;
  hideAdditionalContent?: boolean;
  invertChildren?: boolean;

  fields: AuthFormField[];

  title: string;

  secondaryLink?: AuthFormLink;

  processing?: boolean;
  masterError?: string | JSX.Element;
  masterSuccess?: string | JSX.Element;
  primaryButtonLabel?: string;

  onContinue?: (values: { [key: string]: string }) => void;
}

interface AuthFormState {
  fieldValue: { [key: string]: string };
  fieldValid: { [key: string]: boolean };

  forceTouched: boolean;
}

class AuthForm extends Component<AuthFormProps, AuthFormState> {
  public constructor(props: AuthFormProps) {
    super(props);

    const defaultValues: { [key: string]: string } = {};
    const defaultValids: { [key: string]: boolean } = {};

    this.state = {
      fieldValid: defaultValids,
      fieldValue: defaultValues,

      forceTouched: false
    };

    this.handleContinue = this.handleContinue.bind(this);
    this.handleDisabledContinue = this.handleDisabledContinue.bind(this);
    this.handleValidationStateChange = this.handleValidationStateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  public render(): JSX.Element {
    return (
      <>
        <div className={styles.AuthForm}>
          <div className={styles.formTitle}>{this.props.title}</div>
          {this.props.masterError && <div className={styles.invalidCredentialsAlert}>{this.props.masterError}</div>}

          {this.props.masterSuccess && (
            <div className={`${styles.invalidCredentialsAlert} ${styles.masterSucess}`}>{this.props.masterSuccess}</div>
          )}

          {this.props.invertChildren && <div>{this.props.children}</div>}

          {!this.props.hideForm && <div className={styles.inputMargin} />}

          {!this.props.hideForm && <div>{this.getFormFields()}</div>}

          {!this.props.hideActionArea && (
            <div className={styles.formActionArea}>
              <div className={styles.forgotLink}>
                {this.props.secondaryLink && (
                  <>
                    {this.props.secondaryLink.caption}
                    {this.props.secondaryLink.caption && <>&nbsp;</>}
                    <Link to={this.props.secondaryLink.route} tabIndex={this.props.fields.length + 2}>
                      {this.props.secondaryLink.label}
                    </Link>
                  </>
                )}
              </div>
              <div>
                <SuperButton
                  label={this.props.primaryButtonLabel || "Continue"}
                  spin={this.props.processing}
                  onClick={this.handleContinue}
                  onClickDisabled={this.handleDisabledContinue}
                  disabled={this.props.processing || !this.isFormValid()}
                  tabIndex={this.props.fields.length + 1}
                />
              </div>
            </div>
          )}

          {!this.props.hideAdditionalContent && !this.props.invertChildren && <div>{this.props.children}</div>}
        </div>
      </>
    );
  }

  private getFormFields(): JSX.Element {
    const fields = this.props.fields.map(
      (field: AuthFormField, key: number): JSX.Element => {
        const fieldValue: string = this.state.fieldValue[field.name] ? this.state.fieldValue[field.name]! : "";

        return (
          <div className={styles.inputContainer} key={key}>
            <label>
              {field.label}
              <div className={styles.entryContainer}>
                <TextInput
                  {...field}
                  spellCheck={false}
                  forceTouched={this.state.forceTouched}
                  onChange={this.handleChange}
                  onValidationStateChanged={this.handleValidationStateChange}
                  value={fieldValue}
                  formValues={this.state.fieldValue}
                  tabIndex={key + 1}
                  autoFocus={key === 0}
                  onKeyPress={this.handleKeyPress}
                  disabled={this.props.processing}
                  iconSide="left"
                />
              </div>
            </label>
          </div>
        );
      }
    );

    return <>{fields}</>;
  }

  private handleChange(value: string, field?: string): void {
    if (!field) {
      return; // can't change
    }

    this.setState(
      (previousState: AuthFormState): Pick<AuthFormState, "fieldValue"> => {
        return {
          fieldValue: { ...previousState.fieldValue, [field]: value }
        };
      }
    );
  }

  private handleValidationStateChange(valid: boolean, field?: string): void {
    if (!field) {
      return; // can't change
    }

    this.setState(
      (previousState: AuthFormState): Pick<AuthFormState, "fieldValid"> => {
        return {
          fieldValid: { ...previousState.fieldValid, [field]: valid }
        };
      }
    );
  }

  private isFormValid(): boolean {
    for (const key in this.state.fieldValid) {
      if (!this.state.fieldValid[key]) return false;
    }

    return true;
  }

  private handleContinue(): void {
    if (this.props.onContinue) {
      this.props.onContinue(this.state.fieldValue);
    }
  }

  private handleDisabledContinue(): void {
    this.setState({
      forceTouched: true
    });
  }

  private handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter" && !this.props.processing) {
      if (this.isFormValid()) {
        this.handleContinue();
      } else {
        this.handleDisabledContinue();
      }
    }
  }
}

export default AuthForm;
