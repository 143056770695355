import RegexValidator from "modules/customInputDataValidators/textValidators/regexValidator";

export const DEFAULT_USERNAME_FORMAT_VALIDATOR: RegexValidator = new RegexValidator(
  /^([A-Z]|[a-z]|_|-|[0-9])+$/,
  "Username should contain only letters, numbers and '_'."
);

export const DEFAULT_ALIAS_FORMAT_VALIDATOR: RegexValidator = new RegexValidator(
  /^([A-Z]|[a-z]|_|-|[0-9])+$/,
  "Alias should contain only letters, numbers and '_'."
);
