import React, { Component } from "react";

import styles from "./PunchlistArea.module.css";
import SuperButton from "components/superButton/SuperButton";

import * as Icons from "react-feather";
import ContentLoader from "react-content-loader";
import PunchlistMethods from "modules/api/punchlistMethods";
import ProjectMethods from "modules/api/projectMethods";
import PunchlistItemCompositor from "./puchlistItemCompositor/PunchlistItemCompositor";

import { withRouter, RouteComponentProps } from "react-router";
import ItemsSorter from "./itemsSorter/ItemsSorter";

interface PunchlistAreaProps extends RouteComponentProps {
  punchlistId: string;
  items: string[];
  ordering: "NEWEST_FIRST" | "OLDEST_FIRST";
}

interface PunchlistAreaState {
  name?: string;
  members?: string[];
  collapse: boolean;
}

class PunchlistArea extends Component<PunchlistAreaProps, PunchlistAreaState> {
  constructor(props: PunchlistAreaProps) {
    super(props);

    this.state = {
      collapse: true
    };

    this.handleGoClick = this.handleGoClick.bind(this);
    this.handleToggleMoreClick = this.handleToggleMoreClick.bind(this);
  }

  render() {
    let items = [...this.props.items];

    if (this.props.ordering === "NEWEST_FIRST" && this.state.collapse && items.length > 5) {
      items = items.splice(items.length - 5);
    }

    return (
      <div className={styles.PunchlistArea}>
        <div className={styles.titlebar}>
          <div className={styles.title}>
            {this.state.name ? (
              this.state.name + " (" + this.props.items.length + ")"
            ) : (
              <div className={styles.titleLoader}>
                <ContentLoader
                  height={20}
                  width={200}
                  speed={1}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                ></ContentLoader>
              </div>
            )}
          </div>
          <SuperButton
            label="View Punchlist"
            icon={Icons.ArrowRight}
            iconSide="right"
            outline
            onClick={this.handleGoClick}
          />
        </div>
        <div className={styles.list}>
          <ItemsSorter sorting={this.props.ordering}>
            {items.map((v, i) => {
              if ((this.state.collapse && i < 5) || !this.state.collapse)
                return (
                  <React.Fragment key={i}>
                    <PunchlistItemCompositor
                      itemId={v}
                      punchlistId={this.props.punchlistId}
                      projectMembers={this.state.members}
                    />
                  </React.Fragment>
                );
              else return <></>;
            })}
          </ItemsSorter>
          {this.state.collapse && this.props.items.length > 5 && (
            <button className={styles.showMoreButton} onClick={this.handleToggleMoreClick}>
              Show More Items ({this.props.items.length - 5})
            </button>
          )}

          {!this.state.collapse && this.props.items.length > 5 && (
            <button className={styles.showMoreButton} onClick={this.handleToggleMoreClick}>
              Collapse Items
            </button>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: PunchlistAreaProps) {
    if (prevProps.punchlistId !== this.props.punchlistId) {
      this.fetchData();
    }
  }

  async fetchData() {
    const project = await PunchlistMethods.getPunchlistProject(this.props.punchlistId);

    const projectData = await ProjectMethods.getProjectData(project);

    const members = [];

    for (const key in projectData.members) {
      members.push(key);
    }

    this.setState({
      name: projectData.name,
      members: members
    });
  }

  handleGoClick() {
    this.props.history.push("/app/punchlist/" + this.props.punchlistId);
  }

  handleToggleMoreClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }
}

export default withRouter(PunchlistArea);
