import React, { Component } from "react";

import styles from "./TopMenu.module.css";

import logo from "assets/logo.svg";
import { Link, Redirect } from "react-router-dom";

import FloatingMenu, { FloatingMenuItem } from "components/floatingMenu/FloatingMenu";

import * as Icons from "react-feather";
import { AuthContext } from "contexts/AuthContext";
import TopMenuLink from "./topMenuLink/TopMenuLink";
import UserAvatar from "components/userAvatar/UserAvatar";

interface TopMenuState {
  showUserMenu: boolean;
  redirectToHome: boolean;
}

class TopMenu extends Component<{}, TopMenuState> {
  public static contextType = AuthContext;
  public context!: React.ContextType<typeof AuthContext>;

  constructor(props: {}) {
    super(props);

    this.state = {
      showUserMenu: false,
      redirectToHome: false
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleUserIconClick = this.handleUserIconClick.bind(this);
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
  }

  public render(): JSX.Element {
    const menuItems: FloatingMenuItem[] = [
      {
        label: "Preferences",
        icon: Icons.Settings
      },
      {
        label: "Logout",
        icon: Icons.LogOut,
        onClick: this.handleLogoutClick
      }
    ];

    if (this.state.redirectToHome) return <Redirect to={{ pathname: "/" }} />;

    return (
      <header className={styles.TopMenu}>
        <div className={styles.container}>
          <Link className={styles.logo} to="/app">
            <img className={styles.logo} src={logo} alt="Punchlist" />
          </Link>
          <div className={styles.filler} />
          <div className={styles.linksArea}>
            <TopMenuLink exact link="/app" content="Home" />
            <TopMenuLink link="/app/teams" content="Teams" />
            <TopMenuLink link="/app/projects" content="Projects" />
            <TopMenuLink link="/app/punchlist/first" content="Punchlists" />
          </div>
          <FloatingMenu
            show={this.state.showUserMenu}
            className={styles.currentUser}
            fixOn="right"
            onClickOutside={this.handleClickOutside}
            items={menuItems}
          >
            <button onClick={this.handleUserIconClick} className={styles.currentUserLink}>
              <UserAvatar size={32} uid={this.context.uid || undefined} />
            </button>
          </FloatingMenu>
        </div>
      </header>
    );
  }

  handleClickOutside() {
    this.setState({
      showUserMenu: false
    });
  }

  handleUserIconClick() {
    this.setState({
      showUserMenu: true
    });
  }

  async handleLogoutClick() {
    const result = await this.context.logout();

    if (result.status) {
      this.setState({
        redirectToHome: true
      });
    }

    this.handleClickOutside();
  }
}

export default TopMenu;
