import HelperMethods from "./helper";
import TeamData from "./apiModels/teamData";
import TeamsListData from "./apiModels/teamsListData";
import HttpsFunctions from "./httpsFunctions";
import ErrorHelper from "types/errorHelper";
import { RetrievedProjectInvite, ProjectInvite } from "./apiModels/projectInvite";

class TeamMethods {
  private static cachedTeamsList?: TeamsListData;

  /**
   * Check if an alias is available for team creation
   * @param alias The alias to check
   */
  public static async isAliasAvailable(alias?: string): Promise<boolean> {
    if (!alias || alias === "") return false;

    return (await HttpsFunctions.isTeamAliasAvailable(alias)).data;
  }

  /**
   * Leaves a project
   * @param {string} projectId The id of the project to leave
   * @throws {COMMON_ERRORS.UNKNOWN} Unknown Error/Not Permitted
   */
  public static async leaveTeam(projectId: string) {
    try {
      await HttpsFunctions.leaveTeam(projectId);
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Deletes a project
   * @param {string} id Project ID
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error/Not Permitted
   */
  public static async deleteTeam(id: string) {
    try {
      await HttpsFunctions.deleteTeam(id);
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Get data about a specific team
   * @param alias The team alias(`$user/$alias`)
   * @throws {COMMON_ERRORS.NOT_PERMITTED} Not Permitted
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error
   * @throws {COMMON_ERRORS.INEXISTS} Child doesn't exists
   * @throws {COMMON_ERRORS.ACTION_REQUIRES_LOGIN} Action Requires Login
   */
  public static async getTeamData(alias: string): Promise<TeamData> {
    const data = await HelperMethods.getSnapData(`teams/${alias}`, true);

    return data;
  }

  /**
   * Get all projects list for current user
   * @throws {COMMON_ERRORS.NOT_PERMITTED} Not Permitted
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error
   * @throws {COMMON_ERRORS.INEXISTS} Child doesn't exists
   * @throws {COMMON_ERRORS.ACTION_REQUIRES_LOGIN} Action Requires Login
   */
  public static async getUserSubscribedTeams(): Promise<TeamsListData> {
    const uid = HelperMethods.getCurrentUserUid();

    const data = await HelperMethods.getSnap(`userData/${uid}/teams`);

    const teamsList: TeamsListData = {};

    if (data.exists()) {
      const val = data.val();

      for (const team in val) {
        teamsList[team] = await HelperMethods.getSnapData(`teams/${team}`);
      }
    }

    this.cachedTeamsList = teamsList;

    return teamsList;
  }

  /**
   * Get the latest cached projects list
   */
  public static getCachedTeamsList(): TeamsListData | undefined {
    return this.cachedTeamsList;
  }

  /**
   * Creates a project
   * @param {string} alias The project alias
   * @param {string} name The Project name
   * @param {string[]} members The list of members to invite to the project
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error/Not Permitted
   */
  public static async createTeam(alias: string, name: string, members: string[]) {
    try {
      await HttpsFunctions.createTeam(alias, name, members);
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Retrieves an invite
   * @param {string} token The invite token
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error/Not Permitted
   * @throws {COMMON_ERRORS.PROJECT_INVITE.INVALID_TOKEN} Invalid token
   * @throws {COMMON_ERRORS.PROJECT_INVITE.TOKEN_EXPIRED} Token expired
   * @throws {COMMON_ERRORS.PROJECT_INVITE.PROJECT_DELETED} Project deleted
   */
  public static async retrieveInvite(token: string): Promise<RetrievedProjectInvite> {
    try {
      const res = await HttpsFunctions.retrieveInvite(token, "team");

      return res.data;
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Executes an invite
   * @param {string} token The invite token
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error/Not Permitted
   * @throws {COMMON_ERRORS.PROJECT_INVITE.INVALID_TOKEN} Invalid token
   * @throws {COMMON_ERRORS.PROJECT_INVITE.TOKEN_EXPIRED} Token expired
   * @throws {COMMON_ERRORS.PROJECT_INVITE.PROJECT_DELETED} Project deleted
   * @throws {COMMON_ERRORS.PROJECT_INVITE.TOKEN_REVOKED} Token revoked
   * @throws {COMMON_ERRORS.PROJECT_INVITE.EMAILS_DOESNT_MATCH} Emails doesn't match
   */
  public static async executeInvite(token: string) {
    try {
      await HttpsFunctions.executeTeamInvite(token);
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Get login token for an invite
   * @param {string} token The invite token
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error/Not Permitted
   * @throws {COMMON_ERRORS.PROJECT_INVITE.INVALID_TOKEN} Invalid token
   * @throws {COMMON_ERRORS.PROJECT_INVITE.TOKEN_EXPIRED} Token expired
   */
  public static async getInviteLoginToken(token: string): Promise<string> {
    try {
      const res = await HttpsFunctions.getInviteLoginToken(token, "team");

      return res.data.loginToken;
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Get active invites for a project
   * @param {string} projectId Project id
   * @throws {COMMON_ERRORS.UNKNOWN} Unknown Error/Not Permitted
   */
  public static async getActiveInvites(projectId: string): Promise<ProjectInvite[]> {
    try {
      const res = await HttpsFunctions.getActiveInvites(projectId, "team");

      return res.data;
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Sets the project name
   * @param {string} id The target project id
   * @param {string} name The new name
   * @throws {COMMON_ERRORS.UNKNOWN} Unknow Error/Not Permitted
   * @throws {COMMON_ERRORS.PROJECT_INVITE.INVALID_TOKEN} Invalid token
   * @throws {COMMON_ERRORS.PROJECT_INVITE.TOKEN_EXPIRED} Token expired
   */
  public static async setTeamName(id: string, name: string) {
    await HelperMethods.setChild(`teams/${id}/name`, name, true);
  }

  /**
   * Revokes a list of invites
   * @param {string[]} inviteKeys The invites to revoke
   * @throws {COMMON_ERRORS.UNKNOWN} Unknown Error/Not Permitted
   */
  public static async revokeInvites(inviteKeys: string[]) {
    try {
      await HttpsFunctions.revokeInvites(inviteKeys, "team");
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Invites a list of users to the project
   * @param {string} projectId The target project id
   * @param {string[]} users The users to invite
   * @throws {COMMON_ERRORS.UNKNOWN} Unknown Error/Not Permitted
   */
  public static async inviteToTeam(projectId: string, users: string[]) {
    try {
      await HttpsFunctions.inviteToTeam(projectId, users);
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }

  /**
   * Remove a list of users from the project
   * @param {string} projectId The target project id
   * @param {string[]} users The users to remove
   * @throws {COMMON_ERRORS.UNKNOWN} Unknown Error/Not Permitted
   */
  public static async removeFromTeam(projectId: string, users: string[]) {
    try {
      await HttpsFunctions.removeFromTeam(projectId, users);
    } catch (ex) {
      throw ErrorHelper.getCommonErrorByCode(ex.message);
    }
  }
}

export default TeamMethods;
