import React, { Component } from "react";

import styles from "./NewTeam.module.css";

import PageHeader from "components/pageHeader/PageHeader";
import TextInput from "components/inputs/textInput/TextInput";

import * as Icons from "react-feather";
import SuperButton from "components/superButton/SuperButton";
import { DEFAULT_REQUIRED_VALIDATOR } from "modules/customInputDataValidators/textValidators/minLengthValidator";
import { DEFAULT_ALIAS_FORMAT_VALIDATOR } from "modules/customInputDataValidators/textValidators/usernameFormatValidator";
import { AuthContext } from "contexts/AuthContext";
import { DEFAULT_ALIAS_AVAILABLE_VALIDATOR } from "modules/customInputDataValidators/textValidators/aliasAvailableValidator";
import { Redirect } from "react-router-dom";
import ProjectUserList from "components/projectUserList/ProjectUserList";
import TeamMethods from "modules/api/teamMethods";

interface NewTeamState {
  alias: string;
  name: string;

  members: string[];

  aliasValid: boolean;
  nameValid: boolean;

  isValidatingAlias: number;

  forceTouched: boolean;

  isLoading: boolean;

  redirectSucess?: boolean;
}

export default class NewTeam extends Component<{}, NewTeamState> {
  public static contextType = AuthContext;
  public context!: React.ContextType<typeof AuthContext>;

  private members: string[] = [];

  constructor(props: {}) {
    super(props);

    this.state = {
      alias: "",
      name: "",
      members: this.members,
      aliasValid: false,
      nameValid: false,
      forceTouched: false,
      isValidatingAlias: 0,
      isLoading: false
    };

    this.addSearchingUser = this.addSearchingUser.bind(this);
    this.handleMemberRemove = this.handleMemberRemove.bind(this);
    this.tryPushOwnUsername = this.tryPushOwnUsername.bind(this);
    this.handleDisabledClick = this.handleDisabledClick.bind(this);
    this.handleContinueClick = this.handleContinueClick.bind(this);
  }

  componentDidMount() {
    if (this.context.username) {
      this.tryPushOwnUsername();
    } else {
      this.context.onCompletionAssigned.addEventListener(this.tryPushOwnUsername);
    }
  }

  componentWillUnmount() {
    this.context.onCompletionAssigned.removeEventListener(this.tryPushOwnUsername);
  }

  render() {
    if (this.state.redirectSucess) {
      return <Redirect to="/app/teams?message=team_success" />;
    }
    return (
      <div className={styles.NewTeam}>
        <PageHeader
          title="Create Team"
          summary="Teams help you share punchlists, projects and resources with other members on your organization."
        />
        <div className={styles.creationForm}>
          <label className={styles.formEntry}>
            Name
            <div className={styles.formInput}>
              <TextInput
                value={this.state.name}
                type="text"
                placeholder="My Awesome Team"
                icon={Icons.User}
                iconSide="left"
                onChange={this.setName}
                onValidationStateChanged={this.setNameValid}
                validators={[DEFAULT_REQUIRED_VALIDATOR]}
                forceTouched={this.state.forceTouched}
              />
            </div>
          </label>
          <label className={styles.formEntry}>
            Team Alias
            <div className={styles.formEntryCaption}>
              This will be your team unique identifier. It cannot be changed later.
            </div>
            <div className={styles.formInput}>
              <TextInput
                value={this.state.alias}
                type="text"
                placeholder="my-awesome-team"
                icon={Icons.Hash}
                iconSide="left"
                onChange={this.setAlias}
                onValidationStateChanged={this.setAliasValid}
                validators={[
                  DEFAULT_REQUIRED_VALIDATOR,
                  DEFAULT_ALIAS_FORMAT_VALIDATOR,
                  DEFAULT_ALIAS_AVAILABLE_VALIDATOR
                ]}
                forceTouched={this.state.forceTouched}
                onValidationCompleted={this.aliasValidationCompleted}
                onValidationStarted={this.aliasValidationStarted}
              />
            </div>
          </label>

          <div className={styles.formEntry}>
            <ProjectUserList
              members={this.state.members}
              onAddMember={this.addSearchingUser}
              onRemoveMember={this.handleMemberRemove}
            />
          </div>

          <div className={styles.actionArea}>
            <SuperButton
              label="Create Team"
              disabled={
                this.state.isLoading ||
                !this.state.nameValid ||
                !this.state.aliasValid ||
                this.state.isValidatingAlias !== 0
              }
              onClickDisabled={this.handleDisabledClick}
              onClick={this.handleContinueClick}
              spin={this.state.isLoading}
            />
          </div>
        </div>
      </div>
    );
  }

  async handleContinueClick() {
    this.setState({
      isLoading: true
    });
    await TeamMethods.createTeam(this.state.alias, this.state.name, this.state.members);
    this.setState({
      isLoading: false,
      redirectSucess: true
    });
  }

  handleDisabledClick() {
    this.setState({
      forceTouched: true
    });
  }

  tryPushOwnUsername() {
    if (this.context.username) {
      this.members = [...this.members, this.context.username];
      this.setState({
        members: this.members
      });
    }
  }

  addSearchingUser(member: string) {
    this.members = [...this.members, member];

    this.setState({
      members: this.members
    });
  }

  handleMemberRemove(user: string) {
    const x = [...this.members];
    x.splice(x.indexOf(user), 1);

    this.members = x;

    this.setState({
      members: this.members
    });
  }

  // event handlers
  setAlias = (val: string) => {
    this.setState({
      alias: val
    });
  };

  setName = (val: string) => {
    this.setState({
      name: val
    });
  };

  setAliasValid = (val: boolean) => {
    this.setState({
      aliasValid: val
    });
  };

  setNameValid = (val: boolean) => {
    this.setState({
      nameValid: val
    });
  };

  aliasValidationStarted = () => {
    this.setState({
      isValidatingAlias: this.state.isValidatingAlias + 1
    });
  };

  aliasValidationCompleted = () => {
    this.setState({
      isValidatingAlias: this.state.isValidatingAlias - 1
    });
  };
}
