import { PunchlistData } from "./apiModels/punchlistData";
import { ApiCommonResponse } from "types/apiCommonResponse";

import * as firebase from "firebase/app";
import { COMMON_ERRORS } from "types/commonErrors";
import { PunchlistItem } from "./apiModels/punchlistItem";
import HelperMethods from "./helper";

export type PunchlistListenerCallback = (itemId: string, itemData: PunchlistItem) => any;

interface PunchlistListener {
  method: "child_added" | "child_removed" | "child_changed";
  callback: PunchlistListenerCallback;
  punchlistId: string;

  assignedCallback?: (snap: firebase.database.DataSnapshot) => any;
}

class PunchlistMethods {
  private static punchlistsListeners: PunchlistListener[] = [];

  /**
   * Gets the punchlist data
   * @param punchlistId The id of the punchlist
   */
  public static async getPunchlistData(punchlistId: string): Promise<PunchlistData> {
    const name = await this.getPunchlistName(punchlistId);
    const project = await this.getPunchlistProject(punchlistId);
    const qtd = await this.getPunchlistPunchedQtd(punchlistId);

    return {
      name: name,
      project: project,
      punchedQtd: qtd
    };
  }

  public static async getPunchlistItems(punchlistId: string): Promise<{ [item: string]: PunchlistItem }> {
    const db = firebase.database();

    HelperMethods.throwNotLogged();

    try {
      const snap = await db
        .ref(`punchlists/${punchlistId}/items`)
        .orderByChild("creationDate")
        .once("value");

      const data = snap.val();

      if (data) {
        return data;
      }

      return {};
    } catch (reason) {
      if (reason.code === "PERMISSION_DENIED") throw COMMON_ERRORS.NOT_PERMITTED;

      throw COMMON_ERRORS.UNKNOWN;
    }
  }

  public static async getPunchlistProject(punchlistId: string): Promise<string> {
    return await this.getPunchlistProp(punchlistId, "project");
  }

  public static async getPunchlistName(punchlistId: string): Promise<string> {
    return await this.getPunchlistProp(punchlistId, "name");
  }

  public static async getPunchlistPunchedQtd(punchlistId: string): Promise<number> {
    return await this.getPunchlistProp(punchlistId, "punchedQtd");
  }

  private static async getPunchlistProp(punchlistId: string, prop: "name" | "project" | "punchedQtd"): Promise<any> {
    const data = await HelperMethods.getSnapData(`punchlists/${punchlistId}/${prop}`, true, true);

    return data;
  }

  public static async getPunchlistItemData(punchlistId: string, punchlistItem: string): Promise<PunchlistItem> {
    const data = await HelperMethods.getSnapData(`punchlists/${punchlistId}/items/${punchlistItem}`, true, true);

    return data;
  }

  /**
   * Gets the punchlist session date. -1 if there's not session.
   */
  public static async getPunchlistSession(
    punchlistId: string
  ): Promise<{ sessionDate: number; sessionPingDate: number }> {
    const snap = await HelperMethods.getSnap(`sessions/${punchlistId}`, true);

    if (!snap.exists()) {
      return { sessionDate: -1, sessionPingDate: -1 };
    }

    return snap.val();
  }

  public static async startPunchlistSession(punchlistId: string) {
    await HelperMethods.setChild(
      `sessions/${punchlistId}`,
      {
        sessionDate: firebase.database.ServerValue.TIMESTAMP,
        sessionPingDate: firebase.database.ServerValue.TIMESTAMP
      },
      true
    );
  }

  public static async pingPunchlistSession(punchlistId: string) {
    const db = firebase.database();
    const auth = firebase.auth();

    if (!auth.currentUser) {
      throw COMMON_ERRORS.ACTION_REQUIRES_LOGIN;
    }

    try {
      await db.ref(`sessions/${punchlistId}`).update({
        sessionPingDate: firebase.database.ServerValue.TIMESTAMP
      });
    } catch {
      await PunchlistMethods.startPunchlistSession(punchlistId);
    }
  }

  public static async pushComment(punchlistId: string, itemId: string, content: string) {
    await HelperMethods.pushChild(
      `punchlists/${punchlistId}/items/${itemId}/comments`,
      {
        owner: HelperMethods.getCurrentUserUid(),
        content: content,
        creationDate: firebase.database.ServerValue.TIMESTAMP
      },
      true
    );
  }

  public static async deleteComment(punchlistId: string, itemId: string, commentId: string) {
    await HelperMethods.removeChild(`punchlists/${punchlistId}/items/${itemId}/comments/${commentId}`, true);
  }

  public static async pushItem(punchlistId: string, title: string, punched: boolean) {
    await HelperMethods.pushChild(
      `punchlists/${punchlistId}/items`,
      {
        owner: HelperMethods.getCurrentUserUid(),
        title: title,
        punched: punched,
        creationDate: firebase.database.ServerValue.TIMESTAMP,
        updateDate: firebase.database.ServerValue.TIMESTAMP
      },
      true
    );
  }

  public static async deleteItem(punchlistId: string, itemId: string) {
    await HelperMethods.removeChild(`punchlists/${punchlistId}/items/${itemId}`, true);
  }

  public static async changeTickItem(punchlistId: string, itemId: string, tick: boolean) {
    await HelperMethods.updateChild(
      `punchlists/${punchlistId}/items/${itemId}`,
      {
        punched: tick,
        punchdate: firebase.database.ServerValue.TIMESTAMP
      },
      true
    );

    await HelperMethods.pushChild(
      `punchlists/${punchlistId}/items/${itemId}/punchistory`,
      {
        state: tick,
        time: firebase.database.ServerValue.TIMESTAMP
      },
      true
    );
  }

  public static async tickItem(punchlistId: string, itemId: string) {
    await PunchlistMethods.changeTickItem(punchlistId, itemId, true);
  }

  public static async assignItem(punchlistId: string, itemId: string, assignee?: string) {
    await HelperMethods.setChild(
      `punchlists/${punchlistId}/items/${itemId}/assignee`,
      assignee ? assignee : null,
      true
    );
  }

  public static async setTitle(punchlistId: string, itemId: string, newTitle: string) {
    await HelperMethods.setChild(`punchlists/${punchlistId}/items/${itemId}/title`, newTitle, true);
  }

  public static async untickItem(punchlistId: string, itemId: string) {
    await PunchlistMethods.changeTickItem(punchlistId, itemId, false);
  }

  public static listenPunchlistItemRemoved(punchlistId: string, cb: PunchlistListenerCallback) {
    return PunchlistMethods.listenPunchlistItem("child_removed", punchlistId, cb);
  }

  public static mutePunchlistItemRemoved(punchlistId: string, cb: PunchlistListenerCallback) {
    return PunchlistMethods.mutePunchlistItem("child_removed", punchlistId, cb);
  }

  public static listenPunchlistItemAdded(punchlistId: string, cb: PunchlistListenerCallback) {
    return PunchlistMethods.listenPunchlistItem("child_added", punchlistId, cb);
  }

  public static mutePunchlistItemAdded(punchlistId: string, cb: PunchlistListenerCallback) {
    return PunchlistMethods.mutePunchlistItem("child_added", punchlistId, cb);
  }

  public static listenPunchlistItemChanged(punchlistId: string, cb: PunchlistListenerCallback) {
    return PunchlistMethods.listenPunchlistItem("child_changed", punchlistId, cb);
  }

  public static listenPunchlistItemChildChanged(
    punchlistId: string,
    punchlistItem: string,
    cb: PunchlistListenerCallback
  ) {
    return PunchlistMethods.listenPunchlistItem("child_changed", punchlistId, cb, punchlistItem);
  }

  public static mutePunchlistItemChildChanged(
    punchlistId: string,
    punchlistItem: string,
    cb: PunchlistListenerCallback
  ) {
    return PunchlistMethods.mutePunchlistItem("child_changed", punchlistId, cb, punchlistItem);
  }

  public static mutePunchlistItemChanged(punchlistId: string, cb: PunchlistListenerCallback) {
    return PunchlistMethods.mutePunchlistItem("child_changed", punchlistId, cb);
  }

  private static listenPunchlistItem(
    event: "child_added" | "child_changed" | "child_removed",
    punchlistId: string,
    cb: PunchlistListenerCallback,
    item?: string
  ) {
    const db = firebase.database();
    const auth = firebase.auth();

    if (!auth.currentUser) {
      throw COMMON_ERRORS.ACTION_REQUIRES_LOGIN;
    }

    let ref = db.ref(`punchlists/${punchlistId}/items`);

    if (item) {
      ref = db.ref(`punchlists/${punchlistId}/items/${item}`);
    }

    const listener: PunchlistListener = {
      method: event,
      callback: cb,
      punchlistId: punchlistId
    };

    if (!item) {
      listener.assignedCallback = snap => {
        const val = snap.val();
        const key = snap.key;

        listener.callback(key || "", val);
      };
    } else {
      listener.assignedCallback = async snap => {
        const val = await db.ref(`punchlists/${punchlistId}/items/${item}`).once("value");
        const key = item;

        listener.callback(key || "", val.val());
      };
    }

    this.punchlistsListeners.push(listener);

    ref.on(event, listener.assignedCallback);
  }

  public static mutePunchlistItem(
    event: "child_added" | "child_changed" | "child_removed",
    punchlistId: string,
    cb: PunchlistListenerCallback,
    item?: string
  ) {
    const db = firebase.database();
    const auth = firebase.auth();

    if (!auth.currentUser) {
      throw COMMON_ERRORS.ACTION_REQUIRES_LOGIN;
    }

    let ref = db.ref(`punchlists/${punchlistId}/items`);

    if (item) {
      ref = db.ref(`punchlists/${punchlistId}/items/${item}`);
    }

    const listener = PunchlistMethods.punchlistsListeners.find(x => x.callback === cb);

    if (!listener) return;

    ref.off(event, listener.assignedCallback);

    const index = PunchlistMethods.punchlistsListeners.findIndex(x => x === listener);
    PunchlistMethods.punchlistsListeners.splice(index, 1);
  }

  public static async getPunchlistProgress(
    punchlistId: string
  ): Promise<ApiCommonResponse<{ unchecked: number; checked: number }>> {
    const auth = firebase.auth();

    if (!auth.currentUser) {
      throw COMMON_ERRORS.ACTION_REQUIRES_LOGIN;
    }

    const totalRes = await HelperMethods.getChildrenNum(`punchlists/${punchlistId}/items`);

    const checked = await HelperMethods.getSnapData(`punchlists/${punchlistId}/punchedQtd`);

    const unchecked = totalRes - checked;

    return {
      status: true,
      errors: [],
      response: {
        unchecked: unchecked,
        checked: checked
      }
    };
  }

  public static async setVisit(punchlistId: string) {
    console.log(`userData/${HelperMethods.getCurrentUserUid()}/recentPunchlists/${punchlistId}`);

    await HelperMethods.setChild(
      `userData/${HelperMethods.getCurrentUserUid()}/recentPunchlists/${punchlistId}`,
      firebase.database.ServerValue.TIMESTAMP,
      true
    );
  }

  public static async getRecentPunchlists() {
    return (
      (await HelperMethods.getSnapData(`userData/${HelperMethods.getCurrentUserUid()}/recentPunchlists`, true)) || {}
    );
  }
}

export default PunchlistMethods;
