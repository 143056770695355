import React, { Component } from "react";

import UserProfileMethods from "modules/api/userProfileMethods";

import styles from "./UserAvatar.module.css";

import defaultAvatar from "assets/defaultUser.jpg";

interface UserAvatarProps {
  size: number;
  uid?: string;

  /**
   * Set this to retrieve from pic field of the user profile
   */
  pic?: string;
}

interface UserAvatarState {
  avatarUrl: string;
}

class UserAvatar extends Component<UserAvatarProps, UserAvatarState> {
  constructor(props: UserAvatarProps) {
    super(props);

    this.state = {
      avatarUrl: defaultAvatar
    };
  }

  render() {
    return (
      <div
        className={styles.UserAvatar}
        style={{
          height: this.props.size + "px",
          width: this.props.size + "px",
          backgroundImage: `url(${this.state.avatarUrl})`,
          borderRadius: this.props.size / 2 + "px"
        }}
      />
    );
  }

  componentDidMount() {
    this.fetchProfilePicture();
  }

  componentDidUpdate(prevProps: UserAvatarProps) {
    if (prevProps.uid !== this.props.uid) {
      this.fetchProfilePicture();
    }
  }

  async fetchProfilePicture() {
    let pic;

    if (this.props.uid) {
      pic = await UserProfileMethods.getUserPicture(this.props.uid);
    } else if (this.props.pic) {
      pic = await UserProfileMethods.getUserPictureFromProfilePic(this.props.pic);
    } else {
      return;
    }

    if (pic) {
      this.setState({
        avatarUrl: pic
      });
    }
  }
}

export default UserAvatar;
