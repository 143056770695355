import React, { Component } from "react";

import AuthForm, { AuthFormField } from "components/authForm/AuthForm";
import {
  DEFAULT_REQUIRED_VALIDATOR,
  DEFAULT_PASSWORD_LENGTH_VALIDATOR
} from "modules/customInputDataValidators/textValidators/minLengthValidator";
import { AuthContext } from "contexts/AuthContext";
import FormWall from "components/formWall/FormWall";
import { Link } from "react-router-dom";

import * as Icons from "react-feather";
import SiblingInputValidator from "modules/customInputDataValidators/textValidators/siblingInputValidator";

const NEWPASS_FIELDS: AuthFormField[] = [
  {
    name: "password",
    placeholder: "••••••",
    label: "New Password",
    type: "password",
    validators: [DEFAULT_REQUIRED_VALIDATOR, DEFAULT_PASSWORD_LENGTH_VALIDATOR],
    icon: Icons.Lock
  },
  {
    name: "password_confirmation",
    placeholder: "••••••",
    label: "Confirm New Password",
    type: "password",
    validators: [
      DEFAULT_REQUIRED_VALIDATOR,
      DEFAULT_PASSWORD_LENGTH_VALIDATOR,
      new SiblingInputValidator("password", "Passwords doesn't match.")
    ],
    icon: Icons.Lock
  }
];

interface NewPasswordFormProps {
  oobCode: string;
}

interface NewPasswordFormState {
  hasError: boolean;
  error?: string | JSX.Element;
  success?: string | JSX.Element;
}

class NewPasswordForm extends Component<NewPasswordFormProps, NewPasswordFormState> {
  public static contextType = AuthContext;
  public context!: React.ContextType<typeof AuthContext>;

  public constructor(props: NewPasswordFormProps) {
    super(props);

    this.state = {
      hasError: false
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  public render(): JSX.Element {
    const success = this.state.success !== undefined;

    return (
      <div className="NewPassword">
        <FormWall
          actionLink={{
            caption: "Know your password?",
            label: "Login",
            route: "/login"
          }}
        >
          <div className="r-login-form">
            <AuthForm
              fields={NEWPASS_FIELDS}
              title="Reset Password"
              onContinue={this.handleContinue}
              processing={this.context.authState !== "guest"}
              masterError={this.state.error}
              masterSuccess={this.state.success}
              hideActionArea={success}
              hideAdditionalContent={success}
              hideForm={success}
            />
          </div>
        </FormWall>
      </div>
    );
  }

  private async handleContinue(values: { [key: string]: string }): Promise<void> {
    const authContext = this.context;

    const newPassword = values["password"];

    const response = await authContext.confirmResetPassword(this.props.oobCode, newPassword);

    this.setState({
      hasError: !response.status,
      error: !response.status ? response.errors[0].message : undefined,
      success: undefined
    });

    if (response.status) {
      this.setState({
        success: (
          <>
            Success! You can now <Link to="/login">login</Link> with your new password.
          </>
        )
      });
    }
  }
}

export default NewPasswordForm;
