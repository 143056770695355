import React, { Component } from "react";

import styles from "./Spinner.module.css";

import spinnerWhite from "assets/spinner.svg";
import spinnerBlue from "assets/spinner-blue.svg";

interface SpinnerProps {
  colorful?: boolean;
  size: number;
}

class Spinner extends Component<SpinnerProps> {
  render() {
    const defaultSizeStyle = { height: this.props.size + "px", width: this.props.size + "px" };

    return (
      <div className={styles.Spinner} style={defaultSizeStyle} role="presentation">
        {!this.props.colorful && (
          <img
            className={styles.spinnerCircle}
            style={defaultSizeStyle}
            alt="Waiting..."
            src={spinnerWhite}
          />
        )}

        {this.props.colorful && (
          <img className={styles.spinnerCircle} style={defaultSizeStyle} alt="Waiting..." src={spinnerBlue} />
        )}
      </div>
    );
  }
}

export default Spinner;
