import React, { Component } from "react";

import styles from "./RecentItem.module.css";

import * as Icons from "react-feather";
import PunchlistMethods from "modules/api/punchlistMethods";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface RecentItemProps extends RouteComponentProps {
  punchlistId: string;
}

interface RecentItemState {
  name: string;
}

class RecentItem extends Component<RecentItemProps, RecentItemState> {
  constructor(props: RecentItemProps) {
    super(props);

    this.state = {
      name: ""
    };

    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    return (
      <div className={styles.RecentItem} onClick={this.handleClick}>
        <div className={styles.avatarArea}>
          <div className={styles.teamAvatar}>
            <Icons.List />
          </div>
        </div>
        <div className={styles.descArea}>
          <div className={styles.teamName}>{this.state.name}</div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const name = await PunchlistMethods.getPunchlistName(this.props.punchlistId);

    this.setState({
      name: name
    });
  }

  handleClick() {
    this.props.history.push("/app/punchlist/" + this.props.punchlistId);
  }
}

export default withRouter(RecentItem);
