import React, { Component } from "react";

import styles from "./AddCommentBox.module.css";
import SuperButton from "components/superButton/SuperButton";

interface AddCommentProps {
  onEnter?: (content: string) => any;
}

interface AddCommentBoxState {
  expanded: boolean;
}

export default class AddCommentBox extends Component<AddCommentProps, AddCommentBoxState> {
  input?: HTMLDivElement;

  constructor(props: AddCommentProps) {
    super(props);

    this.state = {
      expanded: false
    };

    this.handleFocus = this.handleFocus.bind(this);
    this.handleUnfocus = this.handleUnfocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    return (
      <div className={styles.AddCommentBox + (this.state.expanded ? " " + styles.boxFocused : "")}>
        {!this.state.expanded && (
          <div className={styles.placeholder} tabIndex={0} onFocus={this.handleFocus}>
            Add comment...
          </div>
        )}
        {this.state.expanded && (
          <div
            className={styles.contentBox}
            contentEditable
            onBlur={this.handleUnfocus}
            ref={div => (this.input = div || undefined)}
            onInput={this.handleChange}
            onKeyDown={this.handleKeydown}
            onPaste={this.pasteInterceptor}
          ></div>
        )}
        {/* {this.state.expanded && ( */}
        <div className={styles.boxBtn}>
          <SuperButton label="Post" compact onClick={this.handleClick}></SuperButton>
        </div>
        {/* )} */}
      </div>
    );
  }

  handleFocus() {
    if (!this.state.expanded) {
      this.setState(
        {
          expanded: true
        },
        () => {
          if (this.input) this.input.focus();
        }
      );
    }
  }

  handleUnfocus() {
    if (!this.input) return;

    if (this.input.innerText) return;

    this.setState({
      expanded: false
    });
  }

  handleChange() {
    if (!this.input) return;

    // do something, like validate?
  }

  handleClick() {
    if (!this.input || this.input.innerText.trim() === "") return;

    if (this.props.onEnter) {
      this.props.onEnter(this.input.innerText);

      this.input.innerText = "";
    }

    this.input.blur();
    this.handleUnfocus();
  }

  handleKeydown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();

      if (!this.input || this.input.innerText.trim() === "") return;

      if (this.props.onEnter) {
        this.props.onEnter(this.input.innerText);

        this.input.innerText = "";
      }

      this.input.blur();
    }
  }

  pasteInterceptor(e: React.ClipboardEvent<HTMLDivElement>) {
    e.preventDefault();
    var text = e.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  }
}
