import { TextInputValidator } from "components/inputs/textInput/TextInput";

/**
 * Blend validator only checks validator `n+1` if the validator `n` returns `true`;
 */
class BlendValidator implements TextInputValidator {
  private errorMessage: string;
  private validators: TextInputValidator[];

  /**
   * Blend validator only checks validator `n+1` if the validator `n` returns `true`;
   * @param errorMessage The default error message
   * @param validators The validators to check
   */
  constructor(errorMessage: string, ...validators: TextInputValidator[]) {
    this.errorMessage = errorMessage;
    this.validators = validators;
  }

  public async validate(value?: string): Promise<true | string> {
    if (value === undefined) {
      return this.errorMessage;
    }

    for (let i = 0; i < this.validators.length; i++) {
      const validator = this.validators[i];

      const isValid = await validator.validate(value);

      if (isValid === true) {
        if (i === this.validators.length - 1) return true;
        else continue;
      } else {
        break;
      }
    }

    return this.errorMessage;
  }
}

export default BlendValidator;
