import { TextInputValidator } from "components/inputs/textInput/TextInput";
import UserProfileMethods from "modules/api/userProfileMethods";

class UsernameAvailableValidator implements TextInputValidator {
  private errorMessage: string;
  private timeout: number;

  private lastValidationResolver: ((v: true | string) => void) | null = null;

  public constructor(errorMessage: string = "Username is not available.", timeout: number = 2000) {
    this.errorMessage = errorMessage;
    this.timeout = timeout;
  }

  public validate(value: string | undefined): Promise<true | string> {
    if (this.lastValidationResolver) this.lastValidationResolver(true);

    const resolver = async (resolve: (v: true | string) => void) => {
      this.lastValidationResolver = resolve;

      setTimeout(async () => {
        if (this.lastValidationResolver !== resolve) return;

        if (!value) resolve("Username not provided.");

        const isAvailable = await UserProfileMethods.isUsernameAvailable(value);

        if (isAvailable) resolve(true);
        else resolve(this.errorMessage);

        if (this.lastValidationResolver === resolve) {
          this.lastValidationResolver = null;
        }
      }, this.timeout);
    };

    return new Promise(resolver);
  }
}

export const DEFAULT_USERNAME_AVAILABLE_VALIDATOR: UsernameAvailableValidator = new UsernameAvailableValidator();

export default UsernameAvailableValidator;
