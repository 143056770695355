import React, { Component } from "react";

import styles from "./CommonFooter.module.css";

class CommonFooter extends Component {
  render() {
    return (
      <div className={styles.CommonFooter}>
        Punchlist - v{process.env.REACT_APP_BRANCH_NAME} - Environment: {process.env.REACT_APP_ENVIRONMENT}
      </div>
    );
  }
}

export default CommonFooter;
