import React, { Component } from "react";

import styles from "./PageHeader.module.css";
import Spinner from "components/spinner/Spinner";

interface PageHeaderProps {
  title: string;
  titleColor?: string;
  summary?: string;
  spinner?: "colorful" | "white";
}

export default class PageHeader extends Component<PageHeaderProps> {
  render() {
    return (
      <div className={styles.PageHeader} style={{ color: this.props.titleColor || "#000000" }}>
        <div className={styles.titleArea}>
          <div className={styles.titleContainer}>
            <h1>{this.props.title}</h1>
            {this.props.spinner && (
              <div className={styles.spinner}>
                <Spinner size={16} colorful={this.props.spinner === "colorful"} />
              </div>
            )}
          </div>
          <div className={styles.summary}>{this.props.summary}</div>
        </div>
        <div className={styles.actionArea}>{this.props.children}</div>
      </div>
    );
  }
}
