import { TextInputValidator } from "components/inputs/textInput/TextInput";

class RegexValidator implements TextInputValidator {
  private errorMessage: string;
  private regex: RegExp;

  public constructor(regex: RegExp, errorMessage: string = "Invalid format.") {
    this.regex = regex;
    this.errorMessage = errorMessage;
  }

  public async validate(value: string | undefined): Promise<true | string> {
    if (value !== undefined && this.regex.test(value)) return true;

    return this.errorMessage;
  }

  public validateSync(value: string | undefined): true | string {
    if (value && this.regex.test(value)) return true;

    return this.errorMessage;
  }
}

export const EMAIL_VALIDATOR: RegexValidator = new RegexValidator(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  "Invalid e-mail address."
);

export const SIMPLE_EMAIL_LIST_VALIDATOR: RegexValidator = new RegexValidator(
  /^\s*([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\s*[,|;|\s]?\s*)*$/,
  "Invalid e-mail address."
);

export const NAMED_EMAIL_LIST_VALIDATOR: RegexValidator = new RegexValidator(
  /^\s*((\w\s*)*[<[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*>\s*[,|;|\s]?\s*)*$/,
  "Invalid e-mail address."
);

export default RegexValidator;
