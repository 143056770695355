import React, { Component } from "react";

import styles from "./TopMenuLink.module.css";
import { NavLink } from "react-router-dom";

interface TopMenuLinkProps {
  link: string;
  content: string;
  exact?: boolean;
}

class TopMenuLink extends Component<TopMenuLinkProps> {
  render() {
    return (
      <NavLink
        exact={this.props.exact}
        to={this.props.link}
        className={styles.TopMenuLink}
        activeClassName={styles.active}
      >
        <div className={styles.indicatorTop} />
        <div className={styles.content}>{this.props.content}</div>
        <div className={styles.indicator} />
      </NavLink>
    );
  }
}

export default TopMenuLink;
