class CommonEvent {
  public listeners: Array<() => void> = [];

  public addEventListener(listener: () => void) {
    this.listeners.push(listener);
  }

  public removeEventListener(listener: () => void) {
    this.listeners.splice(this.listeners.indexOf(listener), 1);
  }

  public propagate() {
    this.listeners.forEach(l => l());
  }
}

export default CommonEvent;
