import React, { Component } from "react";

import styles from "./ItemComment.module.css";
import UserAvatar from "components/userAvatar/UserAvatar";

import { PunchlistItemComment } from "modules/api/apiModels/PunchlistItemComment";
import { UserProfileSettings } from "modules/api/apiModels/UserProfileSettings";
import UserProfileMethods from "modules/api/userProfileMethods";
import Spinner from "components/spinner/Spinner";
import TimeUtil from "modules/util/time";
import Tooltip from "components/tooltip/Tooltip";

import * as Icons from "react-feather";
import FloatingMenu from "components/floatingMenu/FloatingMenu";
import PunchlistMethods from "modules/api/punchlistMethods";
import { AuthContext } from "contexts/AuthContext";

interface ItemCommentProps {
  commentId: string;
  itemId: string;
  punchlistId: string;

  compact?: boolean;

  itemData: PunchlistItemComment;
}

interface ItemCommentState {
  loadingUserDetails?: boolean;
  username?: string;
  details?: UserProfileSettings;

  overflows?: boolean;
  collapse?: boolean;

  menuOpen?: boolean;
}

export default class ItemComment extends Component<ItemCommentProps, ItemCommentState> {
  public static contextType = AuthContext;
  public context!: React.ContextType<typeof AuthContext>;

  avatar: number = Math.round(Math.random() * 2);
  contentDiv?: HTMLDivElement;

  constructor(props: ItemCommentProps) {
    super(props);

    this.state = {
      loadingUserDetails: true,

      overflows: false,
      collapse: true,

      menuOpen: false
    };

    this.handleShowLessClick = this.handleShowLessClick.bind(this);
    this.handleShowMoreClick = this.handleShowMoreClick.bind(this);
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.updateUserInfo();
  }

  componentDidUpdate(prevProps: ItemCommentProps) {
    if (prevProps.itemData.owner !== this.props.itemData.owner) {
      this.updateUserInfo();
    }

    if (this.contentDiv && this.contentDiv.scrollHeight > this.contentDiv.offsetHeight) {
      if (!this.state.overflows)
        this.setState({
          overflows: true
        });
    } else {
      if (this.state.overflows)
        this.setState({
          overflows: false
        });
    }
  }

  render() {
    let creationDate = new Date(this.props.itemData.creationDate);

    let creationDelta = Date.now() - this.props.itemData.creationDate;
    let creationBefore = TimeUtil.getReadableDelta(creationDelta);

    let userAvatar = (
      <div className={styles.avatarContainer}>
        <UserAvatar uid={this.props.itemData.owner} size={this.props.compact ? 20 : 32} />
      </div>
    );

    let profileName = (
      <div className={styles.profileName}>
        {this.state.details && this.state.details.name + (this.props.compact ? ":" : "")}
      </div>
    );

    let showMoreBtn = (
      <button
        className={this.props.compact ? styles.showMoreButtonCompact : styles.showMoreButton}
        onClick={this.handleShowMoreClick}
      >
        Show more
      </button>
    );

    let showLessBtn = (
      <button
        className={this.props.compact ? styles.showLessButtonCompact : styles.showMoreButton}
        onClick={this.handleShowLessClick}
      >
        Show less
      </button>
    );

    return (
      <div className={styles.ItemComment + (this.props.compact ? " " + styles.compact : "")}>
        <div className={styles.titleArea}>
          {this.props.compact ? (
            <Tooltip tooltip={"@" + this.state.username + " - " + creationBefore}>{userAvatar}</Tooltip>
          ) : (
            userAvatar
          )}
          <div className={styles.profileInfo}>
            {this.state.loadingUserDetails && <Spinner size={12} colorful />}
            {this.state.details && (
              <>
                {this.props.compact ? (
                  <Tooltip tooltip={"@" + this.state.username + " - " + creationBefore}>{profileName}</Tooltip>
                ) : (
                  profileName
                )}
                {!this.props.compact && (
                  <div className={styles.profileUsername}>
                    @{this.state.username} &#8212;{" "}
                    <Tooltip tooltip={creationDate.toLocaleString()}>{creationBefore}</Tooltip>
                  </div>
                )}
              </>
            )}
          </div>
          {this.props.compact && (
            <div
              className={styles.contentFirst + " " + (this.state.collapse ? styles.collapsed : "")}
              ref={d => (this.contentDiv = d || undefined)}
            >
              {this.props.itemData.content}

              {!this.state.collapse && showLessBtn}
            </div>
          )}
          {this.props.compact && this.state.overflows && showMoreBtn}
          {!this.props.compact && this.props.itemData.owner === this.context.uid && (
            <FloatingMenu
              fixOn="right"
              show={this.state.menuOpen}
              items={[{ icon: Icons.Trash, label: "Delete", onClick: this.handleDelete }]}
              onClickOutside={this.handleToggleMenu}
            >
              <button className={styles.optionsBtn} onClick={this.handleToggleMenu}>
                <Icons.MoreVertical />
              </button>
            </FloatingMenu>
          )}
        </div>
        {!this.props.compact && (
          <div
            className={styles.content + " " + (this.state.collapse ? styles.collapsed : "")}
            ref={d => (this.contentDiv = d || undefined)}
          >
            {this.props.itemData.content}
          </div>
        )}

        {!this.props.compact && this.state.overflows && showMoreBtn}
        {!this.props.compact && !this.state.collapse && showLessBtn}
      </div>
    );
  }

  async updateUserInfo() {
    this.setState({ loadingUserDetails: true });

    const uid = this.props.itemData.owner;

    const username = await UserProfileMethods.getUserUsername(uid);
    const details = await UserProfileMethods.getUserDetails(uid);

    this.setState({ loadingUserDetails: false, username: username || undefined, details: details });
  }

  async handleDelete() {
    this.handleToggleMenu();

    await PunchlistMethods.deleteComment(this.props.punchlistId, this.props.itemId, this.props.commentId);
  }

  handleShowMoreClick() {
    this.setState({
      collapse: false
    });
  }

  handleShowLessClick() {
    this.setState({
      collapse: true
    });
  }

  handleToggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen
    });
  }
}
