export const ERROR_MESSAGES = {
  UNKNOWN: "An unknown error ocurred.",
  ACTION_REQUIRES_LOGIN: "You must be signed in to complete this action.",
  PROFILE_INEXISTS: "The user profile doesn't exists.",
  NOT_PERMITTED: "The user doesn't have permission to access this data.",
  MISSING_COMPANY: "User profile is missing company field.",
  MISSING_NAME: "User profile is missing name field.",
  MISSING_USERNAME: "User profile is missing username field.",
  USERNAME_TAKEN: "Username is already taken.",
  USERNAME_INVALID: "Username must only contain letters, numbers and underscores.",
  INEXISTS: "The request resource doesn't exists in the database.",
  PROJECT_INVITE_REVOKED: "This invitation link was revoked.",
  PROJECT_DELETED: "This project was deleted.",
  PROJECT_TOKEN_EXPIRED: "This invite expired.",
  PROJECT_INEXISTS: "The requested project doesn't exists.",
  PROJECT_INVITE_EMAILS_DOESNT_MATCH: "Invite is not valid for current account. Logout and try again.",
  INVALID_TOKEN: "Invalid token.",
  ALIAS_INVALID: "Project alias is invalid.",
  ALIAS_TAKEN: "Project alias already taken."
};
