import React, { Component } from "react";
import { AuthContext } from "contexts/AuthContext";
import FormWall from "components/formWall/FormWall";
import AuthForm, { AuthFormField } from "components/authForm/AuthForm";
import UserProfileMethods from "modules/api/userProfileMethods";
import { DEFAULT_REQUIRED_VALIDATOR } from "modules/customInputDataValidators/textValidators/minLengthValidator";
import { COMMON_ERRORS } from "types/commonErrors";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";

import * as firebase from "firebase/app";

import * as Icons from "react-feather";
import { DEFAULT_USERNAME_FORMAT_VALIDATOR } from "modules/customInputDataValidators/textValidators/usernameFormatValidator";
import { DEFAULT_USERNAME_AVAILABLE_VALIDATOR } from "modules/customInputDataValidators/textValidators/usernameAvailableValidator";

const BASE_FORM_FIELDS: { [key: string]: AuthFormField } = {
  "userProfile/missingUsername": {
    name: "username",
    placeholder: "johndoe",
    label: "Username",
    type: "text",
    validators: [DEFAULT_REQUIRED_VALIDATOR, DEFAULT_USERNAME_FORMAT_VALIDATOR, DEFAULT_USERNAME_AVAILABLE_VALIDATOR],
    icon: Icons.AtSign
  },
  "userProfile/missingCompany": {
    name: "company",
    placeholder: "Company Name",
    label: "Company",
    type: "text",
    validators: [DEFAULT_REQUIRED_VALIDATOR],
    icon: Icons.Shield
  },

  "userProfile/missingName": {
    name: "name",
    placeholder: "John Doe",
    label: "Name",
    type: "text",
    validators: [DEFAULT_REQUIRED_VALIDATOR],
    icon: Icons.User
  }
};

interface CompleteCreationState {
  formFields: AuthFormField[];
  processing: boolean;

  error?: string;

  redirectBack: boolean;
}

class CompleteCreation extends Component<RouteComponentProps<any>, CompleteCreationState> {
  public static contextType = AuthContext;
  public context!: React.ContextType<typeof AuthContext>;

  constructor(props: RouteComponentProps<any>) {
    super(props);

    this.state = {
      formFields: [],
      processing: false,
      redirectBack: false
    };

    this.updateCompletionState = this.updateCompletionState.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  render() {
    let redirectTo = "/app";

    const query = new URLSearchParams(window.location.search);
    const redirectBack = query.get("next");
    if (redirectBack) redirectTo = redirectBack;

    if (this.state.redirectBack) return <Redirect to={{ pathname: redirectTo }} />;

    return (
      <div className="CompleteCreation">
        {this.state.redirectBack && <>back</>}
        <FormWall>
          <div>
            <AuthForm
              fields={this.state.formFields}
              title="Complete Profile"
              onContinue={this.handleContinue}
              processing={this.state.processing}
              masterError={this.state.error}
            />
          </div>
        </FormWall>
      </div>
    );
  }

  componentDidUpdate() {
    if (this.context.isProfileComplete && !this.state.redirectBack) {
      this.setState({
        redirectBack: true
      });
    }
  }

  componentDidMount() {
    if (!firebase.auth().currentUser) {
      firebase.auth().onAuthStateChanged(this.updateCompletionState);
    } else {
      this.updateCompletionState();
    }
  }

  async updateCompletionState() {
    if (!this.context.isAuthenticated) {
      return;
    }

    const completion = await UserProfileMethods.isUserProfileComplete();

    const formFields: AuthFormField[] = [];

    completion.errors.forEach(err => {
      if (err.code === COMMON_ERRORS.USER_PROFILE.PROFILE_INEXISTS.code) {
        for (const key in BASE_FORM_FIELDS) {
          if (
            BASE_FORM_FIELDS.hasOwnProperty(key) &&
            key !== "userProfile/missingUsername" &&
            key !== "userProfile/missingName"
          ) {
            formFields.push(BASE_FORM_FIELDS[key] as AuthFormField);
          }
        }

        return;
      }

      formFields.push(BASE_FORM_FIELDS[err.code] as AuthFormField);
    });

    this.setState({
      formFields: formFields
    });
  }

  async handleContinue(values: { [key: string]: string }): Promise<void> {
    this.setState({
      processing: true
    });

    if (values["company"]) {
      try {
        await UserProfileMethods.updateProfileSettings(values["company"]);
      } catch (ex) {
        this.setState({
          processing: false,
          error: ex.message
        });

        return;
      }
    }

    if (values["name"]) {
      try {
        await UserProfileMethods.setDisplayName(values["name"]);
      } catch (ex) {
        this.setState({
          processing: false,
          error: ex.message
        });

        return;
      }
    }

    if (values["username"]) {
      try {
        await UserProfileMethods.setCurrentUserUsername(values["username"]);
      } catch (ex) {
        this.setState({
          processing: false,
          error: ex.message
        });

        return;
      }
    }

    this.context.updateProfileState(() => {
      this.setState({
        processing: false,
        redirectBack: true
      });
    });
  }
}

export default withRouter(CompleteCreation);
