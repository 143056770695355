import { TextInputValidator } from "components/inputs/textInput/TextInput";

class MinLengthValidator implements TextInputValidator {
  private errorMessage: string;
  private minLength: number;

  public constructor(length: number, errorMessage: string) {
    this.errorMessage = errorMessage;
    this.minLength = length;
  }

  public async validate(value?: string): Promise<true | string> {
    if (value !== undefined && value.trim().length >= this.minLength) return true;

    return this.errorMessage;
  }
}

export const DEFAULT_REQUIRED_VALIDATOR: MinLengthValidator = new MinLengthValidator(
  1,
  "This field is required."
);

export const DEFAULT_PASSWORD_LENGTH_VALIDATOR: MinLengthValidator = new MinLengthValidator(
  6,
  "Password must be at least 6 characters long."
);

export default MinLengthValidator;
