import React, { Component } from "react";
import FormWall from "components/formWall/FormWall";
import AuthForm from "components/authForm/AuthForm";

import ProjectMethods from "modules/api/projectMethods";
import { AuthContext } from "contexts/AuthContext";
import TeamMethods from "modules/api/teamMethods";

interface JoinAuthState {
  projectInfo?: {
    owner: string;
    name: string;
  };

  error?: string;
  sucess?: string;

  token?: string;
  type: string;

  processing: boolean;
}

export default class JoinAuth extends Component<{}, JoinAuthState> {
  public static contextType = AuthContext;
  public context!: React.ContextType<typeof AuthContext>;

  private triedLogin: boolean = false;

  constructor(props: {}) {
    super(props);

    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    const type = query.get("type");

    this.state = {
      token: token || undefined,
      processing: false,
      type: type || "project"
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  render() {
    if (this.context.firstState && this.context.isAuthenticated)
      window.location.href =
        window.location.origin + "/app/join/?type=" + this.state.type + "&token=" + this.state.token;

    return (
      <FormWall>
        <div>
          <AuthForm
            fields={[]}
            title="Join"
            invertChildren
            primaryButtonLabel="Login"
            onContinue={this.handleContinue}
            processing={!this.state.error}
            masterError={this.state.error}
            masterSuccess={this.state.sucess}
            hideActionArea={this.state.sucess !== undefined || this.state.error !== undefined}
          ></AuthForm>
        </div>
      </FormWall>
    );
  }

  componentDidUpdate() {
    if (this.context.firstState && !this.context.isAuthenticated && !this.triedLogin) {
      this.tryLogin();
    }
  }

  async tryLogin() {
    this.triedLogin = true;

    if (!this.state.token) {
      return;
    }

    try {
      const loginToken =
        this.state.type === "project"
          ? await ProjectMethods.getInviteLoginToken(this.state.token)
          : await TeamMethods.getInviteLoginToken(this.state.token);

      const res = await this.context.loginWithToken(loginToken);

      if (!res.status) {
        this.setState({
          error: "Can't login."
        });
      }
    } catch (err) {
      console.log(err);

      this.setState({
        error: "Can't retrieve invite. Maybe it was already used, revoked or expired."
      });
    }
  }

  async handleContinue() {
    this.setState({ processing: true });

    if (!this.state.token) return;

    try {
      await ProjectMethods.executeInvite(this.state.token);

      this.setState({
        sucess: "Invite accepted.",
        processing: false
      });
    } catch {
      this.setState({
        error: "Can't accept invite.",
        processing: false
      });
    }
  }
}
