import React, { Component } from "react";
import FormWall from "components/formWall/FormWall";
import AuthForm from "components/authForm/AuthForm";

import ProjectMethods from "modules/api/projectMethods";
import TeamMethods from "modules/api/teamMethods";

interface JoinState {
  projectInfo?: {
    owner: string;
    name: string;
  };

  error?: string;
  sucess?: string;

  token?: string;

  type: string;

  processing: boolean;
}

export default class Join extends Component<{}, JoinState> {
  constructor(props: {}) {
    super(props);

    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    const type = query.get("type");

    this.state = {
      token: token || undefined,
      processing: false,
      type: type || "project"
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  render() {
    // const query = new URLSearchParams(window.location.search);
    // const token = query.get("token");

    // if (token !== this.state.token) {
    //   this.retrieveProject();
    // }

    return (
      <FormWall hideLogo>
        <div>
          <AuthForm
            fields={[]}
            title={"Enter " + (this.state.type === "project" ? "Project" : "Team")}
            invertChildren
            primaryButtonLabel="Accept"
            onContinue={this.handleContinue}
            processing={!this.state.projectInfo || this.state.processing}
            masterError={this.state.error}
            masterSuccess={this.state.sucess}
            hideActionArea={this.state.sucess !== undefined || this.state.error !== undefined}
          >
            {this.state.projectInfo && !this.state.sucess && (
              <>
                <span>
                  {this.state.projectInfo.owner} invited you to join his{" "}
                  {this.state.type === "project" ? "project" : "team"} "<b>{this.state.projectInfo.name}</b>".
                </span>
                <br />
                <br />
              </>
            )}
          </AuthForm>
        </div>
      </FormWall>
    );
  }

  componentDidMount() {
    this.retrieveProject();
  }

  componentDidUpdate() {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");

    if (token !== this.state.token) {
      this.retrieveProject();
    }
  }

  async retrieveProject() {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    const type = query.get("type");

    this.setState({
      token: token || undefined,
      type: type || "project"
    });

    if (!this.state.token) {
      return;
    }

    try {
      const projectData =
        type === "project"
          ? await ProjectMethods.retrieveInvite(this.state.token)
          : await TeamMethods.retrieveInvite(this.state.token);

      this.setState({
        projectInfo: projectData
      });
    } catch (err) {
      this.setState({
        error: err.message
      });
    }
  }

  async handleContinue() {
    this.setState({ processing: true });

    if (!this.state.token) return;

    try {
      if (this.state.type === "project") await ProjectMethods.executeInvite(this.state.token);
      else await TeamMethods.executeInvite(this.state.token);

      this.setState({
        sucess: "Invite accepted.",
        processing: false
      });
    } catch (err) {
      this.setState({
        error: err.message,
        processing: false
      });
    }
  }
}
