import React, { Component } from "react";

import styles from "./ProgressBar.module.css";

interface ProgressBarProps {
  progress: number;
}

export default class ProgressBar extends Component<ProgressBarProps> {
  render() {
    return (
      <div className={styles.ProgressBar}>
        <div className={styles.container} style={{ width: `${this.props.progress * 100}%` }}>
          <div className={styles.leftCap}></div>
          <div className={styles.track}></div>
          <div className={styles.rightCap}></div>
        </div>
      </div>
    );
  }
}
