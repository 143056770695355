import { COMMON_ERRORS } from "./commonErrors";

class ErrorHelper {
  public static getCommonErrorByCode(code: string) {
    const result = this.searchCommonErrorInObject(code, COMMON_ERRORS);

    if (result === null) {
      return COMMON_ERRORS.UNKNOWN;
    }

    return result;
  }

  private static searchCommonErrorInObject(code: string, object: any): any {
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const element = object[key];

        if (element.code && element.code === code) {
          return element;
        } else if (!element.code) {
          const result = this.searchCommonErrorInObject(code, element);

          if (result) {
            return result;
          }
        }
      }
    }

    return null;
  }
}

export default ErrorHelper;
