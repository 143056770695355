import React, { Component } from "react";

import styles from "./SuperButton.module.css";

import Spinner from "components/spinner/Spinner";

import * as Icons from "react-feather";

interface SuperButtonProps {
  tabIndex?: number;

  label: string;

  onClick?: () => void;
  onClickDisabled?: () => void;

  disabled?: boolean;
  spin?: boolean;

  className?: string;

  outline?: boolean;
  compact?: boolean;

  customColor?: string;

  icon?: React.ComponentType<Icons.Props>;
  iconSide?: "left" | "right";

  iconOnly?: boolean;

  title?: string;
}

class SuperButton extends Component<SuperButtonProps> {
  public constructor(props: SuperButtonProps) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  public render(): JSX.Element {
    let customStyle = {};

    if (this.props.customColor)
      customStyle = {
        backgroundColor: this.props.customColor,
        color: this.props.customColor,
        borderColor: this.props.customColor
      };

    return (
      <button
        className={
          styles.SuperButton +
          (this.props.disabled ? " " + styles.disabled : "") +
          (this.props.outline ? " " + styles.outline : "") +
          (this.props.compact ? " " + styles.compact : "") +
          (this.props.spin ? " " + styles.spinning : "") +
          (this.props.className ? ` ${this.props.className}` : "") +
          (this.props.iconOnly ? " " + styles.iconOnly : "")
        }
        style={customStyle}
        onClick={this.handleClick}
        tabIndex={this.props.tabIndex}
        aria-label={this.props.label}
        title={this.props.iconOnly ? this.props.label : this.props.title}
      >
        {this.props.icon && this.props.iconSide !== "right" && (
          <div className={styles.icon}>
            <this.props.icon size={16}></this.props.icon>
          </div>
        )}
        {!this.props.iconOnly && <div className={styles.label}>{this.props.label}</div>}
        {this.props.icon && this.props.iconSide === "right" && (
          <div className={styles.icon}>
            <this.props.icon size={16}></this.props.icon>
          </div>
        )}
        <div className={styles.spinnerContainer + (this.props.spin ? "" : " " + styles.notVisible)}>
          <Spinner size={16} colorful={this.props.outline} />
        </div>
      </button>
    );
  }

  private handleClick(): void {
    if (this.props.disabled && this.props.onClickDisabled) {
      this.props.onClickDisabled();
    }

    if (!this.props.disabled && this.props.onClick) {
      this.props.onClick();
    }
  }
}

export default SuperButton;
