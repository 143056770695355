import React, { Component } from "react";

import styles from "./TeamsList.module.css";

import TeamItem from "components/teamItem/TeamItem";
import TeamsListData from "modules/api/apiModels/teamsListData";

import * as Icons from "react-feather";
import { Link } from "react-router-dom";

interface TeamsListProps {
  items: TeamsListData;
  filter: string;
  onResetFilter?: () => void;
}

export default class TeamsList extends Component<TeamsListProps> {
  render() {
    const items = this.getItemArray();

    return <div className={items.length > 0 ? styles.TeamsList : styles.ProjectList}>{this.parseItems(items)}</div>;
  }

  getItemArray(): JSX.Element[] {
    const items: JSX.Element[] = [];

    let i = 0;

    for (const key in this.props.items) {
      const item = this.props.items[key];

      if (
        item.name
          .toLowerCase()
          .trim()
          .includes(this.props.filter.toLowerCase().trim())
      )
        items.push(<TeamItem item={item} key={i} id={key}></TeamItem>);

      i++;
    }

    return items;
  }

  parseItems(items: JSX.Element[]): JSX.Element {
    if (items.length === 0 && !this.props.filter) {
      return (
        <div className={styles.noProjects}>
          <div className={styles.noProjectsIcon}>
            <Icons.Coffee size="64" strokeWidth="1" />
          </div>
          <div className={styles.caption}>You don't have any team.</div>
          <Link className="linkLikeBtn" to="/app/teams/create">
            Create One
          </Link>
        </div>
      );
    }

    if (items.length === 0 && this.props.filter) {
      return (
        <div className={styles.noProjects}>
          <div className={styles.noProjectsIcon}>
            <Icons.Codesandbox size="64" strokeWidth="1" />
          </div>
          <div className={styles.caption}>No teams found for &ldquo;{this.props.filter}&rdquo;</div>
          <button className="linkLikeBtn" onClick={this.props.onResetFilter}>
            See All Teams
          </button>
        </div>
      );
    }
    return <>{items}</>;
  }
}
