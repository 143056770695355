import React, { Component } from "react";
import RecentItem from "./recentItem/RecentItem";

import styles from "./RecentPunchlists.module.css";
import PunchlistMethods from "modules/api/punchlistMethods";
import NewItem from "./newItem/NewItem";

interface RecentPunchlistsState {
  punchlists: Array<{
    id: string;
    date: number;
  }>;
}

export default class RecentPunchlists extends Component<{}, RecentPunchlistsState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      punchlists: []
    };
  }

  render() {
    return (
      <div className={styles.RecentPunchlists}>
        {this.state.punchlists.map(x => {
          return <RecentItem punchlistId={x.id} />;
        })}
        <NewItem />
      </div>
    );
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const lists: { [pId: string]: number } = await PunchlistMethods.getRecentPunchlists();

    const listArr = [];

    for (const key in lists) {
      listArr.push({
        id: key,
        date: lists[key]
      });
    }

    const sorted = listArr.sort((a, b) => a.date - b.date);

    if (sorted.length > 3) {
      sorted.splice(3);
    }

    this.setState({
      punchlists: sorted
    });
  }
}
