import React, { Component } from "react";
import PageHeader from "components/pageHeader/PageHeader";

import styles from "./Manage.module.css";
import { DEFAULT_REQUIRED_VALIDATOR } from "modules/customInputDataValidators/textValidators/minLengthValidator";

import * as Icons from "react-feather";
import TextInput from "components/inputs/textInput/TextInput";
import SuperButton from "components/superButton/SuperButton";
import { withRouter, RouteComponentProps } from "react-router";
import ProjectUserList from "components/projectUserList/ProjectUserList";
import UserProfileMethods from "modules/api/userProfileMethods";
import { AuthContext } from "contexts/AuthContext";
import DangerArea from "components/dangerArea/DangerArea";
import { ProjectInvite } from "modules/api/apiModels/projectInvite";
import TeamMethods from "modules/api/teamMethods";
import TeamData from "modules/api/apiModels/teamData";

interface ManageTeamState {
  alias: string;
  name: string;
  nameValid: boolean;
  nameTouched: boolean;

  members: string[];
  membersTouched: boolean;

  forceTouched: boolean;
  isLoading: boolean;
  redirectSucess?: boolean;
  exitingProject: boolean;
  isLoadingProjectData: boolean;
  deletingProject: boolean;

  invitedMembers?: ProjectInvite[];
  inviteMembersTouched: boolean;
}

interface ManageProps extends RouteComponentProps<{ userId: string; projectAlias: string }> {}

class ManageTeam extends Component<ManageProps, ManageTeamState> {
  public static contextType = AuthContext;
  public context!: React.ContextType<typeof AuthContext>;

  private addedUsers: string[] = [];
  private removedUsers: string[] = [];
  private revokedInvites: string[] = [];

  private members: string[] = [];

  private originalProject?: TeamData;
  private projectId?: string;

  constructor(props: ManageProps) {
    super(props);

    this.state = {
      alias: "",
      name: "",
      nameValid: true,
      nameTouched: false,

      members: [],
      membersTouched: false,

      forceTouched: false,

      isLoading: false,
      isLoadingProjectData: true,
      exitingProject: false,
      deletingProject: false,

      inviteMembersTouched: false
    };

    this.addMember = this.addMember.bind(this);
    this.removeMember = this.removeMember.bind(this);
    this.revokeInvite = this.revokeInvite.bind(this);
    this.deleteProject = this.deleteProject.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.setName = this.setName.bind(this);
    this.setNameValid = this.setNameValid.bind(this);
    this.exitProject = this.exitProject.bind(this);
  }

  render() {
    const isOwner = this.originalProject ? this.context.uid === this.originalProject.owner : false;

    return (
      <div className={styles.Manage}>
        <PageHeader
          title="Manage Team"
          summary="Edit participants, name or delete this team."
          spinner={this.state.isLoadingProjectData ? "colorful" : undefined}
        ></PageHeader>
        {!this.state.isLoadingProjectData && (
          <div className={styles.creationForm}>
            <label className={styles.formEntry}>
              Name
              <div className={styles.formInput}>
                <TextInput
                  value={this.state.name}
                  type="text"
                  placeholder="My Awesome Team"
                  icon={Icons.Package}
                  iconSide="left"
                  onChange={this.setName}
                  onValidationStateChanged={this.setNameValid}
                  validators={[DEFAULT_REQUIRED_VALIDATOR]}
                  forceTouched={this.state.forceTouched}
                  disabled={!isOwner}
                />
              </div>
            </label>

            <div className={styles.formEntry}>
              <ProjectUserList
                auxMembers={
                  this.state.invitedMembers
                    ? this.state.invitedMembers.map(x => x.originalUsername || x.email || "")
                    : undefined
                }
                auxRemoveText="Revoke Invite"
                members={this.state.members}
                onAddMember={this.addMember}
                onRemoveMember={this.removeMember}
                onRemoveAux={this.revokeInvite}
                hideActionButtons={!isOwner}
                disabled={!isOwner}
              />
            </div>

            {isOwner && this.getActionButtons()}

            {!isOwner && this.getExitButton()}
          </div>
        )}
      </div>
    );
  }

  getActionButtons() {
    return (
      <>
        <div className={styles.actionArea}>
          <SuperButton
            label="Save Changes"
            disabled={
              this.state.isLoading ||
              !this.state.nameValid ||
              !(this.state.membersTouched || this.state.nameTouched || this.state.inviteMembersTouched)
            }
            onClick={this.saveChanges}
            spin={this.state.isLoading}
          />
        </div>

        <DangerArea
          label="Delete Team"
          desc="Use the button on the right if you want to exclude this team. Be aware that this action can't be undone."
          confirmationLabel="Confirm deletion?"
          confirmationAction="Delete"
          action="Delete Team"
          execute={this.deleteProject}
          executing={this.state.deletingProject}
          actionIcon={Icons.Trash}
        />
      </>
    );
  }

  getExitButton() {
    return (
      <DangerArea
        label="Leave Team"
        desc="Use the button on the right if you want to leave this team. You can't undo this action."
        confirmationLabel="Confirm exit?"
        confirmationAction="Leave"
        action="Leave Team"
        execute={this.exitProject}
        executing={this.state.exitingProject}
        actionIcon={Icons.LogOut}
      />
    );
  }

  componentDidMount() {
    const pid = this.props.match.params.projectAlias;

    this.projectId = `${pid}`;

    if (this.props.location.state && this.props.location.state.project) {
      this.processState();
    } else {
      this.loadProjectFromId();
    }
  }

  async loadProjectFromId() {
    if (!this.projectId) {
      return;
    }

    // get project data
    const projectData = await TeamMethods.getTeamData(this.projectId);

    this.originalProject = projectData;

    await this.processOriginalProject();
  }

  async processState() {
    if (!this.props.location.state || !this.props.location.state.project) {
      return;
    }

    this.originalProject = this.props.location.state.project;

    await this.processOriginalProject();
  }

  async processOriginalProject() {
    if (!this.originalProject || !this.projectId) return;

    const members: string[] = [];

    for (const key in this.originalProject.members) {
      const username = await UserProfileMethods.getUserUsername(key);

      if (username) members.push(username);
    }

    this.members = members;

    const isOwner = this.originalProject ? this.context.uid === this.originalProject.owner : false;

    try {
      if (isOwner) {
        const invitedMembers = await TeamMethods.getActiveInvites(this.projectId);

        for (const member of invitedMembers) {
          if (member.originalUid) {
            const u = await UserProfileMethods.getUserUsername(member.originalUid);

            if (u) {
              member.originalUsername = u;
            }
          }
        }

        this.setState({
          invitedMembers: invitedMembers
        });
      }

      this.setState({
        name: this.originalProject.name,
        members: members,
        isLoadingProjectData: false
      });
    } catch {}
  }

  async deleteProject() {
    this.setState({
      deletingProject: true
    });

    if (!this.projectId) {
      return;
    }

    await TeamMethods.deleteTeam(this.projectId);

    this.props.history.replace("/app/teams");
  }

  async exitProject() {
    this.setState({
      exitingProject: true
    });

    if (!this.projectId) {
      return;
    }

    try {
      await TeamMethods.leaveTeam(this.projectId);
      this.props.history.replace("/app/teams");
    } catch {}
  }

  async saveChanges() {
    this.setState({ isLoading: true });

    if (this.state.nameTouched && this.projectId) {
      await TeamMethods.setTeamName(this.projectId, this.state.name);
    }

    if (this.state.membersTouched && this.projectId && this.addedUsers.length > 0) {
      await TeamMethods.inviteToTeam(this.projectId, this.addedUsers);
    }

    if (this.state.membersTouched && this.projectId && this.removedUsers.length > 0) {
      await TeamMethods.removeFromTeam(this.projectId, this.removedUsers);
    }

    if (this.state.inviteMembersTouched && this.projectId && this.revokedInvites.length > 0) {
      await TeamMethods.revokeInvites(this.revokedInvites);
    }

    this.setState({ isLoading: false, nameTouched: false, membersTouched: false });

    this.props.history.replace("/app/teams");
  }

  addMember(username: string) {
    if (this.removedUsers.includes(username)) {
      const i = this.removedUsers.indexOf(username);
      this.removedUsers.splice(i, 1);
    } else {
      this.addedUsers.push(username);
    }

    this.members = [...this.members, username];

    this.setState({
      members: this.members,
      membersTouched: true
    });
  }

  async removeMember(username: string) {
    let uid: string;

    if (this.addedUsers.includes(username)) {
      const i = this.addedUsers.indexOf(username);
      this.addedUsers.splice(i, 1);
    } else {
      const search = await UserProfileMethods.getUserId(username);

      if (!search) {
        return;
      }

      uid = search;
      this.removedUsers.push(uid);
    }

    const x = [...this.members];
    x.splice(x.indexOf(username), 1);

    this.members = x;

    this.setState({
      members: this.members,
      membersTouched: true
    });
  }

  async revokeInvite(username: string) {
    if (!this.state.invitedMembers) {
      return;
    }

    const invite = this.state.invitedMembers.find(x => x.email === username || x.originalUsername === username);

    if (!invite) {
      return;
    }

    const inviteIndex = this.state.invitedMembers.indexOf(invite);

    this.revokedInvites.push(invite.key);

    const invites = [...this.state.invitedMembers];
    invites.splice(inviteIndex, 1);

    this.setState({
      invitedMembers: invites,
      inviteMembersTouched: true
    });
  }

  setName(value: string) {
    this.setState({
      nameTouched: true,
      name: value
    });
  }

  setNameValid(value: boolean) {
    this.setState({
      nameValid: value
    });
  }
}

export default withRouter(ManageTeam);
